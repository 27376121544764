@import "styles/vars";

.sticker-filter-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 28px;
  border-top: 1px solid var(--new-border, #2B292E);
  background: var(--new-bg-2, #19181C);
  @include tr-ease();
  cursor: pointer;

  &:hover, &:active {
    background: var(--new-bg-3, #1F1E21);
  }

  &__img {
    flex-shrink: 0;

    img {
      display: block;
      object-fit: cover;
      aspect-ratio: 1.14;
      width: 80px;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__title {
    color: $color-yellow-0;
    @include fs--headline-4-pc;
  }

  &__type {
    margin-top: 8px;
    color: $color-white;
    @include fs--headline-4-pc;
  }

  &__actions {
    flex-shrink: 0;
    color: $color-purple-3;
  }
}

@media screen and (max-width: 1024px) {
  .sticker-filter-item {
    gap: 16px;
    padding: 12px 16px;

    &__img {
      img {
        aspect-ratio: 1.14;
        width: 42px;
      }
    }

    &__title {
      @include fs--headline-3-m;
    }

    &__type {
      @include fs--headline-3-m;
    }
  }
}
