@import "src/styles/vars";

.request-comparison-modal {
  &__wrapper {
    .ant-modal-close {
      top: 30px;
      right: 30px;
    }
  }

  &__content {
    padding: 32px 32px 28px 32px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 44px;
    gap: 12px;
  }

  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-3-pc;
  }

  &__count {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 12px;
    color: $color-grey-text;
    @include fs--title-6-pc;
  }

  &__body {
    margin-top: 24px;
  }
}


@media screen and (max-width: 1024px) {
  .request-comparison-modal {
    &__content {
      padding: 26px 0 0;
    }

    &__head {
      margin-bottom: 22px;
      padding-right: 60px;
      padding-left: 16px;
    }

    &__title {
      @include fs--title-4-pc;
    }

    &__count {
      gap: 8px;
      @include fs--headline-6-pc;
    }

    &__body {
      margin-top: 16px;
      padding-top: 16px;
    }

    &__table {
      .ant-table {
        background: var(--new-bg-1, #131215);
      }
    }
  }
}
