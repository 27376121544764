@import "src/styles/vars";

.message{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  &__avatar{
    margin-right: 13px;
  }
  &__content{
    max-width: 860px;
    padding: 12px 72px 12px 14px;
    border-radius: 8px;
    background: $color-bg-2;
    position: relative;
    &:before{
      content: '';
      background: url("../../../../../assets/img/tip.svg") center center no-repeat;
      background-size: contain;
      width: 15px;
      height: 12px;
      position: absolute;
      left: -10px;
      top: 0;
    }
    &--name{
      @include fs--headline-3-pc;
      color: $color-white;
    }
    &--text{
      @include fs--body-2-pc;
      color: $color-white;
      margin-top: 4px;
      margin-bottom: 10px;
    }
    &--image{
      margin-top: 10px;
      border-radius: 8px;
      overflow: hidden;
      max-width: 500px;
      width: 100%;
      height: auto;
      margin-bottom: 10px;
      img{
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
    &--timestamp{
      @include fs--headline-6-pc;
      color: $color-grey-text;
      position: absolute;
      right: 12px;
      bottom: 12px;
    }
  }
}

@media screen and (max-width: 1024px){
  .message{
    &__avatar{
      display: none;
    }
    &--user{
      justify-content: flex-end;
      .message__content{
        margin-left: 0;
        margin-right: 12px;
        &:before{
          transform: rotateY(180deg);
          left: auto;
          right: -10px;
        }
      }
    }
    &__content{
      max-width: 85%;
      margin-left: 12px;
      padding: 12px 63px 23px 14px;
      &--name{
        @include fs--headline-4-pc;
      }
      &--text{
        @include fs--body-3-pc;
      }
      &--timestamp{
        @include fs--headline-9-pc;
      }
    }
  }
}