@import "styles/vars";

.dashboard{
  &__reset-seances{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 32px 32px 12px 32px;
    svg{
      width: 48px;
      height: 48px;
      color: $color-purple;
    }
    &--title{
      @include fs--title-4-pc;
      color: $color-white;
      margin-top: 24px;
      margin-bottom: 12px;
      text-align: center;
    }
    &--desc{
      @include fs--body-2-pc;
      color: $color-white;
      margin-bottom: 32px;
      text-align: center;
    }
    &--button{
      &-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
      }
      &-wide{
        width: 100%;
      }
    }
    &--button + &--button{
      margin-top: 12px;
    }
  }
}

@media screen and (max-width: 1024px){
  .dashboard{
    &__reset-seances{
      padding: 0;
      svg{
        margin-top: 72px;
      }
      &--title, &--desc{
        padding-left: 16px;
        padding-right: 16px;
      }
      &--title{
        @include fs--headline-3-pc;
        text-align: center;
      }
      &--desc{
        @include fs--body-4-pc;
        text-align: center;
        margin-bottom: 130px;
      }
      &--button{
        &-container{
          padding: 24px 16px 5px 16px;
          background: $color-bg-2;
        }
      }
    }
  }
}