@import "styles/vars";

.inventory-product {
  position: relative;
  &__scroll {
    position: relative;
    top: 0;
    @include scrollbar();
    padding-right: 4px;
    margin-right: -4px;
    overflow: auto;
  }

  &__card {
    padding: 12px 20px;
    border-radius: 8px;
    background: var(--new-bg-2, #19181C);
  }

  &__banner {
    position: relative;
    display: flex;

    min-height: 353px;
    padding: 0px 16px 12px;
    overflow: hidden;

    &--dota {
      .inventory-product__banner-bg {
        top: -36px;
        bottom: -24px;
        right: -24px;
        left: -24px;
      }

      .inventory-product__banner-img {
        img {
          //max-width: 191px;
          max-width: 220px;
        }
      }
    }
  }

  &__banner-bg {
    position: absolute;
    top: -72px;
    bottom: -48px;
    right: -48px;
    left: -48px;
    opacity: 0.2;
  }

  &__banner-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__banner-btn-close {
    position: absolute;
    top: 16px;
    right: 0;
    color: $color-white;
    @include tr-ease();
    cursor: pointer;
    &:hover,&:active {
      color: var(--new-grey-text, #939199);
    }
  }

  &__banner-img {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-bottom: -12px;
    img {
      max-width: 290px;
      display: block;
      margin: 0 auto;
      object-fit: cover;
    }
  }

  &__banner-stickers {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 12px;
    @include list-reset-style;
    li {
      text-align: center;

      img {
        display: block;
        width: 64px;
        height: auto;
        aspect-ratio: 1;
        margin: 0 auto;
        object-fit: cover;
      }

      svg {
        width: 64px;
        height: auto;
      }

      div {
        height: 23px;
        margin-top: 4px;
      }

    }
  }

  &__banner-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    padding-top: 12px;
  }

  &__banner-game {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: $color-green-03;
    @include fs--headline-1-pc;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 12px;
  }

  &__actions-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: var(--new-white, #FFF);
    @include fs--title-2-pc;
  }

  &__actions-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__btn-sell {
    min-width: 95px;
  }

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 12px;
  }

  &__float {
    padding-top: 8px;
    padding-bottom: 8px;
    width: calc(50% - 6px);
    flex-shrink: 1;
  }

  &__float-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;

    span {
      color: $color-white;
    }
  }

  &__float-range {
    margin-top: 7px;
  }

  &__rarity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 8px;
    width: calc(50% - 6px);
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;
  }

  &__chart {
    margin-top: 12px;
    padding: 8px 4px 8px 12px;
  }

  &__chart-content {
    height: 100px;
  }
}
