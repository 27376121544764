@import "styles/vars";

.deals-head {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px 24px;
  }

  &__title {
    width: 100%;
    color: $color-white;
    @include fs--title-1-pc;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__tabs {

  }

  &__autocomplete {
    width: 260px;
  }

  &__sort {
    width: 220px;
  }

  &__btn-create {

  }
}

@media screen and (max-width: 1360px){
  .deals-head__title {
    order: 1;
    flex-grow: 1;
    width: auto;
  }

  .deals-head__btn-create {
    order: 2;
  }

  .deals-head__actions {
    order: 3;
    width: 100%;
  }

  .deals-head__autocomplete {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .deals-head__title {
    @include fs--title-4-pc;
  }

  .deals-head__actions {
    flex-wrap: wrap;
    gap: 12px 8px;
  }

  .deals-head__autocomplete {
    order: 0;
  }

  .deals-head__tabs {
    width: 100%;
    order: 1;
    a {
      flex-grow: 1;
    }
  }

  .deals-head__sort {
    order: 2;
    width: calc(50% - 4px);
    flex-grow: 1;
  }



}
