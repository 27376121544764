@import "../../../../../styles/vars";

.product-requests-card {
  position: relative;
  flex-grow: 1;
  width: 25%;
  padding: 24px;
  border-radius: 12px;
  background: var(--new-bg-2, #19181C);
  cursor: pointer;
  @include tr-ease();
  overflow: hidden;

  &:hover, &:active {
    background: var(--new-bg-3, #1F1E21);
  }
  &--loading {
    pointer-events: none;
  }

  &__loader {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }

  &__title {
    color: var(--new-grey-text, #939199);
    @include fs--title-4-pc;

    span {
      color: var(--new-white, #FFF);
    }
  }

  &__text {
    margin-top: 8px;
    @include fs--headline-4-pc;
    color: var(--new-grey-text, #939199);
  }
}

@media screen and (max-width: 1360px) {
  .product-requests-card {
    width: calc(50% - 6px);
  }

}


@media screen and (max-width: 1024px) {
  .product-requests-card {
    &__title {
      @include fs--title-6-pc;
    }

    &__text {
      @include fs--headline-5-pc;
    }
  }
}

@media screen and (max-width: 768px) {
  .product-requests-card {
    width: 100%;

  }
}
