@import "styles/vars";

.two-factor{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    &--icon{
      svg{
        width: 48px;
        height: 48px;
        color: $color-purple;
      }
    }
  &--qr{
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
    img{
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
  &--text{
    margin-bottom: 5px;
    color: $color-white;
  }
  &--desc{
    color: $color-white;
    @include fs--body-2-pc;
    text-align: center;
    max-width: 320px;
    margin-bottom: 32px;
  }
    &--title{
      @include fs--title-4-pc;
      color: $color-white;
      margin-top: 24px;
      margin-bottom: 32px;
    }
    &--button{
      width: 100%;
      margin-top: 32px;
    }
    &--input{
      width: 100%;
    }
    &--input + &--input{
      margin-top: 12px;
    }
}
@media screen and (max-width: 1024px){
  .two-factor {
    &--icon{
      margin-top: 20px;
    }
    padding: 0;
    &--title {
      @include fs--title-3-m;
    }
    &--title, &--desc, &--input{
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      text-align: center;
    }
    &--desc{
      max-width: none;
    }
    &--button{
      margin-top: 90px;
      padding: 16px 24px;
      background: $color-bg-2;
    }
  }
}