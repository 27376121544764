@import "styles/vars";

.steam-auth-modal {
  width: 100%;

  &__wrapper {
    .ant-modal-close {
      color: $color-white;
    }
  }

  &__head {
    position: relative;
    width: 100%;
    height: 260px;
    text-align: center;
    background: radial-gradient(73.20% 81.31% at 50.00% 81.14%, #A66DFF 0%, #532D8F 90%);
  }

  &__head-bg {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url("../../../../../src/assets/img/maskotBg.png") center center no-repeat;
    background-size: cover;
    opacity: .5;
  }

  &__head-maskot {
    z-index: 2;
    width: 100%;
    height: 100%;
    max-width: 456px;
    margin: 0 auto;
    object-position: center center;
    object-fit: cover;
  }


  &__body {
    padding: 32px;
    text-align: center;
    background: var(--new-bg-1, #131215);
  }

  &__title {
    margin-bottom: 12px;
    color: var(--new-purple-3, #A66DFF);
    @include fs--title-4-pc;

  }

  &__text {
    color: var(--new-white, #FFF);
    @include fs--body-2-pc;
  }

  &__tags {
    margin-top: 24px;
  }

  &__grid {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .ant-tag {
      margin: 0;
    }
  }

  &__actions {
    padding: 32px 32px 12px;
    background: var(--new-bg-3, #1F1E21);
  }

  &__checkbox {

    .ant-checkbox {
      align-self: flex-start;

      & + span {
        color: $color-white;
        @include fs--body-3-pc();
      }
    }

    a {
      color: var(--new-purple-3, #A66DFF);
      text-decoration: underline;
      @include tr-cb();

      &:hover, &:active, &:focus {
        text-decoration: none;
        color: var(--gray-0, #BEBDC2);
      }
    }
  }

  &__btn-auth {
    margin-top: 24px;
  }
}


@media screen and (max-width: 1024px) {
  .steam-auth-modal__head-bg {
    background-size: contain;
    background-repeat: repeat-x;

  }

  .steam-auth-modal__actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-btn {
      max-width: 480px;
    }
  }
}

@media screen and (max-width: 768px) {
  .steam-auth-modal__head {
    height: 240px;

  }

  .steam-auth-modal__body {
    text-align: left;
    padding: 24px 16px;
  }

  .steam-auth-modal__title {
    margin-bottom: 8px;
    @include fs--headline-3-pc;
  }

  .steam-auth-modal__text {
    @include fs--body-4-pc;
  }

  .steam-auth-modal__tags {
    position: relative;
    overflow: hidden;
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-top: 16px;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      width: 32px;
      background: linear-gradient(-90deg, transparent 0%, var(--new-bg-3, #1F1E21) 80%);
    }

    &:after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      right: 0;
      width: 32px;
      background: linear-gradient(90deg, transparent 0%, var(--new-bg-3, #1F1E21) 80%);
    }
  }

  .steam-auth-modal__scroll {
    padding-bottom: 4px;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    @include scrollbar('horizontal')
  }

  .steam-auth-modal__grid {
    padding: 0 16px;
    gap: 4px;
  }

  .steam-auth-modal__actions {
    padding: 24px 16px 16px;
  }

  .steam-auth-modal__checkbox {
    .ant-checkbox {
      & + span {
        @include fs--body-3-m;
      }
    }
  }

  .steam-auth-modal__btn-auth {
    margin-top: 16px;
  }
}
