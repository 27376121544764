@import "src/styles/vars";

.tickets{
  width: 100%;
  &__wrap{
    width: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
  }
  &__loader{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__head{
    width: 100%;
    margin-bottom: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title{
    @include fs--title-1-pc;
    color: $color-white;
  }
  &__content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    &--item{
      width: 100%;
    }
  }
}

@media screen and (max-width: 1024px){
  .tickets{
    &__wrap{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__title{
      @include fs--title-4-pc;
      margin-bottom: 16px;
    }

  }
}


@media screen and (max-width: 768px){
  .tickets{
    &__head{
      margin-bottom: 16px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &__nav{
      width: 100%;
      a{
        button{
          width: 100%;
        }
      }
    }
  }
}
