@import "styles/vars";

.inventory-empty {

  margin: 0 auto;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__btn-market {
    width: 100%;
    max-width: 240px;
  }
}


@media screen and (max-width: 1024px) {
  .inventory-empty {
    max-width: 235px;
  }
}

@media screen and (max-width: 768px) {
  .inventory-empty {
    &__btn-market {
      max-width: 480px;
    }
  }
}
