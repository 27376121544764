@import "styles/vars";

.product-info {
  padding: 36px 0 24px 28px;
  border-bottom: 1px solid var(--new-border, #2B292E);
  overflow: hidden;

  &__head {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
    gap: 24px;
  }

  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-1-pc;
  }

  &__actions {
    max-width: calc(100% - 330px);
    @include scrollbar('horizontal', 4px);
    overflow: auto;
    padding-top: 8px;
    padding-bottom: 6px;

    .ant-radio-button-wrapper {
      white-space: nowrap;
    }
  }

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 44px;
  }

  &__aside {
    display: flex;
    //align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 0;
    width: 315px;
  }

  &__card {
    padding: 16px 20px;
    border-radius: 8px;
    @include fs--headline-4-pc;
    color: var(--new-grey-text, #939199);
    background: var(--new-bg-2, #19181C);

    & + & {
      margin-top: 12px;
    }

    &--steam {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      color: var(--new-white, #FFF);
      @include fs--title-2-pc;
    }

    &--float {

    }

    &--rarity {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--collection {
      ul {
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        width: 100%;
        @include scrollbar('horizontal')
      }
    }
  }
  &__sale {
    align-items: center;
    justify-content: center;
    gap: 4px;
    svg {
      width: 24px;
      height: auto;
      color: $color-white;
    }
  }
  &__discount-percent {
    user-select: none;
    @include fs--title-6-pc;
    color: $color-green;
    &--overpayment {
      color: $color-red;
    }
  }

  &__card-float-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 7px;

    span {
      color: var(--new-white, #FFF);
    }
  }

  &__collection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    @include list-reset-style;
    margin-top: 12px;

    li {
      width: 100%;
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
        width: 80px;
        height: 62px;
        object-fit: cover;
      }

      span {
        display: inline-block;
        margin-top: 8px;
        @include fs--body-4-pc;
        color: var(--new-white, #FFF);
      }
    }
  }

  &__aside-actions {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 8px;
    margin-top: 24px;
    flex-grow: 1;
  }

  &__main {
    max-width: calc(100% - 359px);
    position: relative;
    z-index: 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 60px;
    flex-grow: 1;
  }

  &__gradient {
    position: absolute;
    z-index: 0;
    top: -72px;
    top: -108px;
    bottom: -36px;
    right: 0;
    left: -120px;
    overflow: hidden;
    opacity: .2;
    pointer-events: none;
    background-position: center;
    background-repeat: repeat-y;
  }
  &__charm{
    margin-top: 24px;
  }
  &__stickers {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    @include list-reset-style;

    &--csgo {
      img {
        aspect-ratio: 1;
      }

      svg {
        width: 64px;
      }
    }

    &--dota {
      img {
        aspect-ratio: 1.4;
      }

      svg {
        width: 46px;
      }
    }

    li {
      text-align: center;

      img {
        display: block;
        width: 64px;
        height: auto;
        margin: 0 auto;
        object-fit: cover;
      }

      svg {
        height: auto;
      }

      div {
        margin-top: 4px;
      }

    }
  }

  &__banner {
    position: relative;
    z-index: 0;
    flex-grow: 1;
  }

  &__banner-img {
    align-items: center;
    display: flex;
    height: calc(100% - 44px);
    justify-content: center;
    max-height: 550px;
    padding: 44px 120px 44px 0;
    position: relative;
    z-index: 1;

    img {
      display: block;
      max-width: 100%;
      object-fit: cover;
      width: auto;
      max-height: 120%;
    }
  }

  &__banner-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
  }

  &__banner-game {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: $color-green-03;
    @include fs--title-7-pc;

    svg {
      flex-shrink: 0;
      //  display: inline-block;
      //  vertical-align: middle;
      //  margin-left: 8px;
    }
  }

}

@media screen and (max-width: 1680px) {
  .product-info {
    &__head {
      gap: 16px;
    }

    &__main {
      gap: 16px;
    }

    &__stickers {
      justify-content: flex-start;
    }

    &__banner-img {
      padding: 44px 0px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .product-info {
    &__head {
      flex-direction: column;
      padding-bottom: 16px;
      align-items: flex-start;
    }

    &__actions {
      max-width: none;
      width: 100%;
      padding-bottom: 8px;

      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      -webkit-overflow-scrolling: touch;
      @include scrollbar('horizontal', 4px);
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }

      .ant-radio-button-wrapper {
        white-space: nowrap;
      }
    }

    &__grid {
      gap: 16px;
      flex-direction: column-reverse;
    }

    &__aside {
      position: relative;
      z-index: 2;
      width: 100%;
      max-width: 716px;
      margin: 0 auto;
    }

    &__collection {
      flex-wrap: wrap;

      li {
        width: calc(50% - 8px);
      }
    }

    &__aside-actions {
      margin-top: 12px;
    }

    &__main {
      max-width: 716px;
      width: 100%;
      margin: 0 auto;
    }
    &__stickers {
      justify-content: center;
    }

    &__banner-actions {
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1024px) {
  .product-info {
    padding: 4px 0 24px;

    &__head {
      gap: 4px;
    }

    &__title {
      @include fs--title-h1-m;
    }

    &__aside {
      max-width: 620px;
    }

    &__card {
      &--steam {
        @include fs--title-4-pc;

        svg {
          width: 24px;
        }
      }
    }

    &__sale {
      align-items: center;
      justify-content: center;
      gap: 4px;
      svg {
        width: 24px;
        height: auto;
        color: $color-white;
      }
    }
    &__discount-percent {
      @include fs--headline-3-pc;
    }

    &__collection {
      li {
        img {
          width: 68px;
          height: 56px;
        }
      }
    }

    &__main {
      flex-direction: column-reverse;
    }

    &__gradient {
      top: -36px;
      bottom: -84px;
      right: -16px;
      left: -16px;
    }

    &__stickers {
      flex-direction: row;

      &--csgo {
        svg {
          width: 42px;
        }
      }

      &--dota {
        svg {
          width: 30px;
        }
      }

      li {
        img {
          width: 42px;
        }

        div {
          margin-top: 8px;
          @include fs--body-4-pc;
        }
      }
    }

    &__banner-img {
      height: auto;
      width: 100%;
      max-width: 620px;
      margin: 0 auto;
      padding: 0;

      img {
        width: 100%;
        min-height: auto;
      }
    }
  }
}
