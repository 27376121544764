@import "styles/vars";

.inventory-expose {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 12px;
  background: var(--new-bg-2, #19181C);
  &:before{
    bottom: 0;
    height: 12px;
    width: 100%;
    background: $color-bg-1;
    position: fixed;
    right: 0;
    content: '';
    z-index: 0;
  }

  &__loading{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba($color-bg-2,.75);
    border-radius: 12px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 16px;
    gap: 12px;
  }

  &__head-title {
    color: var(--new-white, #FFF);
    @include fs--title-3-pc;
  }

  &__head-count {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 12px;
    color: $color-grey-text;
    @include fs--title-6-pc;
  }

  &__head-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
  }

  &__head-btn-close {
    flex-shrink: 0;
    cursor: pointer;
    @include tr-ease();
    color: var(--new-grey-text, #939199);
    &:hover, &:active {
      color: $color-white;
    }
  }


  &__content {
    height: calc(100% - 66px - 120px);
    position: relative;
    padding: 0 8px 0 20px;
    overflow: hidden;
  }

  &__scroll {
    max-height: 100%;
    .rc-virtual-list-holder {
      padding-right: 12px;
    }
    .rc-virtual-list-scrollbar {
      visibility: visible!important;
      width: 4px!important;
      background: transparent;
      border-radius: 2px;
    }
    .rc-virtual-list-scrollbar-thumb {
      background: $color-bg-01!important;
      border-radius: 2px!important;
    }
  }
  &__empty {
    margin-bottom: 98px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
    background: var(--new-bg-3, #1F1E21);
    border-radius: 0 0 12px 12px;
  }

  &__footer-info {
  }

  &__footer-summary {
    @include list-reset-style;
    color: var(--new-grey-text, #939199);
    @include fs--title-7-pc;

    span {
      color: var(--new-white, #FFF);
      @include fs--title-7-pc;
    }
    li + li {
      margin-top: 8px;
    }
  }

  &__btn-expose {
    width: 100%;
    max-width: 242px;
  }

}

@media screen and (max-width: 1024px) {
  .inventory-expose {
    margin-bottom: 0;
    border-radius: 0;
    background: var(--new-bg-1, #131215);
    @include tr-cb();
    &__loading {
      bottom: -160px;
    }
    &__loader {
      height: 100%!important;
    }

    &__head {
      padding: 20px 60px 16px 16px;
    }

    &__head-title {
      @include fs--title-4-pc;
    }

    &__head-count {
      gap: 8px;
      @include fs--headline-6-pc;
    }

    &__head-actions {
      gap: 8px;
    }

    &__content {
      height: auto;
      //height: calc(100% - 66px);
      padding: 0 16px;
      overflow: visible;
    }
  }
}

@media screen and (max-width: 768px) {
  .inventory-expose {

    &__head {
      flex-wrap: wrap;
    }

    &__head-actions {
      justify-content: flex-start;
      width: 100%;
    }

    &__content {
      //height: calc(100% - 98px);
    }
  }
}
