@import "styles/vars";

.hacked-attention-modal {
  width: 100%;

  &__wrapper {
    .ant-modal-close {
      color: $color-white;
    }
  }

  &__head {
    width: 100%;
    position: relative;
    height: 235px;
    background: radial-gradient(73.20% 81.31% at 50.00% 91.14%, #E54545 0%, #992E2E 100%);

    img {
      width: 100%;
      height: 100%;
      object-position: top center;
      opacity: .5;
      object-fit: cover;
    }
  }

  &__body {
    padding: 32px;
  }

  &__title {
    margin-bottom: 12px;
    color: var(--red, #E54545);
    @include fs--title-4-pc;

  }

  &__text {
    color: var(--new-white, #FFF);
    @include fs--body-2-pc;

    a {
      color: var(--new-white, #FFF);
      @include fs--body-2-pc;
      text-decoration: underline;
    }

    ol {
      margin-block-start: 1em;
      margin-block-end: 0;
    }
  }

  &__actions {
    padding: 32px 32px 12px;
    text-align: center;
    background: var(--new-bg-3, #1F1E21);

  }

  &__actions-link {
    display: inline-block;
    margin-top: 12px;
  }
}


@media screen and (max-width: 1024px) {
  .hacked-attention-modal__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-btn {
      max-width: 480px;
    }
  }
}

@media screen and (max-width: 768px) {
  .hacked-attention-modal__head {
    height: 240px;
  }

  .hacked-attention-modal__body {
    padding: 24px 16px;
  }

  .hacked-attention-modal__title {
    margin-bottom: 8px;
    @include fs--headline-3-pc;
  }

  .hacked-attention-modal__text {
    @include fs--body-4-pc;
    a {
      @include fs--body-4-pc;
    }
  }

  .hacked-attention-modal__actions {
    padding: 24px 16px 16px;
  }

  .hacked-attention-modal__actions-link {
    margin-top: 24px;
  }

}
