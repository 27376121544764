@import "styles/vars";

.deals-autobuy {
  margin-top: 24px;
  &__table-request {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 200px;
  }
  &__table-sale {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .deals-autobuy {
    margin-top: 12px;
  }
}
