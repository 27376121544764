@import "src/styles/vars";

.chat{
  width: 100%;
  height: 100%;
  &__container{
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    max-height: 60svh;
    overflow-y: scroll;
    @include scrollbar('vertical', 4px)
  }
  &__item--user + &__item--support, + &__item--support + &__item--user{
    margin-bottom: 24px;
  }
  &__item--user + &__item--user, &__item--support + &__item--support{
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 768px){
  .chat__container{
    max-height: none;
    overflow-y: auto;
  }
}