@import "styles/vars";

.inventory-head {
  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    &--spaced{
      width: 100%;
      .inventory-head__autocomplete {
        width: 280px;
      }
    }
  }
  &__switch{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    span{
      color: $color-white;
    }
  }
  &__actions{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-left: auto;
  }
  &__top-left {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  &__autocomplete {
    width: 415px;
  }
  &__btn-reload,
  &__btn-reset {
    &.ant-btn {
      color: $color-white;
      border: 1px solid var(--new-border, #2B292E);
      background: var(--new-bg-2, #19181C);

      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-white;
            border-color: var(--new-purple, #8D5DD9);
            background: var(--new-bg-2, #19181C);
          }

          &:active {
            color: $color-white;
            border-color: var(--new-purple, #8D5DD9);
            background: var(--new-bg-2, #19181C);
          }
        }
      }
    }
  }

  &__sort {
    width: 290px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    margin-top: 12px;
  }


  &__filters {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 56px;
    width: 100%;
  }
  &__btn-select,
  &__btn-unselect {
    &.ant-btn {
      padding: 16px 12px;
      @include fs--headline-4-pc;
    }
  }


  &__scroll {
    position: relative;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    min-height: 100%;
    @include scrollbar('horizontal', 4px);
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    padding-bottom: 8px;
  }

  &__categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }

  &__category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0;
    border-radius: 8px;
    background: var(--new-bg-2, #19181C);
    &:last-child {
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 1280px) {
  .inventory-head {
    &__top{
      &--spaced{
        .inventory-head__autocomplete {
          width: 180px;
        }
        .inventory-head__sort {
          width: 170px;
        }
      }
    }
    &__autocomplete {
      width: 280px;
    }
    &__sort {
      width: 220px;
    }
  }

}
@media screen and (max-width: 1024px) {
  .inventory-head {
    &__top {
      &--spaced {
        flex-wrap: wrap;
        .inventory-head__actions{
          width: 100%;
          button{
            width: 100%;
          }
        }
        .inventory-head__tabs {
          width: 100%;
          order: 1;
          a {
            flex-grow: 1;
          }
        }

        .inventory-head__top-left {
          width: 100%;
        }
        .inventory-head__sort {
          order: 1;
          width: 100%;
        }
        .inventory-head__btn-reset {
          order: 2
        }
        .inventory-head__autocomplete {
          width: 100%;
          max-width: 100%;
          @include tr-cb()
        }

        .inventory-head__autocomplete-dropdown {
          width: 100% !important;
          max-width: 100%;
        }
      }
    }
    &__autocomplete {
      width: 180px;
    }
    &__sort {
      width: 170px;
    }
    &__category {
      padding: 0;
    }
    &__filters {
      width: 100%;
      height: 40px;
    }
  }
}

@media screen and (max-width: 768px) {

  .inventory-head__actions{
    width: 100%;
    button{
      width: 100%;
    }
  }
  .inventory-head__top {
    flex-wrap: wrap;
  }

  .inventory-head__tabs {
    width: 100%;
    order: 1;
    a {
      flex-grow: 1;
    }
  }


  .inventory-head__top-left {
    width: 100%;
  }
  .inventory-head__sort {
    order: 1;
    width: calc(100% - 48px);
  }
  .inventory-head__btn-reset {
    order: 2
  }
  .inventory-head__autocomplete {
    width: 100%;
    max-width: calc(100% - 48px);
    @include tr-cb()
  }

  .inventory-head__autocomplete-dropdown {
    width: 100% !important;
    max-width: calc(100% - 48px);
  }
}

@media screen and (max-width: 480px) {
  .inventory-head{
    &__actions{
      flex-wrap: wrap;
    }
  }
  .inventory-head__top-left {
    &--search-focused {
      .inventory-head__btn-reset {
        margin-left: -48px;
        opacity: 0;
      }

      .inventory-head__autocomplete {
        max-width: 423px;
      }

      .inventory-head__autocomplete-dropdown {
        max-width: 423px;
      }
    }
  }

}
