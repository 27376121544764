@import "styles/vars";

.product-card-actions {
  width: 100%;

  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
  }

  &__btn-buy {
    flex-grow: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  &__btn-expose {
    color: $color-white;
    border-color: $color-purple;
    background: $color-purple;
    transition: all .3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:active {
      color: $color-white!important;
      border: 1px solid $color-purple!important;
      background: $color-purple!important;
    }
    @media screen and (max-width: 1024px) {
      &:hover {
        color: $color-white!important;
        border: 1px solid $color-purple!important;
        background: $color-purple!important;
      }
    }
    &.product-card-actions__btn-expose--active {
      color: $color-purple-3!important;
      border: 1px solid $color-purple-0!important;
      background: $color-purple-0!important;
    }
  }
  &__btn-link{
    width: 100%;
  }
  &__btn-view {
    svg {
      @include tr-cb();
    }
    &--active {
      svg {
        transform: rotateY(180deg);
      }
    }
  }
}
