@import "../../../../../styles/vars";

.dashboard__main{
  &--user{
    padding: 0 180px 24px 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-id{
      .ant-typography{
        padding: 16px 16px 16px 48px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        color: $color-grey-text;
        @include fs--headline-6-pc;
        border-radius: 8px;
        background: $color-bg-2;
        border: 1px solid $color-border;
        position: relative;
        &-copy{
          width: 100%;
          height: 100%;
          position: absolute;
          margin-left: 0;
          left: 0;
          svg{
            margin-left: 16px;
            margin-top: 11px;
          }
          color: $color-white;
          &:hover, &:focus{
            color: $color-purple-3;
          }
        }
      }
    }
    &-data{
      display: flex;
      align-items: center;
      justify-content: center;
      &-avatar{
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
          margin-top: 0;
          margin-bottom: 0;
          color: $color-white;
          @include fs--title-3-pc;
          margin-left: 10px;
        }
      }
      &-steam {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        margin-left: 6px;
        border-radius: 20px;
        color: $color-white;
        background-color: $color-avatar-rounded;
        svg {
          width: 36px;
          height: auto;
        }
      }
      &-status{
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        svg, span{
          color: $color-grey-text;
        }
        span{
          margin-left: 4px;
          @include fs--title-7-pc;
        }
        &:before{
          background: $color-grey-text;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          position: absolute;
          left: -12px;
          bottom: 50%;
          transform: translateY(50%);
          content: '';
        }
      }
    }
  }
  &--balance{
    &-container{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    max-width: 447px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $color-purple;
    &-controls{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    &-value{
      h3{
        margin: 0 0 8px 0;
        @include fs--title-2-pc;
        color: $color-white;
        span{
          @include fs--subtittle-2-pc;
          color: $color-grey-text;
        }
      }
    }
  }
  &--freeze {
    color: $color-red;
    @include fs--headline-6-pc;
    span {
      @include fs--headline-7-pc
    }
  }
}


@media screen and (max-width: 1680px){
  .dashboard{
    &__main{
      &--user{
        padding-right: 60px;
      }
    }
  }
}

@media screen and (max-width: 1280px){
  .dashboard{
    &__main{
      &--user{
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}


@media screen and (max-width: 1024px){
  .dashboard__main--user-id{
    .ant-typography-copy{
      svg{
        position: absolute;
        left: 50%;
        transform: translateX(calc(-50% - 87px));
      }
    }
    &--wide{
      .ant-typography-copy{
        svg{
          position: absolute;
          left: 50%;
          transform: translateX(calc(-50% - 107px));
        }
      }
    }
  }
  .dashboard{
    &__main{
      &--user{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        &-id{
          margin-top: 12px;
          width: 100%;
        }
        &-data{
          &-avatar{
            h3{
              margin-left: 2px;
              @include fs--headline-5-pc;
            }
          }
          &-steam {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            margin-right: 6px;
            svg {
              width: 14px;
            }
          }
          &-status{
            svg{
              width: 16px;
              height: 16px;
            }
            span{
              @include fs--headline-6-pc;
            }
          }
        }
      }
      &--balance{
        max-width: none;
        padding: 12px;
        &-value{
          h3{
            @include fs--title-6-pc;
            margin-bottom: 3px;
            span{
              @include fs--headline-4-pc;
            }
          }

        }
      }
      &--freeze {
        @include fs--headline-4-pc;
        span {
          @include fs--headline-5-pc
        }
      }
    }
  }
}