@import "styles/vars";


.refill-payment{
  width: 100%;
  &__form{
    width: 100%;
    padding: 24px 32px 32px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 32px;
    &-head{
      width: 100%;
    }
    &-title{
      @include fs--title-4-pc;
      color: $color-white;
    }
    &-body{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 18px;
      &__logo{
        width: 50px;
        height: 50px;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &__text{
        @include fs--headline-4-pc;
        text-align: center;
      }
      &__qr{
        width: 140px;
        height: 140px;
        border-radius: 8px;
        background: $color-white;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      &__input{
        width: 100%;
        .ant-input-wrapper.ant-input-group{
          input{
            border-radius: 6px 0 0 6px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .refill-payment__form{
    padding-left: 16px;
    padding-right: 16px;
    &-body{
      &__input{
        .ant-input-wrapper.ant-input-group{
          .ant-input-group-addon{
            margin-left: 0;
          }
          input{
            border-radius: 6px!important;
            width: calc(100% - 54px);
          }
        }
      }
    }
  }
}
