@import "styles/vars";

.table-cell-name {
  &--overflow {
    .ant-typography {
      span {
        max-width: 188px;
        @include text-dots($line-clamp: 1);
      }
    }
  }
  &:hover,&:active {
    .table-cell-name__type {
      text-decoration: underline;
    }
    .table-cell-name__text {
      text-decoration: underline;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    color: var(--new-grey-text, #939199);
    white-space: nowrap;
    @include tr-cb();
  }
  &__type {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: var(--new-white, #FFF);
    @include fs--title-7-pc;
    line-height: 1.2;
    white-space: nowrap;
    @include tr-cb();
  }
  .ant-typography {
    margin-bottom: 0!important;
    span {
      display: inline-block;
    }
  }
  .ant-typography-copy {
    color: inherit;
    vertical-align: top;
    &:hover, &:focus{
      color: $color-purple-3;
    }
    svg{
      width: 18px;
      height: 18px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .table-cell-name {
    &__type {
      @include fs--headline-4-pc;
    }
  }
}
