.ant-progress {
  &.ant-progress-steps {
    &.ant-progress-small {
      width: 100%;
    }
  }
  .ant-progress-steps-item {
    border-radius: 8px;

  }
}
