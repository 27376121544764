@import "styles/vars";

.header-notices {
  width: 355px;
  color: $color-white;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 8px 0 16px;
  }

  &__title {
    flex-grow: 1;
    @include fs--title-7-pc
  }
  &__btn-read {
    width: 130px;
    height: 32px !important;
  }

  &__btn-close {
    width: 32px !important;
    height: 32px !important;
    cursor: pointer;
    @include tr-ease();
    color: var(--new-grey-text, #939199);
    &:hover, &:active {
      color: $color-white;
    }
  }

  &__list {
    &--scroll {
      position: relative;
      margin-right: -8px;

      &:after {
        pointer-events: none;
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 8px;
        bottom: 0;
        height: 100px;
        background: linear-gradient(0deg, $color-bg-3 0%, rgba(25, 24, 28, 0.00) 100%);
      }
    }
  }

  &__scroll {
    max-height: 50vh;
    padding-right: 8px;
    @include scrollbar-modal();
    overflow: auto;
    padding-bottom: 40px;
  }

  &__item {
    & + & {
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header-notices {
    width: 100%;
    height: 100%;

    &__head {
      padding: 20px 12px 16px;
      gap: 8px
    }

    &__title {
      flex-grow: 1;
      @include fs--title-4-pc
    }

    &__btn-read {
      &.ant-btn {
        @include fs--headline-3-m
      }
    }

    &__btn-close {
      svg {
        width: 16px !important;
      }
    }

    &__list {
      height: calc(100% - 66px);
      padding-left: 12px;
      padding-right: 8px;
      padding-bottom: 12px;

      &--scroll {
        margin-right: 0;
      }
    }

    &__scroll {
      height: 100%;
      max-height: inherit;
    }
  }
}
