@import "styles/vars";

.select-search-filter {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-white;
    @include fs--headline-4-pc;
  }
  &__group {
    flex-direction: column;
  }
  &__item {
    & + & {
      margin-top: 16px;
    }
  }
  .ant-select-open {
    .ant-select-arrow {
      transform: rotateX(0)!important;
    }
  }
  .ant-select-selection-search-input {
    height: 100%!important;
  }

  .ant-select-clear {
    display: block;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background-color: $color-white;
    color: $color-bg-3;
    .anticon {
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 5px $color-bg-3;
      svg{
        width: 100%;
        height: auto;
      }
    }
  }
  &__btn-clear {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  &__not-found {
    padding: 16px;
  }
}
