@import "styles/vars";

.table-cell-float {
  width: 185px;
  &__float{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 7px;
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;
    span {
      color: $color-white;
    }
  }
}
