@import "src/styles/vars";

.app-autocomplete {
  &__loader {
    padding: 16px;
  }
  &__dropdown {
    z-index: 1010;
    .ant-select-item {
      padding: 0 6px;
      .ant-select-item-option-content {
        padding: 6px 0;
        border-top: 1px solid var(--new-border, #2B292E);
      }
    }
  }
  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    img {
      display: block;
      width: 40px;
      aspect-ratio: 1.14;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__title {
    color: $color-white;
    @include fs--headline-4-pc
  }
  &__subtitle {
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc
  }
}


@media screen and (max-width: 1024px) {


  .app-autocomplete__title {
    @include fs--headline-4-pc
  }
}
