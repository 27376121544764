@import "styles/vars";

.inventory {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 12px 60px;
  &--catalog {
    padding: 12px 60px;
  }
  &__empty{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 1280px) {
  .inventory {
    padding: 12px 16px 36px;
    &--catalog {
      padding: 12px 16px 36px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .inventory {
    padding: 12px 16px 24px;
    &--catalog {
      padding: 12px 16px 24px;
    }
  }
}
