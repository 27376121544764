.ant-tag {
  &.ant-tag-borderless{
    display: inline-flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 24px;
    background: var(--new-bg-2, #19181C);
    span {
      color: var(--new-white, #FFF);
      @include fs--headline-2-pc;
    }
    svg {
      width: 20px;
      height: auto;
      margin-right: 8px;
      color: var(--new-purple-3, #A66DFF);
    }
  }
}

@media screen and (max-width: 1024px){
  .ant-tag {
    &.ant-tag-borderless{
      span {
        @include fs--headline-2-m;
      }
    }
  }
}
