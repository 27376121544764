@import "styles/vars";

.header-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  &__game-option-flag {
    display: inline-block;
    margin-right: 8px;
  }
  &__btn-balance {
    &:hover,&:active,&:focus {
      .header-actions__btn-content {
        color: $color-white;
      }
    }
  }
  &__btn-content {
    display: flex;
    align-items: center;
    justify-content: center;
    @include fs--headline-3-pc;
    color: $color-grey-text;
    @include tr-ease();
    span {
      display: inline-block;
      margin-left: 4px;
      color: $color-white;
    }
    svg {
      color: $color-white;
      margin-left: 8px;
    }
  }


  &__balance-popover {
    max-width: 320px;
    width: 100%;
    z-index: 990;
  }

  &__balance-content {
    color: $color-white;

  }
  &__balance-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 16px;
  }
  &__balance-title {
    @include fs--title-7-pc;
  }
  &__balance-btn {
    height: 36px!important;
  }
  &__balance-num {
    span {
      color: $color-grey-text;
    }
  }
  &__balance-freeze {
    margin-top: 8px;
    color: $color-red;
    @include fs--title-7-pc;
    span {
      @include fs--headline-3-pc
    }
  }
  &__balance-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-top: 16px;
    > button {
      width: calc(50% - 4px);
    }
  }
  &__select-game {
    width: 145px;
  }
  &__btn-summary {
    font-variant-numeric: tabular-nums;
  }
}

@media screen and (max-width: 1024px){
  .header-actions {
    gap: 8px;
    flex-direction: row-reverse;
    &__btn-balance {
      height: 36px;
      padding: 4px 8px;
    }
    &__btn-content {
      @include fs--headline-3-m();
      svg {
        width: 20px;
        height: auto;
      }
    }

    &__balance-title {
      @include fs--title-4-m;
    }
    &__balance-num {
      @include fs--title-3-m;
      span {
        color: $color-grey-text;
      }
    }
    &__balance-freeze {
      @include fs--headline-3-m;
      span {
        @include fs--headline-4-m;
      }
    }
    &__balance-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      margin-top: 16px;
    }
    &__btn-cart {
      height: 36px;
      padding: 4px 8px;
      @include fs--headline-3-m;
      &.ant-btn-icon-only {
        padding: 4px;
        width: 36px;
      }
      svg {
        width: 20px;
        height: auto;
      }
    }

  }
}
