
//small - h30, middle - h44, large - h56
.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-shadow: none;
  box-shadow: none;
  height: 44px;
  padding: 12px 16px;
  @include fs--headline-5-pc;
  border-radius: 8px;
  outline: 0!important;
  span {
    @include user-select-none();
  }

  svg {
    width: 20px;
    height: auto;
  }

  span + svg {
    margin-left: 8px;
  }

  svg + span {
    margin-left: 8px;
  }

  &.ant-btn-min-width-80,
  &[min-width-80] {
    min-width: 80px;
  }

  //---sizes [small, medium, large]
  &.ant-btn-sm {
    height: 30px;
    padding: 8px 12px;
    @include fs--headline-4-pc;
    border-radius: 8px;
  }

  &.ant-btn-lg {
    height: 56px;
    padding: 16px 24px;
    @include fs--title-6-pc;
    border-radius: 8px;

    svg {
      width: 24px;
    }
  }

  //---colors
  //---type [Primary] NOTE: diff colors btns
  &.ant-btn-primary {
    color: $color-white;
    border-color: $color-purple;
    background: $color-purple;

    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-white;
          border: 1px solid $color-purple-02;
          background: $color-purple-02;
        }

        &:active {
          color: $color-purple;
          border: 1px solid $color-purple-0;
          background: $color-purple-0;
        }
      }
    }

    &:focus {
      color: $color-white;
      border: 1px solid $color-purple-3;
      background: $color-purple-3;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus {
      color: $color-gray-2;
      border-color: $color-purple-01;
      background: $color-purple-01;
    }
  }

  &.ant-btn-primary--white{
    color: $color-purple;
    border-color: $color-white;
    background: $color-white;

    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-white;
          border: 1px solid $color-purple-02;
          background: $color-purple-02;
        }

        &:active {
          color: $color-purple;
          border: 1px solid $color-purple-0;
          background: $color-purple-0;
        }
      }
    }

    &:focus {
      color: $color-white;
      border: 1px solid $color-purple-3;
      background: $color-purple-3;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus {
      color: $color-gray-2;
      border-color: $color-purple-01;
      background: $color-purple-01;
    }
  }

  &.ant-btn-primary--success {
    color: $color-white;
    border-color: $color-green-0;
    background: $color-green-0;

    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-white;
          border: 1px solid $color-green-02;
          background: $color-green-02;
        }

        &:active {
          color: $color-green-0;
          border: 1px solid $color-green-01;
          background: $color-green-01;
        }
      }
    }

    &:focus {
      color: $color-white;
      border: 1px solid $color-green-02;
      background: $color-green-02;
    }
  }

  &.ant-btn-primary--error {
    color: $color-red;
    border-color: $color-red-0;
    background: $color-red-0;
    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus {
      color: $color-red;
      border-color: $color-red-0;
      background: $color-red-0;
      opacity: 0.5;
    }
    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-red;
          border-color: $color-red-0;
          background: transparent;
        }

        &:active {
          color: $color-red;
          border-color: transparent;
          background: transparent;
        }
      }
    }

    &:focus {
      color: $color-red;
      border-color: $color-red;
      background: transparent;
    }

    //&.ant-btn-icon-only {
    //  color: $color-grey-text;
    //  border-color: transparent;
    //  background: transparent;
    //}
  }

  //---END type [primary]
  //---type [default]  NOTE: diff grey btns
  &.ant-btn-default {
    color: $color-white;
    border-color: $color-bg-3;
    background: $color-bg-3;
    text-shadow: none;
    box-shadow: none;

    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-grey-text;
          border: 1px solid $color-bg-3;
          background: $color-bg-3;
        }

        &:active {
          color: $color-grey-text;
          border: 1px solid $color-bg-3;
          background: $color-bg-3;
        }
      }
    }

    &:focus {
      color: $color-grey-text;
      border: 1px solid $color-bg-3;
      background: $color-bg-3;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus {
      color: $color-gray-2;
      //border-color: $color-bg-3;
      //background: $color-bg-3;
    }
  }

  &.ant-btn-default--light {
    color: $color-white;
    border-color: $color-border;
    background: $color-border;

    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-grey-text;
          border: 1px solid $color-border;
          background: $color-border;
        }

        &:active {
          color: $color-grey-text;
          border: 1px solid $color-border;
          background: $color-border;
        }
      }
    }

    &:focus {
      color: $color-grey-text;
      border: 1px solid $color-border;
      background: $color-border;
    }
  }

  &.ant-btn-default--dark {
    color: $color-grey-text;
    border-color: $color-bg-2;
    background: $color-bg-2;

    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-white;
          border: 1px solid $color-bg-2;
          background: $color-bg-2;
        }

        &:active {
          color: $color-white;
          border: 1px solid $color-bg-2;
          background: $color-bg-2;
        }
      }
    }

    &:focus {
      color: $color-white;
      border: 1px solid $color-bg-2;
      background: $color-bg-2;
    }
  }


  //---END type [default]
  //---type [TEXT] NOTE: diff colors for btn as icon with hover,active style
  &.ant-btn-text {
    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover {
          color: $color-white;
          border-color: $color-border;
          background: $color-border;
        }

        &:active {
          color: $color-white;
          border-color: $color-border;
          background: $color-border;
        }
      }
    }

    &.ant-btn-icon-only {
      color: $color-grey-text;
      border-color: transparent;
      background: transparent;
    }

    &.ant-btn-text--gray {
      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          color: $color-white;

          &:hover {
            color: $color-grey-text;
            border-color: $color-border;
            background: $color-border;
          }

          &:active {
            color: $color-grey-text;
            border-color: $color-border;
            background: $color-border;
          }
        }
      }
    }

    &.ant-btn-text--primary {
      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          color: $color-purple;

          &:hover {
            color: $color-white;
            border-color: $color-purple-02;
            background: $color-purple-02;
          }

          &:active {
            color: $color-white;
            border-color: $color-purple-0;
            background: $color-purple-0;
          }
        }
      }
    }

    &.ant-btn-text--error {
      color: $color-red;
      &--left{
        justify-content: flex-start;
      }
      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-red;
            border-color: $color-red-0;
            background: $color-red-0;
          }

          &:active {
            color: $color-red;
            border-color: $color-red-01;
            background: $color-red-01;
          }
        }
      }
    }

    &.ant-btn-text--success {
      color: $color-green;

      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-green;
            border-color: $color-green-01;
            background: $color-green-01;
          }

          &:active {
            color: $color-green;
            border-color: $color-green-02;
            background: $color-green-02;
          }
        }
      }
    }


  }

  //---END type [TEXT]
  //---Ghost(Outlined)
  &.ant-btn-background-ghost {
    &.ant-btn-ghost--primary,
    &.ant-btn-primary {
      color: $color-purple;
      border-color: $color-purple;
      background: transparent;

      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-purple-3;
            border-color: $color-purple-0;
            background: $color-purple-0;
          }

          &:active {
            color: $color-purple-3;
            border-color: $color-purple-01;
            background: $color-purple-01;
          }
        }
      }
      &[disabled] {
        color: var(--new-gray-2, #5D5C66);
        border: 1px solid var(--new-gray-2, #5D5C66);
        background: transparent;
      }
    }

    &.ant-btn-ghost--warning {
      color: $color-gold;
      border-color: $color-gold;
      background: transparent;

      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-white;
            border-color: $color-gold-0;
            background: $color-gold-0;
          }

          &:active {
            color: $color-white;
            border-color: $color-gold-0;
            background: $color-gold-0;
          }
        }
      }

      &.ant-btn-ghost--white {
        color: $color-white;
        border-color: $color-white;
        background: transparent;

        &:not(:disabled) {
          &:not(.ant-btn-disabled) {
            &:hover {
              color: $color-white;
              border-color: $color-purple-3;
              background: $color-purple-3;
            }

            &:active {
              color: $color-white;
              border-color: $color-purple-3;
              background: $color-purple-3;
            }
          }
        }
      }
    }

    &.ant-btn-ghost--success{
      color: $color-green;
      border-color: $color-green;
      background: transparent;
      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-green;
            border-color: transparent;
            background: #62CC3D1A;
          }

          &:active {
            color: $color-green;
            border-color: transparent;
            background: #62CC3D1A;
          }
        }
      }
      &.ant-btn-ghost--white{
        color: $color-white;
        border-color: $color-white;
        background: transparent;
        &:not(:disabled) {
          &:not(.ant-btn-disabled) {
            &:hover {
              color: $color-white;
              border-color: $color-purple-3;
              background: $color-purple-3;
            }

            &:active {
              color: $color-white;
              border-color: $color-purple-3;
              background: $color-purple-3;
            }
          }
        }
      }
    }

    &.ant-btn-ghost--error {
      color: $color-red;
      border-color: $color-red;
      background: transparent;

      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-red;
            border-color: $color-red-0;
            background: $color-red-0;
          }

          &:active {
            color: $color-red;
            border-color: $color-red-01;
            background: $color-red-01;
          }
        }
      }
    }

    &.ant-btn-ghost--grey {
      color: $color-grey-text;
      border-color: var(--new-border, #2B292E);
      background: transparent;

      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-grey-text;
            border-color: var(--new-purple, #8D5DD9);
            background: transparent;
          }

          &:active {
            color: $color-white;
            border-color: var(--new-purple, #8D5DD9);
            background: transparent;
          }
        }
      }
    }
    &.ant-btn-ghost--white{
      color: $color-white;
      border-color: $color-white;
      background: transparent;
      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover {
            color: $color-white;
            border-color: $color-purple-3;
            background: $color-purple-3;
          }

          &:active {
            color: $color-white;
            border-color: $color-purple-3;
            background: $color-purple-3;
          }
        }
      }
    }
  }

  //---END Ghost
  //--Link
  &.ant-btn-link {
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;

    &:not(:disabled) {
      &:not(.ant-btn-disabled) {
        &:hover, &:active {
          color: $color-white;
        }
      }
    }
  }

  //---END Link

  //---icon only
  &.ant-btn-icon-only {
    justify-content: center;
    width: 44px;
    padding: 12px;

    &.ant-btn-sm {
      width: 30px;
      padding: 5px;

      svg {
        width: 20px;
      }
    }

    &.ant-btn-lg {
      width: 56px;
      padding: 16px;
    }
  }

  //---loading
  &.ant-btn-loading {
    opacity: 1 !important;
    pointer-events: none;

    > span:not(.ant-btn-loading-icon) {
      display: none;
      transition-duration: 0s !important;
    }

    > svg {
      display: none;
    }
  }

  //---popover btn
  &.ant-popover-open {
    color: $color-white;

    svg {
      transform: rotateX(180deg)
    }
  }

  //---dropdown btn
  &.ant-dropdown-open {
    color: $color-white;

    svg {
      transform: rotateX(180deg)
    }
  }
}

@media screen and (max-width: 1024px) {
  .ant-btn {
    height: 40px;
    padding: 12px 16px;
    @include fs--headline-2-m;

    svg {
      width: 16px;
    }

    &.ant-btn-sm {
      @include fs--headline-3-m;
    }

    &.ant-btn-lg {
      height: 40px;
      padding: 12px 16px;
      @include fs--headline-2-m;
      border-radius: 8px;

      svg {
        width: 16px;
      }
    }

    //--Link
    //&.ant-btn-link {
    //  @include fs--headline-4-pc;
    //}
    //---END Link

    //---icon only
    &.ant-btn-icon-only {
      width: 40px;
      padding: 12px;

      svg {
        width: 16px;

      }

      &.ant-btn-lg {
        width: 40px;
        padding: 12px;

        svg {
          width: 16px;
        }
      }
    }
  }
}
