@import "styles/vars";

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 16px 0;
  border-top: 1px solid var(--new-border, #2B292E);
  &:hover{
    background: $color-bg-3;
    transition: background $trans-time ease-in-out;
  }
  &__product {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    flex-grow: 1;
    margin-left: 8px;
  }
  &__img {
    &:hover{
      img{
        transform: scale(1.2);
      }
    }
    img {
      transition: transform $trans-time ease-in-out;
      display: block;
      width: 80px;
      height: 70px;
      object-fit: contain;
    }
  }
  &__info {
    //max-width: 200px;
    color: $color-white;
    @include fs--headline-4-pc;
  }
  &__title {
    color: $color-yellow-0;
  }
  &__label {
    margin-top: 8px;
  }
  &__float {
    width: 135px;
    margin-top: 12px;
  }
  &__float-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px ;
    margin-bottom: 7px;
    color: var(--new-white, #FFF);
  }
  &__stickers {
    gap: 8px;
  }
  &__price {
    color: $color-white;
    @include fs--headline-3-pc;
    margin-right: 16px;
  }
  &__btn-delete {
    margin-right: 16px;
  }
}
@media screen and (max-width: 1024px){
  .cart-item {
    gap: 16px;
    &__info {
      @include fs--headline-6-pc;
    }

    &__float {
      width: 200px;
    }
    &__stickers {
      margin-top: 12px;
    }
    &__price {
      color: $color-white;
      @include fs--headline-4-pc;
    }
  }
}

@media screen and (max-width: 768px){
  .cart-item {
    gap: 24px;
    flex-direction: column;
    &__product {
      justify-content: space-evenly;
      align-items: center;
      gap: 16px;
      width: 100%;
      padding-top: 0;
    }
    &__float {
      width: auto;
      min-width: 170px;
    }
    &__price {
      align-self: flex-end;
    }
    &__btn-delete {
      width: 100%!important;
    }
  }
}
