@import "styles/vars";

.catalog-filter-drawer {
  z-index: 5;
  top: auto;
  bottom: -1px;
  .ant-drawer-content {
    padding: 12px 8px 0 0 ;
    background: var(--new-bg-1, #131215);
  }
  .ant-drawer-body {
    padding-right: 8px;
    @include scrollbar-modal();
    padding-bottom: 20px;
  }
  .ant-drawer-mask {
    background: rgba(0, 0, 0, 0.1)
  }
  &__content {
    height: 100%;
  }
}
