@import "styles/vars";

.article{
  width: 100%;
  &__wrapper{
    position: relative;
    width: 100%;
    margin-top: 36px;
  }
  &__wrap{
    width: 100%;
    margin-bottom: 38px;
  }
  &__back{
    position: absolute;
    display: inline-block;
    left: 0;
    top: 0;
  }
  &__head{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $color-border;
    &--left{
      max-width: 60%;
      &-title{
        color: $color-white;
        margin-top: 0;
        margin-bottom: 12px;
        @include fs--title-2-pc;
      }
      &-author{
        @include fs--title-6-pc;
        color: $color-grey-text;
        span{
          color: $color-white;
        }
      }
    }
    &--right{
      display: flex;
      align-items: center;
      justify-content: center;
      @include fs--headline-1-pc;
      color: $color-grey-text;
      &-views{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 28px;
        position: relative;
        &:before{
          background: $color-gray-2;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          position: absolute;
          left: 12px;
          bottom: 50%;
          transform: translateY(50%);
          content: '';
        }
        svg{
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }
  }
  &__banner{
    width: 100%;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 32px;
    img{
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__content{
    width: 100%;
    margin-bottom: 32px;
    img{
      margin-top: 32px;
      margin-bottom: 32px;
      overflow: hidden;
      border-radius: 8px;
      width: 100%!important;
      height: auto;
      object-fit: cover;
    }
    h3, h4{
      color: $color-white;
      @include fs--article-title-pc;
    }
    color: $color-article-text;
    @include fs--article-desc-pc;
    b,strong{
      font-weight: 500;
    }
    a{
      color: $color-primary;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__related{
    width: 100%;
    border-top: 1px solid $color-border;
    padding-top: 38px;
    &--title{
      @include fs--title-1-pc;
      color: $color-white;
      margin-bottom: 20px;
    }
    &--item{
      &-container{
        width: calc(100% + 20px);
        margin-left: -10px;
        display: flex;
        flex-wrap: wrap;
      }
      max-width: 33.333%;
      flex: 0 0 33.333%;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      display: flex;
      align-self: stretch;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1440px){
  .article{
    .app-container{
      max-width: 1196px;
      &--xxs{
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__back{
      position: relative;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 1024px){
  .article{
    &__wrap{
      margin-bottom: 20px;
    }
    &__wrapper{
      margin-top: 20px;
    }
    &__head{
      flex-direction: column;
      padding-bottom: 17px;
      margin-bottom: 17px;
      &--left{
        margin-bottom: 16px;
        max-width: none;
        &-title{
          @include fs--title-1-m;
        }
        &-author{
          @include fs--headline-4-pc;
        }
      }
      &--right{
        @include fs--headline-4-pc;
      }
    }
    &__banner{
      margin-bottom: 24px;
    }
    &__content{
      margin-bottom: 24px;
      h3{
        @include fs--article-title-m;
      }
      @include fs--article-desc-m;
    }
    &__related{
      padding-top: 24px;
      &--title{
        @include fs--title-4-pc;
      }
      &--item{
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }
}

@media screen and (max-width: 480px){
  .article{
    &__related{
      &--item{
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}