@import "styles/vars";

.app-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary;

  &__overlay {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--new-bg-1, #131215);
    opacity: .4;
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: currentColor;

    svg {
      height: auto;
      animation: loader 1.25s infinite;
    }
  }

  &__blur {
    position: absolute;
    filter: blur(125px);
  }

  &[data-position='relative'],
  &[data-position='component'] {
    position: relative;
  }

  &[data-position='absolute'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .app-loader__overlay {
      border-radius: 8px;
    }
  }

  &[data-position='global'] {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .app-loader__overlay {
      opacity: 1;
    }
  }

  &[data-theme='light'] {
  }

  &[data-theme='dark'] {
  }
}

@keyframes loader {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
