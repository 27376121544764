@import "styles/vars";

.table-cell-img {
  &--csgo{
    img {
      width: 50px;
      height: 44px;
    }
  }
  &--dota{
    img {
      width: 74px;
      height: 42px;
      border-radius: 8px;
    }
  }
  &:hover,&:active {
    img {
      transform: scale(1.1);
    }
  }
  img {
    display: block;
    object-fit: cover;
    @include tr-cb();
    transform: scale(1);
  }
}
