@import "styles/vars";

.float-filter {
  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-top: 12px;
  }
  &__divider {
    display: block;
    flex-shrink: 0;
    width: 16px;
    height: 1px;
    background-color: $color-white;
  }
}

@media screen and (max-width: 1024px){
  .float-filter {
    &__grid {
      gap: 8px;
    }
  }
}
