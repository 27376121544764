@import "styles/vars";

.footer {
  padding: 48px 60px;
  border-top: 1px solid var(--new-border, #2B292E);
  background: var(--new-bg-1, #131215);
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 36px;
  }
  &__links {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 56px;
  }
  &__group {
    ul {
      @include fs--headline-4-pc;
      @include list-reset-style;
      li + li {
        margin-top: 16px;
      }
      a {
        color: $color-grey-text;

      }
    }
  }
  &__group-title {
    margin-bottom: 24px;
    @include fs--title-6-pc;
    color: $color-white;

  }
  &__socials {
    @include list-reset-style;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    a {
      display: inline-block;
      color: $color-gray-2;
      @include tr-ease();
      &:hover,&:active {
        color: $color-gray-0
      }
      svg {
        width: 24px;
        height: auto;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-top: 36px;
    @include fs--headline-4-pc;
    color: var(--new-gray-2, #5D5C66);
    border-top: 1px solid var(--new-border);
  }
}


@media screen and (max-width: 1280px){
  .footer {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 1024px){
  .footer{
    padding: 24px 16px;
    &__grid{
      flex-direction: column;
      margin-bottom: 28px;
    }
    &__socials{
      margin-left: auto;
      margin-top: -23px;
    }
    &__links{
      flex-direction: column;
      gap: 36px;
    }
    &__bottom{
      gap: 12px;
      padding-top: 28px;
      flex-direction: column;
      align-items: flex-start;
      line-height: 150%;
      font-weight: 300;
    }
  }
}
