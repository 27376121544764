@import "../../../../../styles/vars";

.dashboard-referral {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &__left {
    max-width: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
  }

  &__right {
    width: calc(100% - 312px);
  }

  &__card {
    width: 100%;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid $color-border;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;

    &--glow {
      border: 1px solid $color-purple-3;
      background: linear-gradient(180deg, #1F1B26 0%, rgba(31, 27, 38, 0.00) 100%)
    }

    &-head {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-title {
      @include fs--title-7-pc;
      color: $color-grey-text;
    }

    &-body {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    &-value {
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        @include fs--title-2-pc;
        color: $color-white;
      }

      span {
        @include fs--subtittle-2-pc;
        color: $color-grey-text;
      }
    }

    &-chart {
      width: 100%;
      height: 125px;
    }

    &-income {
      @include fs--headline-4-pc;
      color: $color-green;
      margin-top: 12px;
      margin-bottom: 37px;
    }
  }

  &__table {
    width: 100%;
  }

  &__table-customer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
  }

  &__table-avatar {
    width: 44px;
    height: 44px;
  }

  &__table-username {
    display: inline-block;
    color: var(--new-white, #FFF);
    @include fs--title-7-pc;
    @include text-dots(1);
    max-width: 200px;
  }
  &__table-tooltip {
    max-width: 280px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-referral {
    flex-direction: column;
    gap: 10px;

    &__card {
      &-title {
        @include fs--headline-3-m;
        margin-bottom: 12px;
      }

      &-action {
        display: none;
      }

      &-income {
        @include fs--headline-6-pc;
      }

      &-value {
        @include fs--title-3-m;

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &__left {
      max-width: none;
      flex-direction: row;
      gap: 10px;
    }

    &__right {
      width: 100%;
    }
  }
}