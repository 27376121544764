
//middle - h44, small - h36
.ant-input-number {
  padding: 6px 16px;
  border-radius: 8px;
  background: var(--new-bg-3, #1F1E21);
  border-color: var(--new-bg-3, #1F1E21);
  @include tr-ease();
  .ant-input-number-input {
    padding: 0;
    color: $color-white;
  }
  //SIZE

  &.ant-input-number-sm {
    padding: 7px 12px;
    @include fs--headline-4-pc
  }
}
