@import "src/styles/vars";

.cart-modal {
  &__wrapper {
    .ant-modal-close {
      top: 30px;
      right: 30px;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 76px 16px 32px;
    gap: 12px;
  }
  &__title {
    color: $color-white;
    @include fs--title-3-pc;
  }
  &__count {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 12px;
    color: $color-grey-text;
    @include fs--title-5-pc;
  }
  &__content {
    padding-left: 32px;
    padding-right: 8px;
  }
  &__list {
    &--scroll {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 8px;
        bottom: 0;
        height: 100px;
        background: linear-gradient(0deg, #19181C 0%, rgba(25, 24, 28, 0.00) 100%);
        pointer-events: none;
      }
    }
  }
  &__scroll {
    max-height: 50vh;
    padding-right: 24px;
    padding-bottom: 16px;
    @include scrollbar-modal();
    overflow: auto;
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 32px;
    background: var(--new-bg-3, #1F1E21);
  }
  &__summary {
    color: var(--new-grey-text, #939199);
    @include fs--title-4-pc;
    span{
      @include fs--title-6-pc;
      color: var(--new-white, #FFF);
    }
  }
  &__btn-pay {
    width: 100%;
    max-width: 190px;
  }
  &__empty {
    margin-bottom: 98px;
  }
}

@media screen and (max-width: 1024px){
  .cart-modal {
    &__wrapper {
      .ant-modal-close {
        top: 20px;
        right: 16px;
      }
    }
    &__head {
      flex-wrap: wrap;
      padding: 20px 56px 16px 16px;
    }
    &__title {
      @include fs--title-4-pc;
    }
    &__count {
      @include fs--headline-4-pc;
    }
    &__actions {
      width: 100%;
    }
    &__content {
      padding-left: 16px;
      padding-right: 8px;
      padding-bottom: 88px;
    }
    &__list {
      &--scroll {
        &:after {
          display: none;
        }
      }
    }
    &__scroll {
      max-height: 100%;
      padding-right: 0px;
    }
    &__footer {
      position: fixed;
      z-index: 1;
      bottom: 0;
      left: 0;
      gap: 24px;
      width: 100%;
      padding: 24px 16px;
      background: var(--new-bg-2, #19181C);
      box-shadow: 0px 10px 65px 0px rgba(0, 0, 0, 0.25);
    }
  }
}


@media screen and (max-width: 768px){
  .cart-modal {
    &__content {
      padding-bottom: 132px;
    }
    &__footer {
      flex-direction: column;
      align-items: flex-start;
    }
    &__btn-pay {
      align-self: center;
      max-width: 480px;
    }
  }
}
