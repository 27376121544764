@import "styles/vars";

.product-requests {
  padding: 24px 0 24px 36px;
  border-bottom: 1px solid var(--new-border, #2B292E);

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-4-pc;
  }

  &__actions {

  }

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 12px;
    margin-top: 16px;
    &--empty{
      @include fs--title-6-pc();
      color: $color-white;
      padding-bottom: 10px;
    }
  }

}

@media screen and (max-width: 1360px) {
  .product-requests {
    &__grid {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 1280px) {
  .product-requests {
    padding: 24px 0 24px 16px;
  }
}

@media screen and (max-width: 1024px) {
  .product-requests {
    padding: 24px 0;
  }
}