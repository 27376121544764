.ant-popover{
  z-index: 110;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    padding: 16px;
    border-radius: 8px;
    background: var(--new-bg-3, #1F1E21);
    box-shadow: 0px 25px 75px 0px rgba(0, 0, 0, 0.25);
  }
}
@media screen and (max-width: 1024px){
  .ant-popover{
    .ant-popover-inner {
      padding: 12px;
    }
  }
}

@media screen and (max-width: 425px){
  .ant-popover{
    &.ant-popover-placement-bottom {
      padding: 0 12px;
    }
  }
}
