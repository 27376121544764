@import "../../../../styles/vars";

.info-tooltip {
  color: $color-white;
  //@include fs--title-6-pc;
  @include fs--body-4-pc;
}


@media screen and (max-width: 1024px) {
  //.sticker-tooltip {
  //  &__title {
  //    color: $color-white;
  //    @include fs--body-3-pc;
  //  }
  //  &__grid {
  //    @include fs--headline-6-pc;
  //  }
  //}
}
