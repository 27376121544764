@import "styles/vars";

.product-card-dota {
  padding: 12px 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  height: 100%;


  &--active,
  &:hover {
    .product-card-dota__glow {
      svg {
        color: inherit;
      }
    }
    .product-card-dota__image {
      img {
        transform: scale(1.1);
      }
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    cursor: pointer;
  }

  &__glow {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    transition: color 0.3s ease-in-out;
    svg {
      color: transparent;
    }
  }

  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--price {
      @include fs--title-7-pc;
      color: $color-white;
      user-select: none;
    }

    &--sale {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;

      svg {
        width: 20px;
        height: auto;
        color: $color-white;
      }
    }
    &-percent {
      @include fs--headline-4-pc;
      color: $color-green;
      user-select: none;
      &--overpayment {
        color: $color-red;
      }
    }
  }

  &__image-wrapper {
    flex-grow: 1;
    display: block;
    padding: 16px 0 8px;
  }


  &__image {
    margin: 0 auto;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    img {
      user-select: none;
      width: 100%;
      //height: 100%;
      aspect-ratio: 1.505;
      object-fit: contain;
      @include tr-cb();
      transform: scale(1);
    }
  }

  &__attributes {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 20px;
    margin-bottom: 12px;
    &--counts {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
    }

    &--count {
      @include fs--headline-7-pc;
      color: $color-grey-text;
      padding: 4px;
      border-radius: 4px;
      background: $color-border;
    }

    &--stickers {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      min-height: 20px;
      span {
        @include fs--headline-8-pc;
        color: $color-white;
      }

      &-item {
        width: 25px;
        height: 18px;
        border-radius: 2px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          transform: scale(1.25);
        }
      }
    }
  }

  &__quality {
    width: 100%;

    &--head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 12px;
      min-height: 17px;
      &-title {
        @include fs--headline-4-pc;
        color: $color-white;
        text-transform: capitalize;
      }
    }

    border-bottom: 1px solid;
  }

  &__content {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    &--title {
      width: 100%;
      margin-bottom: 6px;
      color: $color-white;
      @include fs--headline-5-pc;
      line-height: 120%;
      @include text-dots($line-clamp: 1);
      span, .ant-typography{
        color: $color-white;
        @include fs--headline-5-pc;
        line-height: 120%;
        margin-bottom: 0;
        @include text-dots($line-clamp: 1);
        &-copy{
          margin-left: 6px;
          margin-right: 0;
          transform: translateY(-2px);
          color: $color-white;
          svg{
            width: 18px;
            height: 18px;
          }
          &:hover, &:focus{
            color: $color-purple-3;
          }
        }
      }
      .ant-typography{
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }

    &--desc {
      width: 100%;
      line-height: 120%;
      min-height: 18px;
      user-select: none;
      span {
        display: block;
        @include text-dots($line-clamp: 1);
        color: $color-yellow-0;
      }
    }
  }

  &__actions {
    width: 100%;
    margin-top: 12px;
  }

  &--small {
    overflow: hidden;

    .product-card-dota__attributes {
      justify-content: flex-end;
      margin-bottom: 8px;
      @include tr-cb();
    }

    .product-card-dota__attributes--counts {
      justify-content: flex-end;
    }

    .product-card-dota__quality {
      border-bottom: none;
      @include tr-cb();
    }

    .product-card-dota__quality--head {
      min-height: 20px;
      padding-bottom: 0;
    }

    .product-card-dota__content {
      @include tr-cb();
    }

    .product-card-dota__actions {
      position: absolute;
      bottom: -50px;
      left: 8px;
      right: 8px;
      width: auto;
      margin-top: 0;
      @include tr-cb();
    }

    .product-card-dota__content--desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px
    }

    .product-card-dota__content-icon-success {
      flex-shrink: 0;
      width: 18px;
      height: auto;
      color: $color-purple-3;
    }

    &:hover {
      .product-card-dota__actions {
        bottom: 12px;
      }

      .product-card-dota__attributes,
      .product-card-dota__content {
        opacity: 0;
        visibility: visible;
      }
    }
  }
}
