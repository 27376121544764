@import "styles/vars";

.float-range {
  &__tooltip {
    .ant-tooltip-inner {
      padding: 8px 12px;
      text-align: center;

    }
  }
  &__tooltip-range {
    @include fs--headline-4-pc;
    color: $color-white;
  }
  &__tooltip-value {
    @include fs--headline-4-pc;
    color: $color-white;
    margin-bottom: 5px;
  }
  &__tooltip-title {
    margin-top: 4px;
    @include fs--headline-6-pc;
    color: var(--new-grey-text, #939199);
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include list-reset-style;
    gap: 2px
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    height: 3px;
    padding: 5px 0;


    &:nth-child(1) {
      .float-range__thumb {
        background-color: $color-float-1;
      }

    }

    &:nth-child(2) {
      .float-range__thumb {
        background-color: $color-float-2;
      }

    }

    &:nth-child(3) {
      .float-range__thumb {
        background-color: $color-float-3;
      }

    }

    &:nth-child(4) {
      .float-range__thumb {
        background-color: $color-float-4;
      }

    }

    &:nth-child(5) {
      .float-range__thumb {
        background-color: $color-float-5;
      }

    }
    &--pointer {
      cursor: pointer;
      @include tr-ease();
      &:hover,&:active {
        .float-range__thumb {
          opacity: 0.75;
        }
      }
    }
  }
  &__bg {
    height: 3px;
    width: 100%;
    background-color: $color-float-0;
    border-radius: 2px;
  }
  &__thumb {
    position: absolute;
    z-index: 1;
    top: 50%;
    height: 3px;
    border-radius: 2px;
    transform: translateY(-50%);
  }
}
