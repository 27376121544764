@import "src/styles/vars";

.faq{
  width: 100%;
  flex-grow: 1;
  &__wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding-top: 36px;
    padding-bottom: 36px;
  }
  &__head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
  }
  &__title{
    @include fs--title-1-pc;
    color: $color-white;
  }
  &__content{
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
    &--item{
      width: 100%;
    }
  }
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
  &__filter{
    display: flex;
    align-items: center;
    justify-content: center;
    &--item{
      &-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
      }

      padding: 12px 16px;
      @include fs--headline-2-pc;
      color: $color-white;
      background: $color-bg-2;
      border-radius: 24px;
      transition: all $trans-time--fast ease-in-out;
      cursor: pointer;
      &.active, &:hover{
        background: $color-purple-2;
        color: $color-purple-3;
      }
    }
    &--nav{
      margin-left: 12px;
    }
  }
}

@media screen and (max-width: 1024px){
  .faq{
    &__wrap{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &__head{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 16px;
    }
    &__title{
      @include fs--title-4-pc;
      margin-bottom: 16px;
    }
    &__filter{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      &--item{
        &-wrap{
          gap: 4px;
          max-width: 100%;
          overflow-x: scroll;
          align-items: flex-start;
          justify-content: flex-start;
          @include scrollbar('horizontal', 4px)
        }
        padding: 8px 16px;
        @include fs--headline-2-m;
      }
      &--nav{
        margin-left: 0;
        margin-top: 16px;
        width: 100%;
        a{
          button{
            width: 100%;
          }
        }
      }
    }
  }
}
