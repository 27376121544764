@import "styles/vars";

.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 60px;
  color: $color-white;
  @include fs--title-1-pc
}
