@import 'src/styles/vars';

////---type(color)
//.ant-typography {
//  @include fs--text;
//  &.ant-typography-primary {
//    color: $color-primary;
//  }
//  &.ant-typography-secondary {
//    color: $color-bw--50;
//  }
//
//  &.ant-typography-success {
//    color: $color-success--135;
//  }
//  &[caption] {
//    @include fs--caption;
//  }
//  &[lead] {
//    @include fs--lead;
//  }
//  &[small] {
//    @include fs--text-small;
//  }
//  &[tiny] {
//    @include fs--text-tiny;
//  }
//  &[narrow] {
//    @include fs--narrow;
//  }
//}
//---link
a.ant-typography, .ant-typography a {
  color: var(--new-grey-text, #939199);
  @include fs--headline-4-pc;
  @include tr-ease();
  &:hover,&:active, &:focus {
    color: var(--gray-0, #BEBDC2);
  }

}

////---title
//h5.ant-typography, .ant-typography h5 {
//  @include fs--h5;
//  font-weight: 400;
//}
