@import "../vars";

.Toastify{
  &__toast-container {
    z-index: 1001;
    padding: 0;
  }
  &__toast-container--top-right {
    top: 210px;
    right: 60px;
  }
  &__toast{
    display: flex;
    align-items: center;
    min-height: 72px;
    padding: 15px 16px;
    color: var(--new-white, #FFF);
    border-radius: 12px;
    border: 1px solid var(--new-border, #2B292E);
    background: var(--new-bg-3, #1F1E21);
    box-shadow: $box-shadow-1
  }
  &__close-button {
    position: relative;
    top: -4px;
    right: -4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: $color-grey-text;
    opacity: 1;
    svg {
      width: 16px;
      height: auto;
    }
  }
  &__toast-body {
    padding: 0;
    color: var(--new-white, #FFF);
    @include fs--body-2-pc;
  }
  &__toast-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-right: 16px;
  }
  &__toast--info {
    .Toastify__toast-icon {
      width: 48px;
      height: 48px;
      margin-right: 16px;
      border-radius: 12px;
      color: var(--new-purple-3, #A66DFF);
      border: 1px solid var(--new-purple-3, #A66DFF);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
      svg {
        width: 32px;
        height: auto;
      }
    }
  }
  &__toast--success {
    .Toastify__toast-icon {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      color: var(--new-green-0, #2E9972);
      border: 1px solid var(--new-green-0, #2E9972);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
      svg {
        width: 32px;
        height: auto;
      }
    }
  }
  &__toast--error {
    .Toastify__toast-icon {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      color: var(--red, #E54545);
      border: 1px solid var(--red, #E54545);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
      svg {
        width: 32px;
        height: auto;
      }
    }
  }
  &__toast--warning {
    .Toastify__toast-icon {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      color: var(--new-yellow, #F2BA4D);
      border: 1px solid var(--new-yellow, #F2BA4D);
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
      svg {
        width: 32px;
        height: auto;
      }
    }
  }
  &__toast--default {
    .Toastify__toast-icon {
      width: 48px;
      height: 48px;
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
      svg {
        width: 48px;
        height: auto;
      }
    }
  }
}

.Toastify--animate {
  animation-duration: 0.5s;
}

@media screen and (max-width: 1760px){
  .Toastify {
    &__toast-container--top-right {
      top: 195px;
      right: 16px;
    }
  }
}
@media screen and (max-width: 1024px){
  .Toastify {
    &__toast-body {
      @include fs--body-1-m
    }
    &__toast-container--top-right {
      top: 175px;
    }
  }

}
