@import "styles/vars";

.sticker-filter {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-white;
    @include fs--headline-4-pc;
  }
  &__group {
    flex-direction: column;
    align-items: flex-start!important;
  }
  &__item {
    & + & {
      margin-top: 16px;
    }
  }
  &__btn {
    margin-top: 20px;
    svg{
      path {
        @include tr-ease(.1s);
      }
    }
    &--active {
      color: $color-white!important;
      svg {
        path {
          stroke: $color-purple-3;
          fill: $color-purple-3;
        }
      }
    }
  }
}
