@import "styles/vars";

.table-cell-stickers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  &--csgo {
    img {
      width: 32px;
      aspect-ratio: 1;
    }
  }
  &--dota {
    img {
      width: 44px;
      height: 32px;
      border-radius: 4px;
    }
  }

  img {
    display: block;
    object-fit: cover;
  }
}
