@import "styles/vars";

.inventory-pending {
  margin-top: 20px;
  min-height: 580px;
  &__refetch-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 156px 0;
  }
  &__page-loader {
    padding: 36px 0;
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .inventory-pending {
    margin-top: 12px;
  }
}

.deals-sale {
  margin-top: 24px;

  &__table-offers {
    white-space: nowrap;
    color: var(--new-gray-2, #5D5C66);
    @include fs--headline-4-pc;
    text-decoration: underline;
    pointer-events: none;
    cursor: pointer;
    &--active {
      color: var(--new-purple, #8D5DD9);
      pointer-events: auto;
    }
    &:hover,&:active{
      text-decoration: none;
    }

  }
}

@media screen and (max-width: 1024px) {
  .deals-sale {
    margin-top: 12px;
  }
}
