@import "styles/vars";

.dashboard{
  &__create-payment-password{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    &--icon{
      border-radius: 12px;
      border: 1px solid $color-purple;
      box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-purple;
    }
    &--title{
      @include fs--title-4-pc;
      color: $color-white;
      margin-top: 24px;
      margin-bottom: 32px;
    }
    &--button{
      width: 100%;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 12px;
    }
    &--input{
      width: 100%;
    }
    &--input + &--input{
      margin-top: 12px;
    }
  }
}