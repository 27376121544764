@import "src/styles/vars";

.ticket{
  width: 100%;
  height: 100%;
  position: relative;
  &__wrap{
    width: 100%;
    height: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
  }
  &__loader{
    padding-top: 100px;
    padding-bottom: 100px;
  }
  &__head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 36px;
    &--back, &--action{
      width: 33%;
    }
    &--action{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
    }
    &--text{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &-title{
       @include fs--headline-3-pc;
        color: $color-white;
        text-align: center;
        margin-bottom: 8px;
      }
      &-desc{
        @include fs--headline-4-pc;
        color: $color-grey-text;
        text-align: center;
      }
    }
  }
  &__content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    &--chat{
      width: 100%;
      height: calc(100% - 225px);
    }
    &--input{
      width: 100%;
      margin-top: 34px;
    }
  }
}

@media screen and (max-width: 1024px){
  .ticket{
    &__wrap{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__head{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 16px;
      &--back{
        width: auto;
        margin-bottom: 20px;
      }
      &--text{
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 12px;
        &-title{
          text-align: left;
          @include fs--title-3-m;
        }
        &-desc{
          text-align: left;
          @include fs--body-3-m;
        }
      }
      &--action{
        width: 100%;
        button{
          width: 100%;
        }
      }
    }
  }
}