@import "src/styles/vars";

.remove-trade-modal {

  color: $color-white;
  text-align: center;
  &__body {
    padding: 24px 24px 0;
  }
  &__icon{
    width: 48px;
    height: 48px;
    margin: 0 auto;
    padding: 4px;
  }

  &__title {
    margin-top: 24px;
    @include fs--title-4-pc
  }

  &__text {
    margin-top: 12px;
    @include fs--body-2-pc
  }

  &__actions {
    padding: 32px 24px 12px;
  }

  &__btn-remove{
  }
  &__btn-cancel {
    margin-top: 12px;

  }
}

@media screen and (max-width: 1024px) {
  .remove-trade-modal__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--new-bg-2, #19181C);
    box-shadow: 0px 10px 65px 0px rgba(0, 0, 0, 0.25);
  }
  .remove-trade-modal__btn-remove {
    max-width: 480px;
  }
}

@media screen and (max-width: 768px) {
  .remove-trade-modal__body {
    padding: 20px 16px;
  }
  .remove-trade-modal__actions {
    padding: 24px 16px 0;
  }


  .hacked-attention-modal__head {
    height: 240px;
  }

  .hacked-attention-modal__body {
    padding: 24px 16px;
  }

  .remove-trade-modal__icon {

  }
  .remove-trade-modal__title {
    margin-bottom: 8px;
    @include fs--headline-3-pc;
  }

  .remove-trade-modal__text {
    @include fs--body-4-pc;
  }
}
