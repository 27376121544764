@import "styles/vars";

.header-alert {
  &__message{
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
    font-variant-numeric: tabular-nums;
  }
}
