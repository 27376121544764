@import "styles/vars";

.product-similar {
  position: relative;
  z-index: 1;
  padding: 24px 0 24px 36px;
  border-bottom: 1px solid var(--new-border, #2B292E);
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-4-pc;
  }

  &__table {
    margin-top: 16px;
  }

  &__table-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  &__drawer {
    height: calc(100vh - 88px);
    top: 88px;
  }
}

@media screen and (max-width: 1280px) {
  .product-similar {
    padding: 24px 0 24px 16px;
  }
}

@media screen and (max-width: 1024px) {
  .product-similar {
    padding: 24px 0;
    border-bottom: 1px solid var(--new-border, #2B292E);
    &__drawer {
      height: calc(100vh - 60px);
      top: 60px;
    }
  }
}
