@import "styles/vars";

.history-withdrawal {
  margin-top: 24px;
  &__unknown-card {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 36px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #CCC;
    background: #CCC;
    &:after {
      content: '';
      position: absolute;
      top: 4px;
      width: 100%;
      background-color: $color-grey-text;
      height: 4px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .history-withdrawal {
    margin-top: 12px;
  }
}

@media screen and (max-width: 768px) {
  .history-withdrawal {
    position: relative;
    z-index: 1;
    &__empty-btn-market {
      max-width: 480px;
    }
  }
}
