@import "src/styles/vars";

.home-cta{
  width: 100%;
  &__wrap{
    padding-top: 160px;
    padding-bottom: 80px;
    width: 100%;
  }
  &__banner{
    width: 100%;
    position: relative;
    border-radius: 16px;
    background: linear-gradient(154deg, #8F5EDC 0%, #3F226D 100%);
    &__wrap{
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 93px 133px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }
    &__title{
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      color: $color-white;
      max-width: 505px;
      margin-bottom: 32px;
    }
    &__bg{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      opacity: 0.75;
      background: url("../../../../../src/assets/img/HomeCtaMesh.png") center center no-repeat;
      background-size: cover;
      border-radius: 16px;
    }
    &__image{
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      height: 465px;
      width: auto;
      img{
        width: auto;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 1760px){
  .home-cta__banner__wrap{
    padding-left: 64px;
  }
}
@media screen and (max-width: 1280px){
  .home-cta__banner__image{
    height: 300px;
  }
}

@media screen and (max-width: 1024px){
  .home-cta{
    .app-container{
      padding-left: 0;
      padding-right: 0;
    }
    &__banner{
      &__button{
        width: 100%;
        a{
          display: block;
          width: 100%;
          button{
            width: 100%;
          }
        }
      }
      &__title{
        @include fs--title-h1-m;
        max-width: 290px;
        margin-bottom: 150px;
      }
      &__image{
        left: auto;
        right: 0;
        width: 70%;
        height: auto;
        img{
          width: 100%;
          height: auto;
        }
      }
      border-radius: 0;
      &__wrap{
        padding: 32px 16px;
      }
    }

    &__wrap{
      padding-bottom: 0;
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 500px){
  .home-cta__banner{
    overflow: hidden;
  }
  .home-cta__banner__image{
    width: 150%;
    right: -50%;
  }
  .home-cta__banner__title{
    margin-bottom: 218px;
  }
  .home-cta__banner__bg{
    border-radius: 0;
  }
}
