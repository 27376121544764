@import "../../../../../styles/vars";

.dashboard-block {
  width: 100%;
  padding: 28px 180px 28px 36px;
  border-top: 1px solid $color-border;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &--gradient {
    background: $gradient-gray;
  }
  &--columns {
    flex-direction: column;

    .dashboard-block__left {
      padding-right: 0;
      padding-bottom: 20px;
      max-width: none;
    }

    .dashboard-block__right {
      width: 100%;
    }
  }

  &__left {
    max-width: 420px;
    width: 100%;
    padding-right: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__title {
    @include fs--title-6-pc;
    color: $color-white;
  }

  &__desc {
    margin-top: 8px;
    @include fs--body-2-pc;
    color: $color-grey-text;
  }

  &__right {
    width: calc(100% - 420px);
  }
}

@media screen and (max-width: 1680px) {
  .dashboard-block {
    padding-right: 60px;
  }
}

@media screen and (max-width: 1440px) {
  .dashboard-block {
    &__left {
      max-width: 325px;
    }

    &__right {
      width: calc(100% - 320px);
    }
  }
}

@media screen and (max-width: 1360px) {
  .dashboard-block {
    flex-direction: column;

    &__left {
      padding-right: 0;
      padding-bottom: 20px;
      max-width: none;
    }

    &__right {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1280px) {
  .dashboard--block {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-block {
    padding: 24px 16px;

    &__title {
      @include fs--title-3-m;
    }

    &__desc {
      @include fs--body-3-m;
    }
  }
}
