@import 'src/styles/vars';

.ant-badge {
  &.ant-badge-status {
    .ant-badge-status-text {
      @include fs--headline-4-pc;
      color: $color-grey-text;
    }

    .ant-badge-status-default {
      background-color: $color-grey-text;
    }
  }

  .ant-badge-count {
    z-index: 2;
    padding: 0 5px;
    min-width: 18px;
    height: 18px;
    color: $color-white;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 13px;
    box-shadow: 0 0 0 3px $color-bg-1;
    background: $color-purple;
  }

  .ant-scroll-number {
    .ant-scroll-number-only {
      height: 18px;
    }
  }

  .ant-badge-status-dot {
    &.ant-badge-status-error {
      filter: drop-shadow(0px 0px 8px $color-red);
    }
    &.ant-badge-status-success {
      filter: drop-shadow(0px 0px 8px $color-success);
    }
  }
}
