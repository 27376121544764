@import "styles/vars";

.header-nav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @include list-reset-style();

  li {

  }
  a {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0;
    @include fs--headline-2-pc;
    @include tr-ease();
    color: $color-white;
    svg {
      margin-right: 8px;
    }
    &:hover,
    &:active {
      color: $color-grey-text;
    }
  }
}

@media screen and (max-width: 1680px) {
  .header-nav {
    gap: 12px;
    a {
      font-size: 14px;
      svg {
        margin-right: 4px;
      }
    }
  }
}
@media screen and (max-width: 1280px){
  .header-nav {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 12px;
    li {
      width: 100%;
    }
    a {
      display: block;
    }
  }
}

@media screen and (max-width: 1024px){
  .header-nav {
    a {
      @include fs--headline-4-pc;
    }
  }
}
