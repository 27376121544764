@import "styles/vars";

.header-drawer {
  top: 100%;
  height: calc(100vh - 100%);

  .ant-drawer-content {
    padding: 20px 8px 0 0;
  }

  .ant-drawer-body {
    padding-left: 16px;
    padding-right: 8px;
    @include scrollbar-modal
  }

  &__content {


  }

  &__radio-game {
    width: 100%;
    margin-bottom: 12px;

    .ant-radio-button-wrapper {
      padding: 0;
      height: 40px;
      width: 50%;
      background-color: var(--new-bg-3, #1F1E21) !important;

      &:nth-child(1) {
        &.ant-radio-button-wrapper-checked {
          background-color: #2BB45A !important;

          svg {
            rect {
              fill: #2BB45A;
            }
          }
        }
      }

      &:nth-child(2) {
        &.ant-radio-button-wrapper-checked {
          background-color: #E54545 !important;

          svg {
            rect {
              fill: #E54545;
            }
          }
        }
      }

    }

    svg {
      width: 32px;
      height: auto;

      rect {
        fill: var(--new-bg-3, #1F1E21);
        transition: fill 0.2s;
      }
    }
  }

  &__card {
    margin-bottom: 24px;
    padding: 4px 0;
    border-radius: 8px;
    background: var(--new-bg-3, #1F1E21);

    ul {
      @include list-reset-style
    }

    li {
      display: block;
      overflow: hidden;

      &.header-drawer__profile-nav--danger {
        padding: 13px 12px;
        color: $color-red;
        cursor: pointer;

        &:hover, &:active {
          background: $color-red-0;
        }
      }

    }

    a {
      display: block;
      padding: 16px 12px;
      @include fs--headline-1-m();
      @include tr-cb();
      color: $color-white;

      &:hover, &:active {
        color: $color-grey-text;
        background-color: rgba(255, 255, 255, 0.04);
      }
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 11px 12px;
    color: $color-white;
    @include fs--headline-2-m;
    cursor: pointer;

    &:hover, &:active {
      color: $color-grey-text;
      background-color: rgba(255, 255, 255, 0.04);
    }

    .ant-badge {
      .ant-badge-count {
        box-shadow: none;
      }
    }
  }

  &__avatar {
    flex-shrink: 0;
    margin-right: 4px;
  }
  &__name {
    max-width: 130px;
    @include text-dots();
  }

  &__profile-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    color: $color-gray-2;
  }

}
