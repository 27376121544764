@import "styles/vars";

.header-profile {
  &__avatar {
    flex-shrink: 0;
    margin-right: 6px;
  }
  &__name {
    max-width: 100px;
    @include text-dots();
  }

  &__btn-menu {
    margin-inline-end: 0;
    color: $color-white!important;
    border-color: $color-bg-3!important;
    background: $color-bg-3!important;
    svg {
      width: 24px;
      color: $color-grey-text!important;
    }
    &:hover, &:active{
      color: $color-white!important;
      border-color: $color-bg-05!important;
      background: $color-bg-05!important;
    }
    &.ant-dropdown-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      svg {
        color: $color-white!important;
      }
    }
  }

  &__dropdown-menu {
    .ant-dropdown-menu {
      overflow: hidden;
      .ant-dropdown-menu-item {
        border-radius: 0;
      }
    }
  }

  &__btn-notice{
    color: $color-white!important;
    border-color: $color-bg-0!important;
    background: $color-bg-0!important;
    &:hover, &:active{
      color: $color-white!important;
      border-color: $color-bg-04!important;
      background: $color-bg-04!important;
    }
  }
}
