@import "styles/vars";

.product-aside {
  padding-top: 20px;
  &__nav {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-top: 20px;
  }
  &__link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 16px 12px;
    @include fs--headline-2-pc;
    color: $color-white;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    transition: background $trans-time--fast ease-in-out;
    &:hover, &--active{
      color: $color-purple-3;
      background: $color-purple-2;
    }
    &--active{
      pointer-events: none;
    }
  }
}
