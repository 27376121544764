@import "styles/vars";

.withdraw-balance-confirmation{
  width: 100%;
  &__head{
    width: 100%;
    position: relative;
    height: 385px;
    &--small{
      height: 235px;
    }
    &--bg{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 0;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    &--action{
      padding-top: 32px;
      padding-left: 32px;
    }
    &--content{
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 32px;
      background: rgba(0, 0, 0, 0.30);
      backdrop-filter: blur(50px);
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      &-left{
        max-width: 395px;
        width: 100%;
        h3{
          margin-top: 0;
          margin-bottom: 8px;
          @include fs--title-3-pc;
          color: $color-white;
        }
        p{
          margin: 0;
          @include fs--body-3-pc;
          color: $color-white;
        }
      }
      &-right{
        p{
          @include fs--headline-4-pc;
          color: $color-lily;
          margin-top: 0;
          margin-bottom: 12px;
        }
        &-sum{
          @include fs--title-h2-pc;
          color: $color-lily;
          span{
            color: $color-white;
          }
        }
      }
    }
  }
  &__desc{
    width: 100%;
    padding: 32px;
    &--title{
      color: $color-yellow;
      @include fs--title-4-pc;
      margin-bottom: 12px;
    }
    &--content{
      @include fs--body-2-pc;
      color: $color-white;
    }
  }
  &__body{
    width: 100%;
    padding: 32px 32px 32px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    background: $color-bg-1;
    &--card{
      width: 100%;
      background: $color-bg-2;
      border-radius: 12px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-self: stretch;
      justify-content: space-between;
      &-label{
        @include fs--headline-4-pc;
        color: $color-grey-text;
      }
      &-title{
        @include fs--title-4-pc;
        color: $color-white;
        margin-bottom: 8px;
        span{
          @include fs--headline-4-pc;
          color: $color-grey-text;
        }
        &--wide{
          @include fs--headline-5-pc;
          color: $color-white;
        }
      }
    }
  }
  &__actions{
    width: 100%;
    padding: 32px;
    &--light{
      background: $color-bg-3;
    }
  }
}

@media screen and (max-width: 1024px){
  .withdraw-balance-confirmation{
    &__desc{
      padding: 16px;
    }
    &__actions{
      background: $color-bg-2;
      padding: 24px 16px;
    }
    &__body{
      padding: 12px;
      flex-direction: column;
      &--card{
        &-title{
          @include fs--title-6-pc;
        }
        &-label{
          @include fs--headline-5-pc;
        }
      }
    }
    &__head{
      &--action {
        padding-left: 16px;
        padding-top: 16px;
      }
      &--content{
        padding: 16px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        &-left{
          max-width: none;
          margin-bottom: 16px;
          h3{
            @include fs--title-4-pc;
          }
          p{
            @include fs--body-3-pc;
          }
        }
        &-right{
          &-sum{
            @include fs--title-4-pc;
            font-size: 28px;
            span{
              span{
                font-size: 20px;
                color: $color-lily;
              }
            }
          }
        }
      }
    }
  }
}