@import "src/styles/vars";

.home-assortment{
  width: 100%;
  &__wrap{
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    @include fs--title-1-pc;
    text-align: center;
    color: $color-white;
    margin-bottom: 16px;
  }
  &__desc{
    text-align: center;
    color: $color-grey-text;
    @include fs--headline-2-pc;
  }
  &__block{
    &-wrap{
      margin-top: 48px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 48px;
    }

    width: 100%;
  }
}
@media screen and (min-width: 1920px){
  .home-assortment{
    .app-container{
        max-width: 1920px;
    }
  }
}

@media screen and (max-width: 1760px){
  .home-assortment{
    .app-container{
      padding-left: 52px;
      padding-right: 52px;
    }
    &-block__body-item{
      //max-width: calc(25% - 12px);
      //width: 100%;
    }
    &__block-wrap{
      gap: 32px;
    }
  }
}
@media screen and (max-width: 1280px){
  .home-assortment{
    &-block__body-item{
      max-width: calc(33.333% - 12px);
      width: 100%;
    }
  }
}
@media screen and (max-width: 1024px){
  .home-assortment{
    .app-container{
      padding-left: 0;
      padding-right: 0;
    }
    &__wrap{
      padding-top: 64px;
      padding-bottom: 30px;
    }
    &__title{
      @include fs--title-1-m;
      padding-left: 16px;
      padding-right: 16px;
      margin-bottom: 8px;
    }
    &__desc{
      @include fs--body-1-m;
      padding-left: 16px;
      padding-right: 16px;
      text-align: center;
    }
    &__block-wrap{
      margin-top: 20px;
      gap: 20px;
    }
  }
}
@media screen and (max-width: 640px){
  .home-assortment{
    &-block__body-item{
      max-width: calc(50% - 12px);
      width: 100%;
    }
  }
}
