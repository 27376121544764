@import "styles/vars";

.category-filter {
  &:first-child {
    .category-filter__btn {
      padding-left: 20px;
    }
  }
  &__btn {
    height: 40px;
    padding: 12px;

    &--selected {
      color: $color-white !important;
    }
  }

  &__icon {
    width: 16px;
    height: auto;
    margin-right: 4px;
    transform: rotate(0) !important;
  }

  &__arrow {
    width: 16px !important;
    height: auto;
  }

  &__wrapper {
    .ant-popover-inner {
      padding: 12px;
      box-shadow: none;
    }
  }

  &__dropdown {
    @include scrollbar-modal();
    max-height: 270px;
    margin-right: -4px;
    padding-right: 4px;
    overflow: auto;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(5, auto);
    max-width: 600px;
    @include list-reset-style;

    li {

    }

    &--compact {
      grid-template-columns: repeat(5, auto);

      .category-filter__card {
        width: 90px;
        padding: 0;
        border-radius: 6px;

        .category-filter__card-block {
          width: 90px;
          height: 51px;
        }
      }
    }

  }

  &__card {
    position: relative;
    width: 112px;
    margin: 4px;
    padding: 8px 11px;
    border-radius: 8px;
    background: var(--new-bg-2, #19181C);
    border: 1px solid var(--new-bg-2, #19181C);
    cursor: pointer;

    &--active {
      border: 1px solid $color-purple;
    }

    &--active, &:hover, &:active {
      .category-filter__card-title {
        opacity: 1;
      }

      img {
        filter: blur(2.5px);
      }

      svg {
        filter: blur(2.5px);
      }

      span {
        filter: blur(2.5px);
      }
    }
  }

  &__card-title {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 4px;
    text-align: center;
    @include fs--headline-6-pc;
    color: $color-white;
    opacity: 0;
    @include tr-ease();
    @include user-select-none();
  }

  &__card-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90px;
    height: 64px;

    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include tr-ease();
      border-radius: 6px;
    }

    svg {
      width: 32px;
      height: auto;
      color: $color-grey-text;
    }

    span {
      padding: 0 4px;
      text-align: center;
      @include fs--headline-6-pc;
      color: $color-white;
      @include user-select-none();
    }
  }
}


@media screen and (max-width: 1024px) {
  .category-filter {
    &:first-child {
      .category-filter__btn {
        padding-left: 12px;
      }
    }
    &__btn {
      &.ant-btn {
        padding: 12px 4px;
        @include fs--headline-6-pc
      }
    }

    &__content {
      grid-template-columns: repeat(3, auto);

      &--compact {
        grid-template-columns: repeat(3, auto);

        .category-filter__card {
          width: 70px;
          border-radius: 5px;

          .category-filter__card-block {
            width: 70px;
            height: 40px;
          }
        }
      }

    }

    &__card {
      width: 70px;
      padding: 8px;

      .category-filter__card-block {
        width: 53px;
        height: 38px;

        img {
          border-radius: 5px;
        }
      }

      svg {
        width: 24px;
        height: auto;
      }
    }
  }
}
