@import "styles/vars";

.header-trades {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  margin-right: 16px;
  @include fs--headline-4-pc;
  color: var(--new-grey-text, #939199);
}

@media screen and (max-width: 1024px){
  .header-trades {
    padding: 8px 0 20px;
    margin-right: 0;
  }
}
