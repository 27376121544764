@import "styles/vars";

.catalog-head {
  position: relative;
  z-index: 3;

  &__top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }

  &__autocomplete {
    max-width: 415px;
  }

  &__sort {
    width: 290px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    width: 100%;
    margin-top: 12px;
  }

  &__filters {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 56px;
    width: calc(100% - 120px);
  }

  &__scroll {
    position: relative;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    min-height: 100%;
    padding-bottom: 8px;
    @include scrollbar('horizontal', 4px);
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }
  &__categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }
  &__category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 0;
    border-radius: 8px;
    background: var(--new-bg-2, #19181C);
    &:last-child {
      flex-grow: 1;
    }
  }
  &__switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    white-space: nowrap;
    gap: 8px;
    padding: 0 12px 0 12px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background: var(--New-BG-2, #19181C);
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: -28px;
      width: 36px;
      height: 100%;
      background: linear-gradient(to left, $color-bg-2, transparent);
    }

    span {
      color: $color-white;
      @include fs--headline-5-pc();
    }
  }
}

@media screen and (max-width: 1024px) {
  .catalog-head__category {
    padding: 0;
  }
  .catalog-head__btn-filter {
    flex-shrink: 0;

    &--active {
      color: $color-purple-3 !important;
      background: var(--new-purple-2, #1F1B26) !important;
    }
  }

  .catalog-head__top {
    &--search-focused {
      .catalog-head__btn-filter {
        margin-left: -48px;
        opacity: 0;
      }

      .catalog-head__autocomplete {
        max-width: 423px;
      }

      .catalog-head__autocomplete-dropdown {
        max-width: 423px;
      }
    }
  }
  .catalog-head__autocomplete {
    width: 100%;
    max-width: 375px;
    @include tr-cb()
  }
  .catalog-head__autocomplete-dropdown {
    width: 100% !important;
    max-width: 375px;
    //@include tr-cb()
  }

  .catalog-head__autocomplete-title {
    @include fs--headline-4-pc
  }

  .catalog-head__filters {
    width: 100%;
    height: 40px;
  }
}
