.ant-pagination {
  .ant-pagination-disabled,
  .ant-pagination-disabled:hover {
    .ant-pagination-item-link {
      color: var(--new-grey-text, #939199);
    }
  }


  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    height: 38px;
    min-width: 38px;
    line-height: 38px;

    button, svg {
      color: $color-white;
    }

    &.ant-pagination-disabled {
      button, svg {
        color: var(--new-grey-text, #939199);
      }
    }
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    .ant-pagination-item-container {
      .ant-pagination-item-link-icon {
        color: $color-white;
      }
    }

    &:hover {
      .ant-pagination-item-container {
        background: #2E2B32;
        border-radius: 8px;
      }
    }
  }

  .ant-pagination-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    min-width: 38px;
    line-height: 36px;
    border-radius: 8px;

    a {
      color: $color-white;
    }

    &:hover {
      background: #2E2B32 !important;
    }
  }

  .ant-pagination-item-active {
    border-radius: 8px;
    border: 1px solid var(--new-purple, #8D5DD9) !important;
    background: #1F1929;
    pointer-events: none;
    font-weight: inherit;

    a {
      color: var(--new-purple, #8D5DD9) !important;
    }
  }
}

