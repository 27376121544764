@import "styles/vars";

.ant-table-cell{
  &__status{
    min-width: 110px;
    text-transform: capitalize;
  }
}

.inventory-pending {
  .timer__time{
    font-variant-numeric: tabular-nums;
  }
  margin-top: 20px;
  &__refetch-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 156px 0;
  }
  &__page-loader {
    padding: 36px 0;
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .inventory-pending {
    margin-top: 12px;
  }
}
