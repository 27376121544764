//small - h38, middle - h44, large - h56
//

.ant-input-affix-wrapper {
  padding: 10px 12px;
  border-radius: 8px;
  color: var(--new-grey-text, #939199);
  background: var(--new-bg-3, #1F1E21);
  border-color: var(--new-bg-3, #1F1E21);
  @include tr-ease();
  .ant-input {
    color: $color-white;
    background-color: transparent;
    @include tr-ease();
  }
  .ant-input-prefix {
    margin-inline-end: 8px;
  }

  &:hover {
    color: $color-white;
    border-color: var(--new-bg-3, #1F1E21);
    .ant-input {
      color: $color-white;
    }
  }

  &.ant-input-affix-wrapper-focused {
    color: $color-white;
    .ant-input {
      color: $color-white;
    }
  }

  &.ant-input-affix-wrapper-readonly {
    color: var(--new-grey-text, #939199);
    background: var(--new-bg-3, #1F1E21);
    border-color: var(--new-bg-3, #1F1E21)!important;
    .ant-input {
      color: var(--new-grey-text, #939199);
    }
  }

  &.ant-input-simpler-wrapper {
    .ant-input-prefix {
      display: none;
    }
  }
  //SIZE

  &.ant-input-affix-wrapper-sm {
    padding: 7px 12px;
    @include fs--headline-4-pc
  }
  &.ant-input-affix-wrapper-lg {
    padding: 15px;
  }
  //STYLE
  &.ant-input--dark {
    border: 1px solid var(--new-bg-2, #19181C);
    background: var(--new-bg-2, #19181C);
    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border: 1px solid var(--new-bg-2, #19181C);
      }
    }
  }
  &.ant-input--ghost {
    border: 1px solid var(--new-border, #2B292E);
    background: var(--new-bg-2, #19181C);
    &:not(.ant-input-affix-wrapper-disabled) {
      &:hover {
        border: 1px solid var(--new-border, #2B292E);
      }
    }
    &.ant-input-affix-wrapper-focused {
      color: $color-white;
      .ant-input {
        color: $color-white;
      }
    }
  }
}

.ant-input{
  &--borderless{
    color: $color-white;
    background: $color-bg-3;
    border: none;
    padding: 13px 16px;
  }
  &-group{
    .ant-input{
      color: $color-white;
      background: $color-bg-3;
      padding: 12px 12px;
      border: none;
    }
    .ant-input-group-addon{
      min-width: 180px;
      padding: 10px 12px;
      text-align: left;
      background: $color-bg-0;
      border: none;
      border-radius: 8px;
    }
    .ant-input-affix-wrapper{
      &:not(.ant-input-affix-wrapper-disabled):hover {
        border-color: var(--new-bg-3, #1F1E21);
      }
      &:focus{
        box-shadow: none;
        border-color: var(--new-bg-3, #1F1E21);
      }
    }
  }
}


.ant-input-group-wrapper.ant-input-group-wrapper--custom-addon {
  .ant-input-group-addon {
    min-width: 180px;
    padding: 10px 12px;
    text-align: left;
    background: $color-bg-0;
    border-style: solid;
    border-color: transparent;
    border-radius: 8px 0 0 8px;
    border-width: 1px 0 1px 1px;
    svg{
      width: 20px;
      height: auto;
    }
    > span {
      color: $color-white;
    }
    svg + span {
      display: inline-block;
      margin-left: 8px;
      transform: translateY(1px);
    }
    span + svg {
      margin-right: 8px;
    }
    > .ant-btn {
      vertical-align: top;
    }
  }
  .ant-input{
    color: $color-white;
    background: $color-bg-3;
    padding: 11px 12px;
    border-style: solid;
    border-color: transparent;
    border-radius: 0;
    border-width: 1px 0 1px 0;
    &:focus {
      border-inline-end-width: 0!important;
      box-shadow: none!important;
    }
  }
  .ant-input-affix-wrapper,
  .ant-input + .ant-input-group-addon {
    min-width: auto;
    padding: 0;
    background: $color-bg-3;
    border-radius: 0 8px 8px 0;
    border-width: 1px 1px 1px 0;
    .ant-btn{
      border-radius: 0;
      &:not(:last-child) {
        margin-inline-end: 8px;
      }
    }
    .ant-btn:last-child {
      border-radius: 0 8px 8px 0;
    }
    .ant-btn + .ant-btn{
      &:before{
        position: absolute;
        left: -5px;
        background: $color-border;
        width: 1px;
        height: calc(100% - 6px);
        bottom: 50%;
        transform: translateY(50%);
        content: '';
      }
    }
  }
  //---
  .ant-input-affix-wrapper{
    .ant-input {
      border-width: 0;
    }
  }
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
  border: 1px solid #ff4d4f!important;
  &:hover {
    border: 1px solid #ff4d4f!important;
  }
}

.ant-input-group-wrapper-status-error {
  .ant-input-group-addon{
    border-color: #ff4d4f!important;
    border: 1px solid #ff4d4f;
    >span{
      color: #ff4d4f!important;
    }
  }
  &.ant-input-group-wrapper--custom-addon{
    .ant-input-group-addon {
      border-color: #ff4d4f!important;
      >span{
        color: #ff4d4f!important;
      }
    }
    .ant-input{
      border-color: #ff4d4f!important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .ant-input-affix-wrapper {
    &.ant-input-affix-wrapper-lg{
      padding: 7px;
    }
  }
  .ant-input.ant-input-lg{
    font-size: 14px;
  }
  .ant-input-group-wrapper.ant-input-group-wrapper--custom-affix {
    .ant-input-group-addon {
      svg{
        display: none;
      }
    }
    .ant-input-affix-wrapper{
      &:before {
        display: none;
      }
      height: 40px;
    }
    .ant-input-suffix {
      .ant-btn {
        height: 100%;
      }
    }
  }
  .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon {
    .ant-input-group {
      height: 40px;
    }
    .ant-input-group-addon {
      > svg{
        display: none;
      }
    }
    .ant-input {
      padding: 9px 12px;
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-input-group-wrapper.ant-input-group-wrapper--custom-affix {
    .ant-input-affix-wrapper{
      flex-direction: column;
      align-items: flex-end;
      .ant-input-suffix {
        position: relative;
        margin-top: 4px;
        .ant-btn {
          border-radius: 8px;
        }
      }
    }
  }
  .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon {
    .ant-input-group {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .ant-input-group-addon {
      display: flex;
      align-items: center;
      vertical-align: middle;
    }

    .ant-input{
      width: calc(100% - 180px);
      @include fs--headline-1-m;
      border-width: 1px 1px 1px 0;
      border-radius: 0 8px 8px 0!important;
      &:focus {
        border-inline-end-width: 1px!important;
      }
    }
    .ant-input + .ant-input-group-addon {
      width: auto;
      margin-top: 4px;
      margin-right: 0;
      margin-left: auto;
      border-radius: 8px;
      background-color: transparent;
      border-width: 0;
      .ant-btn {
        border-radius: 8px;
        &:last-child {
          border-radius: 8px;
        }
      }
    }
  }
}

//@media screen and (max-width: 480px) {
//  .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon {
//    .ant-input + .ant-input-group-addon {
//      margin-right: auto;
//      margin-left: 0;
//    }
//  }
//}
