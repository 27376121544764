@import "styles/vars";

.inventory-expose-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  padding: 20px 2px 20px 0;
  border-top: 1px solid var(--new-border, #2B292E);
  #buyerPrice_extra{
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    color: #E5D126;
  }
  &__left {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    flex-grow: 1;
    width: 270px;
    flex-shrink: 0;
  }

  &__img {
    flex-shrink: 0;
    position: relative;
    width: 80px;
    height: 70px;
    cursor: pointer;

    img {
      position: relative;
      z-index: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include tr-cb();
    }

    &:hover, &:active {
      .inventory-expose-item__img-mask {
        opacity: 1;
      }

      img {
        opacity: 0.25;
        filter: blur(2.5px);
      }
    }
  }

  &__img-mask {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-white;
    @include tr-cb();
    svg {
      position: relative;
      z-index: 1;
      width: 32px;
      height: auto;
    }
  }

  &__info {
    flex-grow: 1;
    color: var(--new-white, #FFF);
    @include fs--headline-4-pc;
  }

  &__title {
    color: $color-yellow-0;
  }

  &__label {
    margin-top: 12px;
  }

  &__float {
    margin-top: 8px;
  }

  &__float-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px ;
    margin-bottom: 7px;
    //color: var(--new-grey-text, #939199);
    color: var(--new-white, #FFF);
    span {
      color: var(--new-white, #FFF);
    }
  }
  &__stickers {
    gap: 2px;
    margin-top: 16px;
  }

  &__sticker {
    width: 28px;
  }

  &__prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
    text-align: center;
  }

  &__price {
    color: var(--new-grey-text, #939199);
    @include fs--headline-6-pc;
    white-space: nowrap;

    span {
      display: block;
      margin-top: 8px;
      color: var(--new-white, #FFF);
    }
  }

  &__right {
    width: auto;
    flex-grow: 1;
    //flex-shrink: 0;
  }

  &__form-grid {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 12px;
    margin-top: 12px;
  }

  &__input {
    & + & {
      margin-top: 12px;
    }

    label {
      display: block;
      margin-bottom: 8px;
      color: var(--new-white, #FFF);
      @include fs--headline-4-pc;
    }

    .ant-input-affix-wrapper {
      padding: 5px 5px 5px 12px;
    }
  }

  &__input-btn {
    width: 76px;
    &.ant-btn {
      height: 26px;
      padding: 8px 12px;
      color: var(--new-white, #FFF);
      @include fs--headline-10-pc;
    }
  }

  &__counter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }

  &__btn-count {
    &.ant-btn {
      width: 38px;
      height: 38px;
    }
  }

  &__input-count {
    width: 60px;
  }

  &__btn-auto {
    margin-top: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .inventory-expose-item {
    justify-content: space-between;
  }
  .inventory-expose-item__left {
    flex-grow: 1;
  }

  .inventory-expose-item__info {
    flex-grow: 1;
  }
  .inventory-expose-item__content {
    width: 100%;
    max-width: 180px;
  }
  .inventory-expose-item__right {
    width: 260px;
    flex-grow: unset;
  }
}

@media screen and (max-width: 768px) {
  .inventory-expose-item {
    flex-direction: column;
    gap: 24px;

    &__left {
      justify-content: space-evenly;
      align-items: center;
      gap: 16px;
      width: 100%;
      padding-top: 0;
    }

    &__img {
      width: 92px;
      height: 67px;
    }

    &__info {
      flex-grow: 0;
      width: 170px;
      @include fs--headline-6-pc;
    }


    &__stickers {
      gap: 4px;
      margin-top: 12px;
    }

    &__prices {
      width: 100%;
      justify-content: space-evenly;
      margin-top: 24px;

    }

    &__price {
      @include fs--headline-4-pc;

      span {
        margin-top: 12px;
        text-align: left;
      }
    }

    &__right {
      width: 100%;
      max-width: 100%;
    }

    &__form-grid {
      flex-direction: column;
      gap: 8px;
      margin-top: 16px;
    }

    &__input {
      width: 100%;

      & + & {
        margin-top: 16px;
      }

      label {
        @include fs--headline-1-m;
      }
    }

    &__input-btn {
      &.ant-btn {
        @include fs--headline-3-m;
      }
    }

    &__counter {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 4px;
    }

    &__input-count {
      width: 100%;
    }

    &__btn-delete {
      margin-top: 24px;

      &.ant-btn {
        width: 100%;
      }
    }
  }
}
