@import 'src/styles/vars';

.header-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 120;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 22px 60px;
  background: var(--new-bg-2, #19181C);
  &__logo {
    svg {
      width: 147px;
      height: auto;
    }
  }
  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
  }
  &__btn-drawer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    cursor: pointer;
    svg {
      @include tr-ease();
      color: $color-white;
    }
    &:hover,&:active {
      svg {
        color: var(--new-grey-text, #939199);
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
  }


}

@media screen and (max-width: 1440px) {
  .header-sticky__left {
    gap: 24px;
  }
}

@media screen and (max-width: 1280px){
  .header-sticky {
    padding-left: 16px;
    padding-right: 16px;
  }
}


@media screen and (max-width: 1024px) {
  .header-sticky {
    padding: 12px 16px;
    &__btn-drawer {
      width: 36px;
      height: 36px;
    }
  }
}
