@import "styles/vars";

.inventory-expose-chart {
  &__chart{
    height: 130px;
    margin-bottom: 24px;
  }
  &__filter{
   display: none;
  }
}

@media screen and (max-width: 1024px) {
  .inventory-expose-chart {
    &__filter{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
    &__chart {
      height: 267px;
      margin-top: 24px;
      margin-bottom: 32px;
    }
  }
}
