@import "styles/vars";

.header-notice {
  border-radius: 12px;
  overflow: hidden;
  background: var(--new-bg-2, #19181C);
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 14px 16px;
  }
  &__img {
    display: block;
    width: 50px;
    height: auto;
    flex-shrink: 0;
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex-shrink: 0;
    width: 50px;
    padding: 11px;
    border-radius: 8px;
    background: $color-bg-0;
  }
  &__content {
    flex-grow: 1;
  }
  &__title {
    color: $color-white;
    @include fs--title-7-pc;
    span {
      color: $color-white;
    }
    a{
      display: inline-block;
      @include text-dots(1);
      transform: translateY(2px);
      max-width: 70px;
      overflow: hidden;
      text-decoration: underline;
      &:hover{
        color: $color-primary;
      }
    }
  }
  &__subtitle {
    text-transform: capitalize;
    margin-top: 6px;
    color: $color-grey-text;
    @include fs--headline-4-pc;
    @include text-dots(2);
  }
  &__btn-close {
    align-self: flex-start;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: -4px;
    cursor: pointer;
    @include tr-ease();
    color: var(--new-grey-text, #939199);
    &:hover, &:active {
      color: $color-white;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    color: var(--new-grey-text, #939199);
    @include fs--headline-6-pc;
    border-top: 1px solid var(--new-border, #2B292E);
    >span {
      display: inline-block;
      padding: 16px 0 17px;
    }
    &-timer{
      span{
        padding-bottom: 0;
        padding-right: 0;
        margin-left: 2px;
        margin-right: 2px;
        color: $color-white;
        @include fs--headline-1-m;
        font-variant-numeric: tabular-nums;
      }
      svg{
        transform: translateY(-2px);
      }
    }
  }
  &__price-grid {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    margin-top: 10px;
    @include fs--headline-5-pc;
    .header-notice__price {
      margin-top: 0;
      white-space: nowrap;
    }
  }
  &__price {
    display: inline-block;
    margin-top: 10px;
    @include fs--headline-5-pc;
  }
  &__percent {
    @include fs--headline-4-pc;
    white-space: nowrap;
  }
  &__btn {
    width: 85px;
    padding: 0 4px!important;
  }
  &__offer-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    margin-right: -8px;
  }
  &__offer-btn {
    padding: 0 4px!important;
  }
  &__bargain-btn {
    margin-right: -8px;
  }
  &__balance-out {
    color: var(--new-purple-3, #A66DFF);
  }
  &__balance-request-out {
    color: var(--new-yellow, #F2BA4D);
  }
  &__balance {
    @include fs--headline-4-pc;
  }
  &__support {
    margin-top: 6px;
    color: $color-grey-text;
    @include fs--body-3-pc;
    @include text-dots(2);
  }
  &__chat {
    margin-right: -8px;
  }
  &__user-product-purchased {
    color: var(--new-green, #62CC3D);
  }
  &__user-product-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    margin-right: -8px;
  }
  &__user-product-btn-help {
    color: $color-gray-2;
    cursor: pointer;
  }
}

@media screen and (max-width: 1024px) {
  .header-notice {
    &__title {
      @include fs--headline-4-pc;
    }
  }
}
