@import "src/styles/vars";

.tickets__card--status-value{
  margin-left: 8px;
  &--green{
    color:$color-green;
  }
  &--blue{
    color:$color-blue;
  }
  &--red{
    color:$color-red;
  }
  &--gray{
    color:$color-grey-text;
  }
}

