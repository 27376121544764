.ant-collapse {
  > .ant-collapse-item {

    &.ant-collapse-item-active {
      .ant-collapse-expand-icon {
        color: $color-white;
      }
    }
    > .ant-collapse-header {
      flex-direction: row-reverse;
      padding: 0;

      .ant-collapse-expand-icon {
        width: 18px;
        height: 18px;
        padding: 0;
        margin: 0 0 0 12px;
      }

      .ant-collapse-header-text {
        @include fs--headline-4-pc;
        color: $color-white;
        @include user-select-none();
      }
    }

    .ant-collapse-content {
      &.ant-collapse-content-active {
        border-bottom: 1px solid var(--new-border, #2B292E);
      }
      >.ant-collapse-content-box {
        padding: 20px 0;
      }
    }
  }

  > .ant-collapse-item + .ant-collapse-item {
    margin-top: 20px;
  }
}
.ant-collapse-large{
  border: transparent;
  >.ant-collapse-item{
    border: 1px solid transparent;
    transition: all $trans-time--fast ease-in-out;
    &-active{
      border: 1px solid $color-purple;
      border-radius: 8px;
      &:last-child{
        border-radius: 8px;
        >.ant-collapse-header{
          border-radius: 8px 8px 0 0;
        }
      }
      >.ant-collapse-header{
        background: $color-purple-2;
      }
    }
    border-bottom: transparent;
    >.ant-collapse-header{
      align-items: center;
      padding: 22px 20px;
      >.ant-collapse-expand-icon{
        width: 32px;
        height: 32px;
        color: $color-white;
        svg{
          width: 32px;
          height: 32px;
        }
      }
      >.ant-collapse-header-text{
        @include fs--title-4-pc;
      }
    }
    >.ant-collapse-content{
      background: $color-purple-2;
      border-top: none;
      >.ant-collapse-content-box{
        padding: 0 20px 16px 20px;
        @include fs--body-2-pc;
        color: $color-white;
        p,span,h3{
          margin: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .ant-collapse-large{
    >.ant-collapse-item{
      >.ant-collapse-header{
        padding: 16px 20px;
        .ant-collapse-header-text{
          @include fs--subtittle-2-pc;
        }
      }
      >.ant-collapse-content{
        >.ant-collapse-content-box{
          @include fs--body-3-pc;
        }
      }
    }
  }
}
