@import "styles/vars";

.empty-card {
  text-align: center;
  img{
    display: block;
    width: 250px;
    height: 200px;
    @include margin-center();
    object-fit: contain;
  }
  &--small{
    .empty-card__title {
      margin-top: 36px;
      @include fs--title-5-pc
    }
    .empty-card__text {
      margin-bottom: 16px;
      @include fs--subtittle-2-pc;
    }
  }
  &--empty{
    img{
      transform: translateX(13%);
    }
  }
  &--minimal{
    img{
      width: 150px;
      height: 150px;
    }
    .empty-card__title {
      margin-top: 24px;
      @include fs--title-5-pc
    }
    .empty-card__text {
      margin-bottom: 20px;
      @include fs--title-7-pc;
    }
  }
  &--error{
    img{
      max-width: 400px;
      width: 100%;
      height: auto;
    }
    .empty-card__title {
      margin-top: 20px;
    }
    .empty-card__text {
      max-width: 393px;
    }
  }
  &__title {
    margin-top: 72px;
    color: $color-white;
    @include fs--title-1-pc;

  }
  &__text {
    margin-top: 16px;
    margin-bottom: 32px;
    color: $color-grey-text;
    @include fs--subtittle-1-pc;
  }
}

@media screen and (max-width: 1024px){
  .empty-card {
    img {
      width: 96px;
      height: auto;

    }
    &__title {
      margin-top: 36px;
      @include fs--title-4-pc;

    }
    &__text {
      margin-top: 12px;
      margin-bottom: 24px;
      @include fs--body-3-pc;
    }
  }
}
