@import 'src/styles/vars';

.ant-drawer {
  &.ant-drawer-inline {
  }
  .ant-drawer-content-wrapper {
    box-shadow: 0px 25px 75px 0px rgba(0, 0, 0, 0.25);
  }
  .ant-drawer-content {
    background: var(--new-bg-2, #19181C);
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-title{
    @include fs--title-1-m;
    color: $color-white;
  }
}

