@import "styles/vars";

.scroll-to-top{
  position: fixed;
  right: 60px;
  bottom: 32px;
  border-radius: 50%;
  background: $color-purple;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  &.absolute{
    position: absolute;
    right: 0;
    bottom: 32px;
  }
  &.active{
    opacity: 1;
    visibility: visible;
  }
  &.product{
    right: calc(72px + 528px);
  }
  &.expose{
    right: calc(72px + 600px);
  }
  &:hover{
    background: $color-purple-3;
  }
  svg{
    width: 16px;
    height: 16px;
    color: white;
  }
}

@media screen and (max-width: 1760px){
  .scroll-to-top{
    right: 16px;
    bottom: 16px;
    &.expose{
      right: calc(28px + 600px);
    }
    &.product{
      right: calc(28px + 528px);
    }
  }
}

@media screen and (max-width: 1024px){
  .scroll-to-top{
    right: 16px;
    bottom: 16px;
    &.expose{
      right: 16px;
      bottom: 170px;
    }
    &.product{
      right: 16px;
      bottom: 170px;
    }
    &.empty{
      bottom: 16px;
    }
  }
}