.g2-tooltip {
  opacity: 1 !important;
  padding: 8px !important;
  border-radius: 4px !important;
  background: var(--new-bg-3, #1F1E21) !important;
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.25) !important;
  // custom
  &__content {
    color: var(--new-white, #FFF);
  }

  &__date {
    @include fs--headline-5-pc;
  }

  &__price {
    margin-top: 8px;
    @include fs--headline-7-pc;
  }

  &__count {
    margin-top: 4px;
    @include fs--headline-7-pc;
    color: var(--new-grey-text, #939199);

    span {
      color: var(--new-white, #FFF);
    }
  }
}

