@import "src/styles/vars";

.mobile-expose-modal {
  display: flex;
  flex-direction: column;
  //height: calc(100vh - 160px);
  padding-bottom: 160px;
  //height: 100vh;
  //&__wrapper {
  //  &.ant-modal {
  //    .ant-modal-content {
  //      max-height: inherit;
  //    }
  //  }
  //}
}
