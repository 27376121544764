@import "styles/vars";

.history {
  padding: 32px 0;
  min-height: calc(100vh - 202px);
}

@media screen and (max-width: 1760px) {
  .history {
    padding: 20px 0 36px;
  }
}
@media screen and (max-width: 1024px) {
  .history {
    padding: 20px 0 24px;
  }
}
