@import "styles/vars";

.history-head {
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px 24px;
    &--empty{
      align-items: flex-end;
      justify-content: space-between;
      .history-head__actions-wrap{
        width: auto;
      }
    }
  }

  &__title {
    width: 100%;
    color: $color-white;
    @include fs--title-1-pc;
  }

  &__filters{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    &-wrap{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}


@media screen and (max-width: 1024px) {
  .history-head__content {
    flex-direction: column;
  }
  .history-head__title {
    @include fs--title-4-pc;
  }

  .history-head__actions {
    width: 100%;
    gap: 12px 8px;
    @include scrollbar('horizontal', 8px);
    overflow: auto;
  }

  .history-head__tabs {
    width: 100%;
    order: 1;

    a {
      flex-grow: 1;
    }
  }

}

@media screen and (max-width: 1024px) {
  .history-head__content--empty{
    align-items: flex-start;
    justify-content: flex-start;
    .history-head__actions-wrap{
      width: 100%;
    }
  }
  .history-head__actions {
    padding-bottom: 8px;
    &-wrap{
      flex-direction: column;
    }
  }
  .history-head__filters{
    width: 100%;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 680px) {
  .history-head__filters{
    display: flex;
    flex-wrap: wrap;
  }
}
