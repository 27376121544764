@import "styles/vars";

$grid-repeat-param: minmax(200px, 1fr);
$grid-repeat-param-с: minmax(175px, 1fr);

.catalog {
  flex-grow: 1;
  padding: 32px 60px;

  &__grid {
    position: relative;
    display: flex;
    justify-content: flex-start;
    //gap: 20px;
    gap: 10px;
    min-height: 100%;
  }
  &__aside {
    position: sticky;
    top: 120px;
    //width: 308px;
    //height: 100%;
    width: 320px;
    padding-right: 10px;
    height: calc(100vh - 32px - 88px - 66px);
    flex-shrink: 0;
    overflow: auto;
    @include scrollbar();
    border-radius: 8px;
    transition: height 0.1s linear;
  }
  &__main {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
    //min-height: 20vh;
  }
  &__refetch-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    //flex-grow: 1;
    padding: 156px 0;
  }
  &__scroll-loader {
    padding: 36px 0;
  }
  &__main-grid {
    position: relative;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(auto-fill, $grid-repeat-param);
    margin-top: 20px;
    &--compact {
      grid-template-columns: repeat(auto-fill, $grid-repeat-param-с);
    }
  }

  &__empty{
    display: flex;
    align-items: center;
    justify-content: center;
    //flex-grow: 1;
    width: 100%;
    padding-top: 96px;
    &--card{
      padding-top: 60px;
      padding-bottom: 60px;
      max-width: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 1280px){
  .catalog {
    padding: 12px 16px 36px;
    &__aside {
      top: 100px
    }
  }
}

@media screen and (max-width: 1024px){
  $grid-repeat-param: minmax(170px, 1fr);
  $grid-repeat-param-с: minmax(150px, 1fr);
  .catalog{
    padding: 12px 16px 24px;
    &__main{
      margin-left: 0;
      width: 100%;
    }
    &__main-grid {
      margin-top: 12px;
      grid-template-columns: repeat(auto-fill, $grid-repeat-param);
      &--compact {
        grid-template-columns: repeat(auto-fill, $grid-repeat-param-с);
      }
    }
  }
}

@media screen and (max-width: 480px){
  $grid-repeat-param: minmax(150px, 1fr);
  $grid-repeat-param-с: minmax(150px, 1fr);
  .catalog{
    padding: 12px 16px 24px;
    &__main{
      margin-left: 0;
      width: 100%;
    }
    &__main-grid {
      margin-top: 12px;
      grid-template-columns: repeat(auto-fill, $grid-repeat-param);
      &--compact {
        grid-template-columns: repeat(auto-fill, $grid-repeat-param-с);
      }
    }
  }
}
