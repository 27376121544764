@import "styles/vars";

.currency-toggler {
  .ant-form-item {
    margin-bottom: 0;
  }

  &__dropdown {
    z-index: 125;
  }
}

@media screen and (max-width: 1024px) {
  .currency-toggler {
    border-radius: 8px;

    .ant-select-selector {
      border: 1px solid var(--new-border, #2B292E) !important;
    }
  }
}
