@import "styles/vars";

.product {
  flex-grow: 1;
  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    width: 100%;
    height: 100%;
  }
  &__grid {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: flex-start;
    gap: 36px;
  }

  &__aside {
    position: sticky;
    top: 88px;
    width: 265px;
    flex-shrink: 0;
    height: 100%;
  }

  &__main {
    position: relative;
    flex-grow: 1;
    max-width: calc(100% - 301px);
    border-left: 1px solid var(--new-border, #2B292E);
  }

  &__desc {
    padding: 24px 0 24px 36px;
    color: var(--new-grey-text, #939199);
    @include fs--headline-6-pc;
  }
}

@media screen and (max-width: 1280px) {
  .product {
    &__grid {
      gap: 16px;
    }
    &__aside {
      width: 220px;
    }
    &__main {
      max-width: calc(100% - 236px);
    }
    &__desc {
      padding: 24px 0 24px 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .product {
    &__grid {
      display: block;
    }
    &__aside {
      position: relative;
      top: auto;
      width: 100%;
    }
    &__main {
      max-width: 100%;
      padding-left: 0;
      border-left: none;
    }
    &__desc {
      padding: 24px 0;
    }
  }
}
