@import 'src/styles/vars';

//--- dropdown
.ant-dropdown {
  .ant-dropdown-menu {
    padding: 0;
    border-radius: 0px 0px 8px 8px;
    border-top: 1px solid var(--new-border, #2B292E);
    background: var(--new-bg-3, #1F1E21);
    box-shadow: 0px 25px 75px 0px rgba(0, 0, 0, 0.25);

    .ant-dropdown-menu-item {
      padding: 12px 16px;
      color: $color-grey-text;
      @include fs--headline-2-pc;
      @include tr-ease();

      &.ant-dropdown-menu-item-active {
        color: $color-white;
        background: $color-border;
      }

      &.ant-dropdown-menu-item-danger {
        &:not(.ant-dropdown-menu-item-disabled) {
          &:hover {
            color: $color-red;
            background: $color-red-0;
          }
        }
      }
    }
  }
}
