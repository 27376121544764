@import 'src/styles/vars';

.ant-avatar{
  border: none;
  &.ant-avatar-lg{
    width: 64px;
    height: 64px;
  }
  &--rounded{
    border-radius: 20px;
    background: $color-avatar-rounded;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      width: 40px;
      height: 40px;
    }
  }
}

@media screen and (max-width: 1024px){
  .ant-avatar{
    &.ant-avatar-lg{
      width: 24px;
      height: 24px;
    }
    &--rounded{
      border-radius: 8px;
      svg{
        width: 16px;
        height: 16px;
      }
    }
  }
}
