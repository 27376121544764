@import "src/styles/vars";

.install-extension-modal {
  padding: 24px;
  color: $color-white;
  text-align: center;
  &__icon{
    width: 51px;
    height: 51px;
    margin: 0 auto;
    padding: 8px;
    border-radius: 12px;
    color: var(--new-purple, #8D5DD9);
    border: 2px solid var(--new-purple, #8D5DD9);
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
  }

  &__title {
    margin-top: 24px;
    @include fs--title-4-pc
  }

  &__text {
    margin-top: 12px;
    @include fs--body-2-pc
  }

  &__btn-market{
    margin-top: 32px;

  }
}
