//---colors
//colors
$color-white: #ffffff;
$color-purple: #8D5DD9;
$color-purple-2: #1F1B26;
$color-purple-3: #A66DFF;
$color-purple-0: #2A2138;
$color-purple-01: #483663;
$color-purple-02: #714AAE;
$color-lily: #B5A3D1;
$color-border: #2B292E;
$color-avatar-rounded: #211F26;
$color-bg-3: #1F1E21;
$color-bg-2: #19181C;
$color-bg-1: #131215;
$color-bg-0: #232326;
$color-bg-01: #26232C;
$color-bg-02: #2F2C34;
$color-bg-03: #26222D;
$color-bg-04: #2E2E31;
$color-bg-05: #252428;
$color-grey-text: #939199;
$color-green: #62CC3D;
$color-blue: #4D99F2;
$color-green-03: #243b1b;
$color-green-0: #2E9972;
$color-green-01: #0c3d2b;
$color-green-02: #2c644f;
$color-green-03: #2BB45A;
$color-red: #E54545;
$color-red-0: #3C2A2A;
$color-red-01: #493737;
$color-yellow: #F2BA4D;
$color-yellow-0: #E5D126;
$color-gold: #FFCA62;
$color-gold-0: #D19B10;
$color-gray-2: #5D5C66;
$color-gray-0: #BEBDC2;
$color-gray-01: #A3A3A4;
$color-article-text: #EAE6F2;

$color-primary: $color-purple-3;
$color-secondary: $color-grey-text;
$color-accent: $color-white;

$color-error: $color-red;
$color-info: $color-purple-3;
$color-success: $color-green;
$color-warning: $color-yellow;

$color-float-1: #FAFD01;
$color-float-2: #FBD001;
$color-float-3: #FC8E01;
$color-float-4: #FD6E01;
$color-float-5: #FE5301;
$color-float-0: #3B3C4D;

$color-glow-csgo-gray: #BBB9C6;
$color-glow-csgo-cyan: #6B97D4;
$color-glow-csgo-blue: #4D68CB;
$color-glow-csgo-purple: #9E76F7;
$color-glow-csgo-pink: #CC59E5;
$color-glow-csgo-red: #EB6F6F;
$color-glow-csgo-yellow: #F7C176;

$color-glow-dota-gray: #B0C3D9;
$color-glow-dota-cyan: #5E98D9;
$color-glow-dota-blue: #4B69FF;
$color-glow-dota-purple: #8847FF;
$color-glow-dota-pink: #D32CE6;
$color-glow-dota-red: #EB4B4B;
$color-glow-dota-yellow: #E4AE33;
$color-glow-dota-green: #ADE55C;
$color-glow-dota-light: #FFF34F;
//$color-overlay: rgba($color-bg, .75);

$box-shadow-1: 0px 25px 100px 0px rgba(0, 0, 0, 0.10);
$gradient-gray: linear-gradient(270deg, #19181C 0%, rgba(25, 24, 28, 0.00) 100%);
//---TRANSITION
$trans-time: .4s;
$trans-time--fast: .3s;
$trans-time--slow: .6s;

//---FONTS-FAMILY
$f-family: 'Inter', apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

//---MEDIA
//--- 1920 | 1760 | 1680 | 1440 | 1366 | 1280 | 1140 | 1024 | 768 | 640 | 480 | 375

@mixin max_1920 {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin max_1760 {
  @media (max-width: 1760px) {
    @content;
  }
}

@mixin max_1680 {
  @media (max-width: 1680px) {
    @content;
  }
}
@mixin max_1440 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin max_1366 {
  @media (max-width: 1366px) {
    @content;
  }
}

@mixin max_1280 {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin max_1140 {
  @media (max-width: 1140px) {
    @content;
  }
}

@mixin max_1024 {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin max_768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin max_640 {
  @media (max-width: 640px) {
    @content;
  }
}

@mixin max_480 {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin max_375 {
  @media (max-width: 375px) {
    @content;
  }
}

// MIN MEDIA
@mixin min_1920 {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin min_1760 {
  @media (min-width: 1780px) {
    @content;
  }
}


@mixin min_1680 {
  @media (min-width: 1680px) {
    @content;
  }
}


@mixin min_1440 {
  @media (min-width: 1440px) {
    @content;
  }
}

@mixin min_1366 {
  @media (min-width: 1366px) {
    @content;
  }
}

@mixin min_1280 {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin min_1140 {
  @media (min-width: 1140px) {
    @content;
  }
}

@mixin min_1024 {
  @media (min-width: 1025px) {
    @content;
  }
}


@mixin min_768 {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin min_640 {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin min_480 {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin min_375 {
  @media (min-width: 375px) {
    @content;
  }
}

@mixin margin-center() {
  margin-left: auto;
  margin-right: auto
}

@mixin list-reset-style() {
  list-style: none !important;
  margin: 0;
  padding-left: 0 !important;
  color: lightgray;
}

@mixin text-dots($line-clamp: 1) {
  @if $line-clamp == 1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}


// SELECTION
@mixin selected-text() {
  *::selection {
    //color: $color-white;
    //background: $color-primary--115;
  }
}

@mixin user-select-none() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  user-select: none;
}

// SCROLLBAR
@mixin scrollbar($type: 'vertical',  $customSize: 4px ) {
  $size: $customSize;

  &::-webkit-scrollbar {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-track {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    background: transparent;
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-thumb {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    background: $color-purple-2;
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-thumb:hover {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    background: $color-bg-03;
    cursor: pointer;
  }
}

@mixin scrollbar-modal($type: 'vertical') {
  $size: 4px;

  &::-webkit-scrollbar {
    @if ($type == 'vertical') {
      width: $size;
    } @else {
      height: $size;
    }
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-thumb {
    background: $color-bg-01;
    border-radius: calc($size/2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $color-bg-02;
    cursor: pointer;
  }
}

// TRANSITION
@mixin tr-cb($time: .2s) {
  transition: all $time cubic-bezier(0.645, 0.045, 0.355, 1);
}

@mixin tr-ease($time: .2s) {
  transition: all $time ease
}

// TYPOGRAPHY
// Title
@mixin fs--article-title-pc() {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
}

@mixin fs--article-desc-pc() {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}

@mixin fs--article-title-m() {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

@mixin fs--article-desc-m() {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%;
}

@mixin fs--title-h1-pc() {
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 48px;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
}

@mixin fs--title-h1-m() {
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
}

@mixin fs--title-h2-pc() {
  font-size: 48px;
  font-weight: 600;
  line-height: 100%; /* 48px */
}

@mixin fs--title-1-pc() {
  font-size: 28px;
  font-weight: 600;
  line-height: 140%; /* 39.2px */
}

@mixin fs--title-1-m() {
  font-size: 20px;
  font-weight: 500;
  line-height: 100%; /* 20px */
}

@mixin fs--title-2-pc() {
  font-size: 28px;
  font-weight: 500;
  line-height: 135%; /* 37.8px */
}

@mixin fs--title-2-m() {
  font-size: 18px;
  font-weight: 500;
  line-height: 100%; /* 18px */
}

@mixin fs--title-3-pc() {
  font-size: 24px;
  font-weight: 500;
  line-height: 100%; /* 24px */
}

@mixin fs--title-3-m() {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%; /* 16px */
}

@mixin fs--title-4-pc() {
  font-size: 20px;
  font-weight: 500;
  line-height: 100%; /* 20px */
}

@mixin fs--title-4-m() {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

@mixin fs--title-5-pc() {
  font-size: 20px;
  font-weight: 400;
  line-height: 100%; /* 20px */
}

@mixin fs--title-6-pc() {
  font-size: 18px;
  font-weight: 500;
  line-height: 100%; /* 18px */
}

@mixin fs--title-7-pc() {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

// Body
@mixin fs--body-1-m() {
  font-size: 14px;
  font-weight: 300;
  line-height: 150%; /* 21px */
}

@mixin fs--body-2-pc() {
  font-size: 16px;
  font-weight: 300;
  line-height: 150%; /* 24px */
}

@mixin fs--body-2-m() {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

@mixin fs--body-3-pc() {
  font-size: 14px;
  font-weight: 300;
  line-height: 150%; /* 21px */
}

@mixin fs--body-3-m() {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

@mixin fs--body-4-pc() {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

// Headline
@mixin fs--headline-1-pc() {
  font-size: 16px;
  font-weight: 300;
  line-height: 100%; /* 16px */
}

@mixin fs--headline-1-m() {
  font-size: 14px;
  font-weight: 400;
  line-height: 100%; /* 14px */
}

@mixin fs--headline-2-pc() {
  font-size: 16px;
  font-weight: 400;
  line-height: 100%; /* 16px */
}

@mixin fs--headline-2-m() {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%; /* 14px */
}


@mixin fs--headline-3-pc() {
  font-size: 16px;
  font-weight: 500;
  line-height: 100%; /* 16px */
}

@mixin fs--headline-3-m() {
  font-size: 12px;
  font-weight: 400;
  line-height: 100%; /* 12px */
}

@mixin fs--headline-4-pc() {
  font-size: 14px;
  font-weight: 400;
  line-height: 120%; /* 16px */
}

@mixin fs--headline-4-m() {
  font-size: 12px;
  font-weight: 500;
  line-height: 100%; /* 12px */
}

@mixin fs--headline-5-pc() {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%; /* 14px */
}

@mixin fs--headline-6-pc() {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%; /* 12px */
}

@mixin fs--headline-7-pc() {
  font-size: 12px;
  font-weight: 500;
  line-height: 100%; /* 12px */
}

@mixin fs--headline-8-pc() {
  font-size: 10px;
  font-weight: 500;
  line-height: 100%; /* 10px */
}

@mixin fs--headline-9-pc() {
  font-size: 10px;
  font-weight: 400;
  line-height: 100%; /* 10px */
}

@mixin fs--headline-10-pc() {
  font-size: 10px;
  font-weight: 400;
  line-height: 100%; /* 10px */
}

// Subtitle
@mixin fs--subtittle-1-pc () {
  font-size: 18px;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
}

@mixin fs--subtittle-2-pc () {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

// general gap sizes (1 step = 4px)
// 4 / 8 / [12] / 16 / 24 / 32 / 40 / 48 / 56 / 66 / 72 /

// 80 / 88 / 96 / 104 / 112 / 120 / 128 / 136 / 144 / 152 /
// 160 / 168 / 176 / 184 / 192 / 200 /
