@import "styles/vars";

.multiple-filter {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-white;
    @include fs--headline-4-pc;
  }
  &__group {
    flex-direction: column;
  }
  &__item {
    & + & {
      margin-top: 16px;
    }
  }
}
