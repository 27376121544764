@import "styles/vars";

.catalog-aside {
  //height: 100%;
  //display: flex;
  //flex-direction: column;
  &__price {
    margin-bottom: 12px;
  }
  &__card {
    //flex-grow: 1;
    //display: flex;
    //flex-direction: column;
    padding: 20px 20px 0;
    border-radius: 8px;
    background: var(--new-bg-2, #19181C);
    .ant-collapse-expand-icon {

    }
  }
  &__checkbox {
    margin-top: 20px;
  }
  &__actions {
    position: sticky;
    z-index: 1;
    bottom: 0;
    //bottom: 32px;
    //flex-grow: 1;
    //display: flex;
    //flex-direction: column;
    //justify-content: flex-end;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background: var(--new-bg-2, #19181C);
  }
  &__btn-reset {
    margin-top: 12px;
  }
}

@media screen and (max-width: 1024px){
  .catalog-aside {
    &__card {
      padding: 20px 16px 0;
    }
    &__actions {
      bottom: -20px;
    }
  }
}
