@import "styles/vars";

.inventory-mobile-nav {
  text-align: left;
  &__summary{
    color: var(--new-white, #FFF);
    span {
      @include fs--title-6-pc;
    }
  }
  &__note{
    margin-top: 12px;
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;
  }
  &__actions{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin-top: 24px;
    .ant-btn {
      width: 50%;
      flex-grow: 1;
      flex-shrink: 0;
      max-width: 480px;
    }
  }
}
