@import "styles/vars";

.inventory-trade {
  width: 100%;
  margin: 0 auto;
  max-width: 570px;
  color: $color-white;
  text-align: center;

  &__icon {
    width: 48px;
    height: 48px;
    margin: 0 auto;
    padding: 8px;
    border-radius: 12px;
    border: 2px solid var(--new-purple, #8D5DD9);
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.08);
  }

  &__title {
    margin-top: 24px;
    @include fs--title-4-pc
  }

  &__text {
    margin-top: 12px;
    @include fs--body-2-pc
  }

  &__input {
    margin-top: 32px;
  }

  &__input-link {
    color: var(--new-purple, #8D5DD9) !important;
  }

  &__action {
    margin-top: 32px;
  }

  &__note {
    display: inline-block;
    margin-top: 20px;
    @include fs--headline-4-pc;
  }
}

@media screen and (max-width: 768px) {
  .inventory-trade {
    &__icon {
      width: 36px;
      height: 36px;
      padding: 6px;
      border-radius: 10.286px;
      border: 1.5px solid var(--new-purple, #8D5DD9);
      box-shadow: 0 15.428571701049805px 30.85714340209961px 0 rgba(0, 0, 0, 0.08);
      svg {
        width: 24px;
        height: auto;
      }
    }

    &__title {
      margin-top: 16px;
      @include fs--headline-3-pc
    }

    &__text {
      margin-top: 8px;
      @include fs--body-4-pc
    }

    &__input {
      margin-top: 16px;
    }

    &__action {
      margin-top: 0
    }

    &__btn-save {
      max-width: 480px;
    }

    &__note {
      margin-top: 96px;
    }
  }
}
