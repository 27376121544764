@import "../../../../styles/vars";

.sticker-tooltip {
  &__title {
    color: $color-white;
    @include fs--title-6-pc;
  }
  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;
    span {
      color: $color-white;
    }
  }
  &--small {
    .sticker-tooltip__title {
      @include fs--headline-4-pc;
    }
    .sticker-tooltip__grid {
      margin-top: 8px;
      @include fs--headline-6-pc;
    }
  }
}


@media screen and (max-width: 1024px) {
  .sticker-tooltip {
    &__title {
      color: $color-white;
      @include fs--body-3-pc;
    }
    &__grid {
      @include fs--headline-6-pc;
    }
    &--small {
      .sticker-tooltip__title {
        @include fs--headline-4-pc;
      }
      .sticker-tooltip__grid {
        margin-top: 8px;
        @include fs--headline-6-pc;
      }
    }
  }
}
