.ant-upload-wrapper.ant-upload--input{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  .ant-upload-list-item.ant-upload-list-item-undefined{
    margin-top: 0;
  }
  .ant-upload-list .ant-upload-list-item:hover{
    background-color: transparent;
  }
}