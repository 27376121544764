@import "src/styles/vars";

.sticker-filter-modal {
  &__wrapper {
    .ant-modal-close {
      top: 30px;
      right: 30px;
    }
  }

  &__grid {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 8px;
  }

  &__aside {
    flex-shrink: 0;
    min-width: 287px;
    min-height: 450px;
    padding-right: 28px;
    border-right: 1px solid $color-border;
  }

  &__packs {
    margin: 20px -20px 20px 0;
  }

  &__packs-list {
    list-style: none !important;
    margin: 0 -6px 0 0;
    padding-left: 0 !important;
  }

  &__packs-item {
    padding: 12px 16px;
    color: var(--new-white, #FFF);
    @include fs--headline-2-pc;
    border-radius: 8px;
    cursor: pointer;
    @include tr-ease();

    & + & {
      margin-top: 8px;
    }

    &:hover, &:active {
      background: var(--new-purple-2, #1F1B26);
    }

    &--active {
      color: var(--new-purple-3, #A66DFF);
      background: var(--new-purple-2, #1F1B26);
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-left: 28px;
  }

  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-3-pc();
  }

  &__list {
    width: 100%;
    margin-top: 20px;

    &--scroll {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        right: 8px;
        bottom: 0;
        height: 100px;
        background: linear-gradient(0deg, #19181C 0%, rgba(25, 24, 28, 0.00) 100%);
        pointer-events: none;
      }
    }
  }

  &__packs-scroll,
  &__scroll {
    max-height: 70vh;
    padding-right: 20px;
    @include scrollbar-modal();
    overflow: auto;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 28px 32px;
    background: var(--new-bg-3, #1F1E21);
  }

  &__current {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 4px;
    list-style: none !important;
    margin: 0;
    padding-left: 285px;
  }

  &__current-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      display: block;
      width: 32px;
      aspect-ratio: 1.14;
      object-fit: cover;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }

  &__btn-confirm {
    width: 185px;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 48px;
  }

}

@media screen and (max-width: 1024px) {
  .sticker-filter-modal {
    height: 100%;
    &__wrapper {
      height: 100vh;

      .ant-modal-content,
      .ant-modal-body {
        height: 100%;
      }

      .ant-modal-close {
        top: 20px;
        right: 16px;
      }
    }

    &__grid {
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px 0 0;
      height: calc(100% - 138px);
    }

    &__aside {
      width: 100%;
      padding-right: 0;
      min-height: inherit;
      min-width: inherit;
      border-right: none;
      border-bottom: 1px solid $color-border;
    }

    &__title {
      padding: 0 16px 16px;
      @include fs--title-4-pc();
    }

    &__search {
      width: calc(100% - 32px);
      margin: 0 16px;
    }

    &__packs {
      margin: 20px 0;
    }

    &__packs-scroll {
      max-height: inherit;
      padding-right: 0;
      @include scrollbar('horizontal', 4px);

      &::-webkit-scrollbar-thumb {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }
    }

    &__packs-list {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      gap: 8px;
      width: 100%;
      margin: 0;
    }

    &__packs-item {
      padding: 12px 8px;
      @include fs--headline-1-m;
      white-space: nowrap;

      &:first-child {
        margin-left: 16px;
      }

      & + & {
        margin-top: 0;
      }
    }

    &__main {
      display: block;
      width: 100%;
      height: calc(100% - 164px);
      padding-left: 0;
    }

    &__list {
      margin-top: 0;
      height: 100%;
      padding-right: 6px;
      &--scroll {
        position: relative;

        &:after {
          display: none;
        }
      }
    }

    &__scroll {
      padding-right: 6px;
      height: 100%;
      @include scrollbar('vertical', 4px);
    }

    &__footer {
      flex-direction: column;
      //align-items: center;
      //justify-content: space-between;
      padding: 16px 8px;
    }

    &__current {
      gap: 12px;
      padding-left: 0;

      li {
        > svg {
          width: 50px;
          height: auto;
        }
      }
    }

    &__current-img {
      width: 50px;
      height: 50px;

      img {
        width: 27px;
      }
    }

    &__actions {
      justify-content: center;
      gap: 8px;
      max-width: 640px;
      width: 100%;
    }

    &__btn-confirm {
      flex-grow: 1;
    }

    &__empty {
      margin-top: 36px;
    }

  }
}
