@import "src/styles/vars";

.tickets-create{
  width: 100%;
  &__wrap{
    width: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
  }
  &__head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 72px;
    &--back, &--action{
      width: 25%;
    }
    &--action{
      text-align: right;
    }
    &--title{
      @include fs--headline-3-pc;
      color: $color-white;
    }
  }
  &__form{
    max-width: 700px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &--actions{
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &--title{
      @include fs--title-3-pc;
      margin-bottom: 16px;
      color: $color-white;
    }
    &--desc{
      @include fs--body-2-pc;
      color: $color-grey-text;
      margin-bottom: 32px;
    }
  }
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item:hover{
    background-color: transparent;
  }
  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item{
    border: 1px solid $color-border;
    transition: border $trans-time ease-in-out;
    &:hover{
      border: 1px solid $color-gray-2;
    }
  }
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name{
    color: $color-white;
  }
  .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions.picture .ant-upload-list-item-action{
    svg{
      color: $color-red;
    }
  }
  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail{
    width: 40px;
    height: 30px;
    border-radius: 4px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1024px){
  .tickets-create{
    &__wrap{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &__head{
      margin-bottom: 32px;
    }
    &__form{
      &--title{
        @include fs--title-1-m;
        margin-bottom: 8px;
        text-align: center;
      }
      &--desc{
        @include fs--body-3-m;
        text-align: center;
      }
      &--actions{
        .ant-form-item:last-child{
          width: calc(100% - 60px);
          button{
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px){
  .tickets-create{
    &__head{
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      &--back{
        margin-bottom: 20px;
      }
      &--title{
        margin-bottom: 12px;
        @include fs--title-3-m;
      }
      &--action{
        text-align: center;
        width: 100%;
        button{
          width: 100%;
        }
      }
    }
  }
}
