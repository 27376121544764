@import "styles/vars";

.mobile-bottom-nav {
  position: fixed;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 16px;
  background: var(--new-bg-2, #19181C);
  box-shadow: 0px 10px 65px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
}

