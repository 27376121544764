.ant-checkbox {
  .ant-checkbox-input:focus-visible+.ant-checkbox-inner {
    outline: 0;
  }
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid var(--grey-text, #939199);
    background-color: transparent;

    &:after {
      top: 8px;
      inset-inline-start: 5px;
      width: 5px;
      height: 9px;
      border: 2px solid $color-white;
      border-top: 0;
      border-inline-start: 0;
      border-radius: 1px;
    }
  }

  & + span {
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;
    line-height: 150%
  }

  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      border: 1px solid var(--new-purple, #8D5DD9);
      background: var(--new-purple, #8D5DD9);
    }
    & + span {
      color: $color-white;
    }
  }

}

.ant-checkbox-wrapper {
  &:not(.ant-checkbox-wrapper-disabled) {
    &:hover {
      .ant-checkbox-checked {
        &:not(.ant-checkbox-disabled) {
          .ant-checkbox-inner {
            border: 1px solid var(--new-purple, #8D5DD9);
            background: var(--new-purple, #8D5DD9);
          }
          &:after {
            border: 1px solid var(--new-purple, #8D5DD9);
          }
        }
      }
    }
  }
}

.ant-checkbox {
  align-self: flex-start;
  margin-top: 1px;
}

@media screen and (max-width: 1024px){
  .ant-checkbox {
    & + span {
      @include fs--headline-1-m
    }
  }
}
