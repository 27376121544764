@import "styles/vars";

$grid-repeat-param: minmax(185px, 1fr);
$grid-repeat-param-с: minmax(170px, 1fr);
.inventory-scrollable {
  height: 100%;
  @include scrollbar('vertical', 4px);
  margin-right: -6px;
  padding-right: 6px;
  overflow: auto;

  .infinite-scroll-component {
    overflow: auto !important;
  }

  &__grid {
    position: relative;
    display: grid;
    grid-gap: 8px;
    flex-grow: 1;
    min-height: 20vh;
    grid-template-columns: repeat(auto-fill, $grid-repeat-param);
    z-index: 1;

    &--compact {
      grid-template-columns: repeat(auto-fill, $grid-repeat-param-с);
    }
  }
}

@media screen and (max-width: 1024px) {
  $grid-repeat-param: minmax(170px, 1fr);
  $grid-repeat-param-с: minmax(150px, 1fr);
  .inventory-scrollable {
    overflow: inherit;
    margin-right: 0;
    padding-right: 0;
    &__grid {
      grid-template-columns: repeat(auto-fill, $grid-repeat-param);
      &--compact {
        grid-template-columns: repeat(auto-fill, $grid-repeat-param-с);
      }
    }
  }
}

@media screen and (max-width: 480px) {
  $grid-repeat-param: minmax(150px, 1fr);
  $grid-repeat-param-с: minmax(150px, 1fr);
  .inventory-scrollable {
    &__grid {
      grid-template-columns: repeat(auto-fill, $grid-repeat-param);
      &--compact {
        grid-template-columns: repeat(auto-fill, $grid-repeat-param-с);
      }
    }
  }
}
