@import "styles/vars";

.trade-modal-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 32px;
  gap: 24px;

  &--extended {

  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
  }

  &__right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 16px;
  }

  &__img {
    flex-shrink: 0;

    img {
      display: block;
      width: 93px;
      height: 81px;
      object-fit: contain;
    }
  }

  &__info {
    width: 185px;
    color: var(--new-white, #FFF);
    @include fs--headline-4-pc;
  }

  &__title {
    color: $color-yellow-0;
  }

  &__label {
    margin-top: 8px;
  }

  &__stickers {
    margin-top: 12px;
  }

  &__sticker {
    width: 28px!important;
  }

  &__float {
    margin-top: 12px;
  }

  &__float-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-bottom: 7px;
    color: var(--new-grey-text, #939199);

    span {
      color: var(--new-white, #FFF);
    }
  }

  &__prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-top: 29px;
    text-align: center;
  }

  &__price {
    color: var(--new-grey-text, #939199);
    @include fs--headline-1-pc;
    white-space: nowrap;

    span {
      @include fs--headline-2-pc;
      display: block;
      margin-top: 12px;
      color: var(--new-white, #FFF);

      svg {
        width: 16px;
        height: auto;
      }
    }
  }

  &__divider {
    margin-top: 29px;
    height: 44px;
    border-left: 1px solid var(--New-Border, #2B292E);
  }

  &__form {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
    width: auto;

    label {
      display: block;
      margin-bottom: 12px;
      color: var(--new-white, #FFF);
      @include fs--headline-4-pc;
    }
  }

  &__input {
    width: 185px;

    &--small {
      width: auto;
      .ant-form-item-explain-error{
        white-space: nowrap;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .ant-form-item-has-error + .trade-modal-card__input-note {
      margin-top: 4px;
    }
  }


  &__input-note {
    margin-top: 12px;
    min-height: 12px;
    color: var(--new-gray-2, #5D5C66);
    @include fs--headline-6-pc;
  }

  &__additional {

  }

  &__btn-auto {
    margin-top: 32px;
    height: 36px;
    padding: 8px 12px;
  }

  &__counter {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }

  &__btn-count {
    &.ant-btn {
      width: 44px;
      height: 44px;
    }
  }

  &__input-count {
    width: 60px;
  }

}

@media screen and (max-width: 1280px) {

  .trade-modal-card {
    &--extended {
      .trade-modal-card__input {
        width: 185px;

        &--small {
          width: auto;
        }
      }
    }
  }


  .trade-modal-card__left {
    //flex-grow: 1;
    //justify-content: space-between;
  }

  .trade-modal-card__info {
    width: 250px;
  }

  .trade-modal-card__prices {
    margin-top: 12px;
  }

  .trade-modal-card__input {
    width: 250px;
  }

  .trade-modal-card__divider {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .trade-modal-card {
    align-items: flex-start;
    gap: 24px;
    margin-top: 16px;
    padding: 20px 0;
    border-top: 1px solid var(--new-border, #2B292E);

    &--extended {
      .trade-modal-card__input {
        max-width: 100%;
        width: 100%;

        &--small {
          width: 100%;
        }
      }
    }

    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      width: 45%;
      max-width: 360px;
      //flex-grow: 1;
      //flex-shrink: 0;
    }

    &__img {
      flex-shrink: 0;

      img {
        width: 92px;
        height: 67px;
      }
    }

    &__info {
      @include fs--headline-6-pc;
    }

    &__price {
      @include fs--headline-4-pc;

      span {
        @include fs--headline-4-pc;
      }
    }

    &__form {
      flex-direction: column;
      flex-grow: 1;
      max-width: 480px;
      width: 100%;

      label {
        margin-bottom: 8px;
        @include fs--headline-1-m;
      }
    }

    &__input {
      max-width: 100%;
      width: 100%;
    }

    &__input-note {
      margin-top: 8px;

      @include fs--headline-3-m;
    }

    &__additional {
      width: 100%;
    }

    &__btn-auto {
      margin-top: 0;
      width: 100%;
    }

    &__input-count {
      width: auto;
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 768px) {
  .trade-modal-card {
    flex-direction: column;
    &--extended {
      .trade-modal-card__input {
        max-width: 100%;
        width: 100%;

        &--small {
          width: 100%;
        }
      }
    }
    &__left {
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      max-width: 100%;
      gap: 16px;
    }

    &__right {
      flex-direction: column;
      width: 100%;
    }


    &__info {
      min-width: 165px;
    }

    &__prices {
      justify-content: space-evenly;
      width: 100%;
      margin-top: 24px;
    }

    &__form {
      max-width: 100%;
    }
    &__counter {
      .ant-form-item {
        flex-grow: 1;
      }

    }
    &__input-count {
      display: flex;
    }
  }
}
