.ant-form{
  width: 100%;
}
.ant-form-item .ant-form-item-label >label{
  @include fs--headline-4-pc;
  color: $color-white;
}

.ant-form-item.ant-form-item--flat{
  margin-right: 0;
  margin-bottom: 0;
}

.ant-form-item-explain {
  padding-top: 4px;
}

.ant-form-item .ant-form-item-explain-error {
  @include fs--headline-6-pc()
}
