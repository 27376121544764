@import "src/styles/vars";

.home-p2p{
  width: 100%;
  &__wrap{
    padding-top: 120px;
    padding-bottom: 120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    @include fs--title-1-pc;
    color: $color-white;
    text-align: center;
    margin-bottom: 12px;
    span{
      @include fs--title-2-pc;
      color: $color-grey-text;
    }
  }
  &__desc{
    text-align: center;
    color: $color-grey-text;
    @include fs--headline-2-pc;
  }
  &__item{
    &-wrapper{
      margin-top: 56px;
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 20px;
    }
    position: relative;
    width: 100%;
    display: flex;
    align-self: stretch;
    padding: 51px 32px 51px 185px;
    border-radius: 24px;
    border: 1px solid $color-border;
    &__image{
      position: absolute;
      width: 170px;
      height: 200px;
      left: 10px;
      bottom: 20px;
      &--left{
        left: -10px;
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &__content{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      &-title{
        color: $color-white;
        margin-bottom: 12px;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 0.22px;
      }
      &-desc{
        @include fs--subtittle-2-pc;
        color: $color-white;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .home-p2p{
    &__wrap{
      padding-top: 30px;
      padding-bottom: 20px;
    }
    &__title{
      max-width: 200px;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      margin-bottom: 20px;
      span{
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
      }
    }
    &__desc{
      display: none;
    }
    &__item{
      &__image{
        width: 100px;
        height: 130px;
        left: 3px;
        &--left{
          left: -10px;
        }
      }
      &-wrapper{
        margin-top: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      &__content{
        &-title{
          @include fs--title-3-m;
        }
        &-desc{
          @include fs--body-3-m;
        }
      }
      padding: 20px 20px 20px 100px;
    }
  }
}

@media screen and (max-width: 500px){
  .home-p2p__item{
    border-radius: 12px;
    &__image{
      bottom: 7px;
    }
  }
}