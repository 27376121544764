.ant-space-compact {
  .ant-form-item{
    margin-bottom: 0;
    width: 100%;
  }
  &--combined {
    background: $color-bg-3;
    border-radius: 8px;

    .ant-select.ant-select-single.ant-select--light:not(.ant-select-customize-input) .ant-select-selector {
      background: $color-bg-2;
      border-radius: 0 8px 8px 0;
    }

    .ant-btn-compact-item + .ant-btn-compact-item {
      border-left: 1px solid $color-border;
    }

    .ant-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      line-height: 2;
    }
  }

  &--prepend {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $color-white;
    min-width: 82px;
  }

  &--nav {
    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 56px;
      padding: 4px 16px;
      background: var(--new-bg-2, #19181C);
      border-color: var(--new-bg-2, #19181C);
      color: var(--new-grey-text, #939199);
      @include fs--title-7-pc;
      @include tr-cb;

      &:hover, &:active {
        color: $color-white;
        background: var(--new-bg-3, #1F1E21);
      }

      &:first-child {
        border-start-start-radius: 8px;
        border-end-start-radius: 8px;
      }

      &:last-child {
        border-start-end-radius: 8px;
        border-end-end-radius: 8px;
      }

      &.ant-space-compact-nav--active {
        color: $color-white;
        background: var(--new-purple-3, #A66DFF);
        pointer-events: none;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .ant-space-compact {
    &--prepend {
      padding: 8px 12px;
      @include fs--headline-4-pc;
    }

    &--nav {
      a {
        height: 40px;
        padding: 4px 16px;

        @include fs--headline-6-pc;
      }
    }
  }
}
