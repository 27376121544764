@import "src/styles/vars";

.home-security{
  width: 100%;
  &__wrap{
    width: 100%;
    padding-top: 110px;
    padding-bottom: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__image{
    max-width: 1012px;
    width: 100%;
    height: auto;
    img{
      mix-blend-mode: lighten;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }
  &__content{
    margin-top: 50px;
    width: calc(100% - 1076px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    &-icon{
      width: 135px;
      height: 140px;
      margin-bottom: 40px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-title{
      color: $color-white;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 125%;
      letter-spacing: 0.4px;
      margin-bottom: 16px;
    }
    &-desc{
      @include fs--title-5-pc;
      line-height: 150%;
      color: $color-white;
    }
  }
}

@media screen and (max-width: 1680px){
  .home-security{
    &__image{
      max-width: 780px;
    }
    &__content{
      width: calc(100% - 844px);
    }
  }
}

@media screen and (max-width: 1360px){
  .home-security{
    &__image{
      max-width: 550px;
    }
    &__content{
      width: calc(100% - 600px);
    }

  }
}
@media screen and (max-width: 1024px){
  .home-security{
    .app-container{
      padding-left: 0;
      padding-right: 0;
    }
    &__wrap{
      padding-bottom: 20px;
      padding-top: 20px;
      flex-direction: column-reverse;
    }
    &__content{
      width: 100%;
      margin-top: 0;
      padding-left: 16px;
      padding-right: 16px;
      max-width: 300px;
      &-icon{
        width: 72px;
        height: 72px;
        margin-bottom: 20px;
      }
      &-title{
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 125%;
        margin-bottom: 12px;
      }
      &-desc{
        @include fs--body-1-m;
        margin-bottom: 20px;
      }
    }
    &__image{
      max-width: none;
    }
  }
}