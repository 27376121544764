@import "src/styles/vars";

.price-modal {
  &__content {
    padding: 32px 32px 28px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
  }

  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-3-pc;
  }

  &__chart {
    height: 267px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .price-modal {
    &__content {
      padding: 20px 16px 16px;
    }

    &__title {
      @include fs--title-4-pc;
    }
  }
}

@media screen and (max-width: 768px) {
  .price-modal {
    &__head {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
