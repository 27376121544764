@import "styles/vars";

.lang-toggler {
  &__option-flag {
    position: relative;
    top: -2px;
    display: inline-block;
    height: 16px;
    margin-right: 8px;
  }

  &__dropdown {
    z-index: 125;
  }
}

@media screen and (max-width: 1024px) {
  .lang-toggler {
    border-radius: 8px;

    .ant-select-selector {
      border: 1px solid var(--new-border, #2B292E) !important;
    }
  }
  .lang-toggler__option-flag{
    display: none;
  }
}
