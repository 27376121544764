@import "styles/vars";

.deals {
  padding: 32px 0;
}

@media screen and (max-width: 1760px) {
  .deals {
    padding: 20px 0 36px;
  }
}
@media screen and (max-width: 1024px) {
  .deals {
    padding: 20px 0 24px;
  }
}
