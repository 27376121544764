@import 'src/styles/vars';

.ant-modal {
  .ant-modal-content {
    padding: 0;
    border-radius: 16px;
    background: var(--new-bg-2, #19181C);
    box-shadow: 0px 10px 65px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
  .ant-modal-close {
    top: 16px;
    right: 16px;
    inset-inline-end: 15px;
    width: 32px;
    height: 32px;
    color: var(--new-grey-text, #939199);
    &:hover{
      color: $color-white;
      background: transparent;
    }
  }
  .ant-modal-close-x {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &--support{
    top: auto!important;
    bottom: 36px;
    max-width: 100%;
    vertical-align: bottom!important;
    &--closable{
      .ant-modal-close {
        top: 32px;
      }
    }
  }
  &--mobile {
    top: auto!important;
    padding-bottom: 0;
    bottom: 0;
    left: 0;
    width: 100%!important;
    max-width: 100%;
    vertical-align: bottom!important;
    transform-origin: bottom center!important;
    .ant-modal-content {
      border-radius: 0px;
      background: var(--new-bg-1, #131215);
    }
    .ant-modal-close {
      top: 12px;
      inset-inline-end: 11px;
      width: 32px;
      height: 32px;
    }
  }

}
