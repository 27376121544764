@import "src/styles/vars";

.home-faq{
  width: 100%;
  &__wrap{
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__content{
    max-width: 345px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    &-icon{
      width: 109px;
      height: 125px;
      margin-bottom: 37px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-title{
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 0.4px;
      color: $color-white;
      margin-bottom: 12px;
    }
    &-desc{
      @include fs--title-5-pc;
      line-height: 150%;
      color: $color-white;
      margin-bottom: 20px;
    }
    &-subtitle{
      @include fs--title-7-pc;
      color: $color-grey-text;
      a{
        margin-left: 8px;
        display: inline-block;
        cursor: pointer;
        @include fs--headline-3-pc;
        color: $color-white;
        text-decoration-line: underline;
        transition: color $trans-time ease-in-out;
        &:hover{
          color: $color-grey-text;
        }
      }
    }
  }
  &__item{
    &__wrapper{
      width: calc(100% - 410px);
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 16px;
    }

    width: 100%;
  }
}

@media screen and (max-width: 1024px){
  .home-faq{
    &__wrap{
      padding-top: 20px;
      padding-bottom: 40px;
      flex-direction: column;
    }
    &__item{
      &__wrapper{
        width: 100%;
        gap: 12px;
      }
    }
    &__content{
      margin-bottom: 24px;
      &-icon{
        width: 72px;
        height: 72px;
        margin-bottom: 32px;
      }
      &-title{
        @include fs--title-1-m;
        margin-bottom: 12px;
      }
      &-desc{
        @include fs--title-4-m;
      }
      &-subtitle{
        @include fs--body-1-m;
      }
    }
  }
}