@import "styles/vars";

.inventory-products {
  flex-grow: 1;
  &__grid {
    display: grid;
    grid-template-rows: 1fr 0;
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    column-gap: 0;
    //min-height: 576px;
    //min-height: 704px;

    //height: calc(100vh - 250px);
    height: calc(100vh - 315px);
    min-height: unset;
    margin-top: 12px;
    &--expose {
      grid-template-columns: 1fr 600px;
      grid-column-gap: 12px;
      column-gap: 12px;
      min-height: 483px;
    }

    &--product {
      grid-template-columns: 1fr 528px;
      grid-column-gap: 12px;
      column-gap: 12px;
      min-height: 622px;
    }
  }
  &__main{
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
  }
  &__aside-product {
    position: sticky;
    top: 100px;
    align-self: flex-start;
    flex-shrink: 0;
    transition: all 0.1s linear;
  }

  &__aside-expose {
    display: flex;
    flex-direction: column;
    min-height: 0;
    height: 100%;
  }

  &__mobile-nav {
    z-index: 995;
  }

  &__simple-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-top: 96px;
    padding-bottom: 96px;
  }


  &__refetch-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    flex-grow: 1;
    min-height: 100%;
    padding: 156px 0;
  }

  &__refetch-status {
    @include fs--body-4-pc();
    color: $color-white;
  }

}

@media screen and (max-width: 1024px) {
  .inventory-products {
    &__grid {
      height: unset;
      &--expose {
        grid-template-rows: 1fr 0;
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        column-gap: 0;
        min-height: unset;
      }

      &--product {
        grid-template-rows: 1fr 0;
        grid-template-columns: 1fr;
        grid-column-gap: 0;
        column-gap: 0;
        min-height: unset;
      }
    }
    &__main-content {
      overflow: inherit;
      margin-right: 0;
      padding-right: 0;
    }
    &__main-loader {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      height: 260px;
    }
  }
}


@media screen and (max-width: 768px) {
  .inventory-products {
    position: relative;
  }
}
