//---select input
.ant-select {
  color: $color-white;
  width: 100%;

  //---single
  &.ant-select-single {
    //---show arrow
    &.ant-select-show-arrow {
      .ant-select-selection-item,
      .ant-select-selection-placeholder {
        padding-right: 24px;
        @include fs--headline-4-pc;
        line-height: 1.8;
      }
    }

    //---open
    &.ant-select-open {
      .ant-select-selection-item {
        color: $color-white;
      }

      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          border-radius: 8px 8px 0 0;

        }
      }

      &.ant-select-show-arrow {
        .ant-select-arrow {
          color: $color-white;
          transform: rotateX(180deg);
        }
      }
    }

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        height: 44px;
        padding: 8px 16px;
        border: 1px solid var(--new-bg-2, #19181C);
        background: var(--new-bg-2, #19181C);
        border-radius: 8px;

        .ant-select-selection-search-input {
          height: 47px;
        }
      }
    }

    .ant-select-arrow {
      inset-inline-end: 12px;
      color: $color-grey-text;
    }

    .ant-select-arrow {
      width: 24px;
      height: 24px;
      margin-top: -12px;
      @include tr-ease()
    }

    //---SIZE
    &.ant-select-sm {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          height: 32px;
          padding: 6px 8px;

          .ant-select-selection-search-input {
            height: 31px;
          }
        }
      }

      &.ant-select-show-arrow {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 1.58;
        }

        .ant-select-selection-item {
          padding-right: 20px;
        }
      }

      .ant-select-arrow {
        width: 16px;
        height: 16px;
        margin-top: -8px;
        inset-inline-end: 8px;
      }
    }

    &.ant-select-lg {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          height: 56px;
          padding: 16px 16px;

          .ant-select-selection-search-input {
            height: 55px;
          }
        }
      }

      &.ant-select-show-arrow {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          padding-right: 32px;
          @include fs--headline-2-pc;
          line-height: 24px;
        }
      }

      .ant-select-arrow {
        inset-inline-end: 16px;
      }
    }

    // COLOR
    &.ant-select--light {
      &.ant-select--line{
        .ant-select-selection-item{
          line-height: 2;
        }
      }
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          border: 1px solid var(--new-bg-3, #1F1E21);
          background: var(--new-bg-3, #1F1E21);
        }
      }

      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          &:not(.ant-pagination-size-changer) {
            &:hover {
              .ant-select-selector {
                background: var(--new-bg-3, #1F1E21);
                border-color: var(--new-bg-3, #1F1E21);
              }
            }
          }
        }
      }
    }
    &.ant-select--dark {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          border: 1px solid  var(--new-bg-1, #131215);
          background:  var(--new-bg-1, #131215);
        }
      }

      &:not(.ant-select-disabled) {
        &:not(.ant-select-customize-input) {
          &:not(.ant-pagination-size-changer) {
            &:hover {
              .ant-select-selector {
                background:  var(--new-bg-1, #131215);
                border-color:  var(--new-bg-1, #131215);
              }
            }
          }
        }
      }
    }


    &.ant-select-auto-complete {
      .ant-input-affix-wrapper {
        border: 1px solid var(--new-bg-2, #19181C);
        background: var(--new-bg-2, #19181C);
        border-radius: 8px;

        .ant-input-prefix {
          margin-inline-end: 8px;
        }

        .ant-input {
          color: inherit;
          background-color: inherit;
        }
      }

      //---open
      &.ant-select-open {
        .ant-select-selection-search-input {
          color: $color-white;
          border-radius: 8px 8px 0 0;
        }
      }

      &.ant-select-lg {
        .ant-input-affix-wrapper {
          @include fs--headline-4-pc;
          padding: 0;
          .ant-input-prefix {
            margin-inline-start: 16px;
          }
          .ant-input {
            min-height: 54px;
            border-radius: 8px;
          }
          .ant-input-suffix {
            margin-inline-end: 16px;
          }
        }
      }
    }
  }

  //---END single
  //---hover
  &:not(.ant-select-disabled) {
    &:not(.ant-select-customize-input) {
      &:not(.ant-pagination-size-changer) {
        &:hover {
          .ant-select-selector {
            border-color: var(--new-bg-2, #1F1E21);
          }
        }
      }
    }
  }

  &:not(.ant-select-disabled):hover {
    .ant-select-selection-item {
      color: $color-white;
    }

    .ant-select-arrow {
      color: $color-white;
    }
  }

  //---END hover
  //---focus
  &.ant-select-focused {
    &:not(.ant-select-disabled) {
      &:not(.ant-select-customize-input) {
        &:not(.ant-pagination-size-changer) {
          .ant-select-selector {
            border-color: var(--new-bg-3, #1F1E21);
            box-shadow: none;
          }
        }

        .ant-select-arrow {
          color: $color-white;
        }
      }
    }
  }


  //---END focus
  &--rounded{
    .ant-select-selector{
      border-radius: 24px!important;
      .ant-select-selection-item{
        padding-right: 0!important;
        padding-left: 20px!important;
      }
    }
    .ant-select-arrow{
      left: 8px;
    }
  }
}


//---select dropdown
.ant-select-dropdown {
  z-index: 115;
  padding: 0;
  border-radius: 0px 0px 8px 8px;
  border-top: 1px solid var(--new-border, #2B292E);
  background: var(--new-bg-3, #1F1E21);
  box-shadow: none!important;
  .rc-virtual-list-scrollbar {
    width: 8px!important;
    &.rc-virtual-list-scrollbar-show {
    }
    .rc-virtual-list-scrollbar-thumb {
      width: 4px!important;
      background: $color-bg-01!important;
    }
  }
  .rc-virtual-list-holder {
    @include scrollbar-modal();
  }

  .ant-select-item {
    padding: 11px 12px;
    color: $color-grey-text;
    @include tr-ease();
  }

  .ant-select-item-option-content {
    @include fs--headline-4-pc;
  }

  .ant-select-item-option-active {
    &:not(.ant-select-item-option-disabled) {
      color: $color-white;
      background: $color-border;
    }
  }

  .ant-select-item-option-selected {
    &:not(.ant-select-item-option-disabled) {
      @include fs--headline-4-pc;
      color: $color-gray-2;
      background: var(--new-bg-3, #1F1E21);
      pointer-events: none;
    }
  }

  &.ant-select-dropdown-lg {
    .ant-select-item-option-content {
      @include fs--headline-2-pc;
    }
  }
}


@media screen and (max-width: 1024px) {
  //---select input
  .ant-select {
    &.ant-select-single {
      &.ant-select-show-arrow {
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          padding-right: 24px;
          @include fs--headline-6-pc;
          line-height: 24px;
        }
      }
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          height: 40px;
          padding: 8px 16px;

          .ant-select-selection-search-input {
            height: 39px;
          }
        }
      }

      &.ant-select-customize-input {
        .ant-select-selector {
          height: 40px;
          //padding: 8px 16px;

          .ant-select-selection-search-input {
            height: 38px;
            vertical-align: top;
          }
        }
      }

      .ant-select-arrow {
        inset-inline-end: 12px;
      }

      &.ant-select-lg {
        &:not(.ant-select-customize-input) {
          .ant-select-selector {
            height: 40px;
            padding: 8px 16px;


            .ant-select-selection-search-input {
              height: 39px;
            }
          }
        }

        &.ant-select-show-arrow {
          .ant-select-selection-item,
          .ant-select-selection-placeholder {
            padding-right: 24px;
            @include fs--headline-6-pc;
            line-height: 24px;
          }
        }

        .ant-select-arrow {
          inset-inline-end: 12px;
        }
      }

      &.ant-select-auto-complete {
        .ant-input-affix-wrapper {
          .ant-input-prefix {
            margin-inline-end: 8px;

            svg {
              width: 16px;
              height: auto;
            }
          }
        }

        &.ant-select-lg {
          .ant-input-affix-wrapper {
            @include fs--headline-1-m();
            padding: 0;
            .ant-input-prefix {
              margin-inline-start: 8px;
            }
            .ant-input {
              min-height: 36px;
            }
            .ant-input-suffix {
              margin-inline-end: 8px;
            }
          }
        }
      }
    }
    //---END single
  }

  //---select dropdown
  .ant-select-dropdown {
    &.ant-select-dropdown-lg {
      .ant-select-item-option-content {
        @include fs--headline-4-pc;
      }
    }
  }

}
