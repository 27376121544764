@import 'src/styles/vars';

.ant-alert {

  &.ant-alert-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 60px;

    &.ant-alert-warning {
      color: var(--new-bg-1, #131215);
      background: var(--new-yellow, #F2BA4D);
    }
    &.ant-alert-error {
      color: var(--new-white, #FFF);
      background: var(--red, #E54545);
    }
    &.ant-alert-info {
      color: var(--new-white, #FFF);
      background: var(--new-purple, #8D5DD9);
    }
    .ant-alert-message {
      text-align: center;
      color: currentColor;
      @include fs--headline-5-pc;
    }
    .ant-alert-close-icon {
      color: currentColor;
      margin-inline-start: inherit;
      svg {
        width: 18px;
        height: auto;
      }
    }

  }
}

@media screen and (max-width: 1760px){
  .ant-alert {
    &.ant-alert-banner {
      padding: 0 16px;
    }
  }
}


@media screen and (max-width: 1024px){
  .ant-alert {
    &.ant-alert-banner {
      padding: 0 16px ;
      .ant-alert-message {
        @include fs--body-3-m;
      }
      .ant-alert-close-icon {
        svg {
          width: 18px;
          height: auto;
        }
      }
    }
  }
}
