.app {
  .app-layout {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    //height: 100%;
    &__header {
      //position: sticky;
      //top: 0;
      //left: 0;
      //z-index: 120;
      flex-shrink: 0;
      width: 100%;
      height: auto;
      line-height: inherit;
      padding: 0;
      color: inherit;
      background: inherit;
    }

    &__content {
      //padding-top: 128px;
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    }

    &__footer {
      width: 100%;
      flex: 0 0 auto;
      clear: both;
      min-height: 100px;
      margin-top: auto;
    }
  }
}
