@import "styles/vars";

.dashboard {
  width: 100%;
  position: relative;
  border-bottom: 1px solid $color-border;

  &__wrap {
    padding-left: 180px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__nav {
    border-right: 1px solid $color-border;
    padding: 20px 20px 20px 0;
    max-width: 340px;
    width: 100%;
    min-height: calc(100vh - 202px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    align-self: stretch;

    &--links {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      &-back {
        margin-bottom: 20px;
      }

      &-item {
        &__wrap {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          gap: 8px;
        }

        width: 100%;
        border-radius: 8px;
        text-decoration: none;
        cursor: pointer;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: transparent;
        transition: background $trans-time--fast ease-in-out;

        span {
          margin-left: 12px;
          @include fs--headline-2-pc;
          color: $color-white;
          transition: color $trans-time--fast ease-in-out;
        }

        svg {
          color: $color-gray-2;
          transition: color $trans-time--fast ease-in-out;
        }

        &.danger {
          span, svg {
            color: $color-red;
          }

          &:hover {
            background: #201215;

            svg, span {
              color: $color-red;
            }
          }
        }

        &:hover, &.active {
          background: $color-purple-2;

          svg, span {
            color: $color-purple-3;
          }
        }

        &.active {
          pointer-events: none;
        }
      }
    }
  }

  &__body {
    width: calc(100% - 340px);
    display: flex;
    align-self: stretch;
    position: relative;
    overflow: hidden;

    &--bg {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(30%);
    }
  }

  &__main {
    width: 100%;
    position: relative;
    z-index: 2;

    &-loader {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 150px;
      padding-bottom: 150px;
    }

    &--wrap {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
    }

    &--head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 36px 180px 36px 36px;

      &-burger {
        display: none;
      }

      h1 {
        color: $color-white;
        @include fs--title-1-pc;
        margin: 0;
      }
    }

    &--align {
      &-right {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      }
    }

    &-form {
      width: 100%;
      //gap: 12px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      &-input {
        width: 100%;
      }

      &-switch {
        width: 70%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        span {
          margin-left: 16px;
          @include fs--body-2-pc;
          color: $color-white;
          width: calc(100% - 50px);
        }
      }

      &-connector {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }
  }

  &__drawer {
    .ant-drawer-body {
      padding-left: 8px;
      padding-right: 8px;
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;

      .ant-drawer-close {
        flex-shrink: 0;
        height: 36px;
        margin-inline-end: 0;
        margin-inline-start: 12px;

        svg {
          @include tr-ease();
          color: $color-white;
        }

        &:hover, &:active {
          svg {
            color: var(--new-grey-text, #939199);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .dashboard {
    &__wrap {
      padding-left: 60px;
    }

    &__main {
      &--head {
        padding-right: 60px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .dashboard {
    &__wrap {
      padding-left: 16px;
    }

    &__main {
      &--head {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .dashboard {
    &__wrap {
      padding-left: 0;
    }

    &__main {
      &--align {
        &-right {
          .ant-btn {
            width: 100%;
          }
        }
      }

      &-form {
        &-connector {
          flex-direction: column;
        }

        &-switch {
          width: 100%;

          span {
            @include fs--body-1-m;
          }
        }
      }

      &--head {
        padding-top: 20px;
        padding-bottom: 12px;

        &-burger {
          display: inline-block;
        }

        h1 {
          @include fs--title-1-m;
        }
      }
    }

    &__nav {
      display: none;
    }

    &__body {
      width: 100%;

      &--bg {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    &__main {
      &-form-input {

      }
    }
  }

  .dashboard__main {
    &-form {
      &-input {
        padding-bottom: 44px;

        &--profile {
          .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input-group-addon {
            min-width: 85px;
          }

          .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input {
            width: calc(100% - 85px);
          }
        }

        &--referral {
          .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input-group-addon {
            min-width: 120px;
          }

          .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input {
            width: calc(100% - 120px);
          }
        }

        &--steam {
          .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input-group-addon {
            min-width: 130px;
          }

          .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input {
            width: calc(100% - 130px);
          }
        }

        &.select {
          padding-bottom: 0;
        }

        .ant-form-item-has-error {
          .ant-form-item-control {
            display: flex;
            flex-direction: column-reverse;
          }
          .ant-form-item-explain {
            padding-top: 0;
            padding-bottom: 4px;
          }
        }
      }
    }
  }
}