@import "styles/vars";

.history-purchase {
  margin-top: 24px;
}

@media screen and (max-width: 1024px) {
  .history-purchase {
    margin-top: 12px;
  }
}

@media screen and (max-width: 768px) {
  .history-purchase {
    position: relative;
    z-index: 1;
    &__empty-btn-market {
      max-width: 480px;
    }
  }
}
