@import "src/styles/vars";

.text-page{
  width: 100%;
  &-wrap{
    width: 100%;
    padding-top: 50px;
  }
  &-title{
    text-align: center;
    @include fs--title-1-pc;
    color: $color-white;
    margin-top: 0;
  }
  &-content{
    h3, h4{
      color: $color-white;
      @include fs--article-title-pc;
    }
    color: $color-article-text;
    @include fs--article-desc-pc;
    b,strong{
      font-weight: 500;
    }
    a{
      color: $color-primary;
      text-decoration: none;
      cursor: pointer;
    }
  }
}