@import "src/styles/vars";

.tickets__card{
  padding: 16px 20px;
  width: 100%;
  background: $color-bg-2;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &--left{
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  &--title{
    max-width: 400px;
    @include text-dots(1);
    margin-bottom: 8px;
    color: $color-white;
    @include fs--title-6-pc;
  }
  &--desc{
    max-width: 400px;
    @include text-dots(1);
    color: $color-grey-text;
    @include fs--title-7-pc;
  }
  &--right{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
  &--status, &--state{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include fs--headline-2-pc;
    color: $color-grey-text;
    white-space: nowrap;
  }
  &--status{
    width: 170px;
  }
  &--state {
    width: 185px;
  }
  &--icon{
    margin-left: 8px;
    svg{
      width: 24px;
      height: 24px;
      color: $color-grey-text;
    }
  }
}

@media screen and (max-width: 1024px){
  .tickets__card{
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    &--left{
      max-width: 85%;
      margin-bottom: 24px;
    }
    &--title, &--desc{
      max-width: 100%;
    }
    &--title{
      @include fs--headline-1-m;
      margin-bottom: 8px;
    }
    &--desc{
      @include fs--headline-1-m;
    }
    &--right{
      max-width: 85%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
    }
    &--status, &--state{
      width: 100%;
      @include fs--headline-1-m;
    }
    &--icon{
      margin-left: 0;
      position: absolute;
      top: 22px;
      right: 16px;
    }
  }
}

