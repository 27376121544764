@import "src/styles/vars";

.chat-input {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action{
      opacity: 1;
      &:hover{
        background: transparent;
      }
    }
  }
  .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input{
    border-radius: 8px 0 0 8px;
  }
}

@media screen and (max-width: 768px){
  .chat-input{
    &__actions{
      .ant-upload-list{
        position: fixed;
        left: 15px;
        bottom: 10px;
      }
    }
    .ant-input-group-wrapper.ant-input-group-wrapper--custom-addon .ant-input{
      border-radius: 8px!important;
      width: calc(100% - 80px)
    }
    .ant-input-group-addon{
      margin-left: 0!important;
      margin-top: 0!important;

    }
  }
}