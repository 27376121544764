@import "src/styles/vars";

.request-trade-modal {
  &__wrapper {
    .ant-modal-close {
      top: 30px;
      right: 30px;
    }
  }

  &__content {
    padding: 32px 32px 28px 32px;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-right: 44px;
  }

  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-3-pc;
  }

  &__product {
    td {
      background: var(--new-bg-3, #1F1E21) !important;
    }
  }

  &__product-img {
    width: 50px;
    height: 44px;
    object-fit: cover;
    justify-self: flex-start;
  }

  &__product-type {
    flex-grow: 1;
    @include fs--headline-4-pc;

    div {
      margin-top: 8px;
      @include fs--title-7-pc;
      color: $color-white;
    }
  }

  &__product-float {
    flex-grow: 1;
  }

  &__product-float-content {
    width: 200px;
  }


  &__product-float-grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    margin-bottom: 7px;
    color: var(--new-grey-text, #939199);
    @include fs--headline-4-pc;

    span {
      color: $color-white;
    }
  }

  &__stickers {
    gap: 8px;
    flex-grow: 1;
  }

  &__product-price {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-grow: 1;

    span {
      color: $color-white;
    }

    .ant-btn {
      margin-left: 12px;
    }
  }

  &__product-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    flex-grow: 1;

    .ant-typography {
      span {
        white-space: nowrap;
      }
    }
  }

  &__body {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $color-border;
  }

  &__table-customer {
    .ant-badge {
      .ant-badge-status-default {
        background: var(--new-gray-2, #5D5C66);
      }

      .ant-badge-status-success {
        background: var(--new-purple-3, #A66DFF);
      }

      .ant-badge-dot {
        width: 8px;
        height: 8px;
        box-shadow: 0 0 0 3px #1C1B1E
      }
    }
  }

  &__table-avatar {
    width: 44px;
    height: 44px;
  }

  &__table-username {
    display: inline-block;
    margin-left: 32px;
    color: var(--new-white, #FFF);
    @include fs--title-7-pc;
    @include text-dots(1);
    max-width: 200px;
  }

  &__table-price {
    span {
      color: var(--new-white, #FFF);
    }
  }

  &__table-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px
  }

  &__table-empty {

  }

}


@media screen and (max-width: 1024px) {
  .request-trade-modal {
    &__content {
      padding: 26px 0 0;
    }

    &__head {
      margin-bottom: 22px;
      padding-right: 60px;
      padding-left: 16px;
    }

    &__title {
      @include fs--title-4-pc;
    }

    &__product {
      .ant-table {
        border-radius: 0 !important;
      }

      table {
        border-radius: 0 !important;
      }

      td {
        background: var(--new-bg-3, #1F1E21) !important;
      }
    }

    &__product-type {
      width: 200px;
      div {
        @include fs--headline-4-pc;
      }
    }

    &__stickers {
      gap: 4px;
    }

    &__sticker {
      width: 28px;
    }

    &__product-price {
      .ant-btn {
        margin-left: 4px;
      }
    }

    &__body {
      margin-top: 16px;
      padding-top: 16px;
    }

    &__btn-decline-all {
      margin-left: 16px;
      margin-bottom: 16px;
    }

    &__table-avatar {
      width: 38px;
      height: 38px;
    }

    &__table-username {
      margin-left: 12px;
      @include fs--headline-4-pc;
    }

    &__table-price {
      line-height: 1.2;
    }

    &__table-percent {
      white-space: nowrap;

    }

    &__table {
      .ant-table {
        background: var(--new-bg-1, #131215);
      }

    }
  }
}
