@import "styles/vars";

.blog__card {
  width: 100%;
  border-radius: 12px;
  background: $color-bg-2;
  transition: background $trans-time--fast ease-in-out;
  padding: 20px;
  text-decoration: none;
  cursor: pointer;
  display: block;

  &:hover {
    background: $color-bg-3;
  }

  &--image {
    width: 100%;
    height: 240px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--views {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      color: $color-grey-text;
    }

    span {
      color: $color-grey-text;
      @include fs--headline-4-pc;
    }
  }

  &--title {
    @include fs--title-6-pc;
    color: $color-white;
    @include text-dots(1);
    margin-bottom: 8px;
  }

  &--desc {
    color: $color-grey-text;
    @include fs--headline-4-pc;
    @include text-dots(1);

    > * {
      @include text-dots(1);
    }
    h1, h2, h3, h4, h5, h6 {
      line-height: 1.25;
    }
  }
}

@media screen and (max-width: 1024px) {
  .blog__card {
    padding: 16px;

    &--image {
      height: 180px;
      margin-bottom: 12px;
    }

    &--views {
      margin-bottom: 8px;

      span {
        @include fs--headline-6-pc;
      }
    }

    &--title {
      @include fs--title-7-pc;
      @include text-dots(2);
      white-space: break-spaces;
    }

    &--desc {
      @include fs--headline-6-pc;
    }
  }
}