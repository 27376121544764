@import "styles/vars";

.table-cell-rarity {
  width: 185px;
  @include fs--title-7-pc;
}

@media screen and (max-width: 1024px) {
  .table-cell-rarity {
    @include fs--headline-4-pc;
  }

}
