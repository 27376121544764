@import "src/styles/vars";

.home-marquee{
  width: 100%;
  overflow: hidden;
  &__wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 2px;
    padding-bottom: 2px;
    gap: 1px;
  }
}