@import "src/styles/vars";

.home-marquee-item {
  width: 146px;
  display: block;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid transparent;
  transition: background 0.2s 0.3s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  &:not(&:hover){
    background: transparent!important;
  }
  &__glow {
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    transition: color 0.3s ease-in-out;
    svg{
      height: 100%;
    }
  }
  &__logo {
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    pointer-events: none;
    user-select: none;
    touch-action: none;
    svg{
      width: 72px;
      height: 72px;
    }
  }
  &__image {
    position: relative;
    z-index: 1;
    margin: 8px auto;
    max-width: 108px;
    width: 100%;
    height: 80px;
    pointer-events: none;
    user-select: none;
    touch-action: none;

    img {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      object-fit: contain;
      @include tr-cb();
      transform: scale(1);
    }
  }
  &__sale{
    position: absolute;
    right: 8px;
    top: 4px;
    @include fs--headline-4-pc;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    pointer-events: none;
    user-select: none;
    touch-action: none;
    svg{
      width: 20px;
      height: 20px;
    }
  }

  &__wrap {
    padding: 14px 19px 10px 19px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__title{
    max-width: 100%;
    @include fs--headline-4-pc;
    color: $color-white;
    pointer-events: none;
    user-select: none;
    touch-action: none;
    @include text-dots(1)
  }
}

@media screen and (max-width: 1024px){
  .home-marquee-item{
    width: 104px;
    &__sale{
      span{
        font-size: 12px;
      }
    }
    &__image{
      height: 70px;
      margin-bottom: 0;
    }
    &__title{
      display: none;
    }
  }
}
