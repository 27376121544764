@import 'src/styles/vars';

.ant-tooltip {
  .ant-tooltip-inner {
    min-height: auto;
    padding: 20px;
    @include fs--body-3-pc;
    color: var(--new-white, #FFF);
    background-color: $color-border;
  }
  .ant-tooltip-arrow {
    &:before {
      background-color: $color-border;
    }
    &:active {
      background-color: $color-border;
    }
  }
  &.ant-tooltip--blur {
    max-width: 420px;
    z-index: 1;
    .ant-tooltip-inner {
      position: relative;
      border-radius: 12px;
      box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(0px);
      background: rgba(46, 44, 52, 1);
      animation: blur-in .4s 0.2s forwards;
    }
    .ant-tooltip-arrow {
      &:before {
        background: rgba(46, 44, 52, 0.50);
        backdrop-filter: blur(20px);
      }
    }
  }
  &.ant-tooltip--small {
    .ant-tooltip-inner {
      padding: 12px;
    }
  }
}

@keyframes blur-in {
  from {
    background: rgba(46, 44, 52, 1);
    backdrop-filter: blur(0px);
  }
  to {
    background: rgba(46, 44, 52, 0.50);
    backdrop-filter: blur(25px);
  }
}

@media screen and (max-width: 1024px) {
  .ant-tooltip {
    .ant-tooltip-inner {
      padding: 12px;
      @include fs--body-3-pc;
    }
    &.ant-tooltip--blur {
      max-width: 215px;
    }
  }
}
