@import "styles/vars";

.blog{
  width: 100%;
  flex-grow: 1;
  &__wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding-top: 36px;
    padding-bottom: 20px;
  }
  &__pagination{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 60px;
  }
  &__empty{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 36px;
    margin-bottom: 36px;
  }
  &__head{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    &--left{
      h1{
        margin-bottom: 12px;
        margin-top: 0;
        @include fs--title-1-pc;
        color: $color-white;
      }
      span{
        @include fs--title-7-pc;
        color: $color-grey-text;
      }
    }
    &--right{
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 560px;
      gap: 8px;
    }
  }
  &__head-search, &__head-select {
    width: 280px;
  }
  &__grid{
    flex-grow: 1;
    width: calc(100% + 20px);
    margin-left: -10px;
    display: flex;
    flex-wrap: wrap;
    &-loader{
      display: flex;
      align-items: center;
      margin: 0 auto;
    }
    &--item{
      max-width: 25%;
      flex: 0 0 25%;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 24px;
      display: flex;
      align-self: stretch;
    }
  }
}

@media screen and (max-width: 1280px){
  .blog{
    &__grid{
      &--item{
        max-width: 33.333%;
        flex: 0 0 33.333%;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .blog{
    &__head{
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      &--left{
        margin-bottom: 16px;
        h1{
          @include fs--title-4-pc;
        }
        span{
          @include fs--headline-6-pc;
        }
      }
      &--right{
        width: 100%;
        max-width: none;
      }
    }
    &__head-search {
      width: 220px;
      font-size: 12px!important;
    }
    &__head-select {
      width: 220px;
    }
  }
}

@media screen and (max-width: 768px){
  .blog{
    &__grid{
      &--item{
        max-width: 50%;
        flex: 0 0 50%;
      }
    }
  }
}
@media screen and (max-width: 480px){
  .blog{
    &__grid{
      &--item{
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
  }
}
