@import "src/styles/vars";

.support-modal{
  width: 100%;
  padding: 24px;
  &__wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--spaced{
      padding-right: 40px;
    }
  }
  &__content{
    max-width: 70%;
    &-title{
      @include fs--title-4-pc;
      color: $color-white;
      margin-bottom: 8px;
    }
    &-desc{
      @include fs--body-2-pc;
      color: $color-white;
    }
  }
  &__actions{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    &--closable{
      margin-right: 32px;
    }
  }
}

@media screen and (max-width: 1760px){
  .ant-modal--support{
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 1024px){
  .ant-modal--support{
    padding-left: 0;
    padding-right: 0;
  }
   .support-modal{
     &__wrap{
      flex-direction: column;
       align-items: flex-start;
       justify-content: flex-start;
       &--spaced{
         padding-right: 0;
       }
     }
     &__content{
       max-width: none;
       margin-bottom: 24px;
       &-title{
         @include fs--title-4-m;
       }
       &-desc{
         @include fs--body-2-m;
       }
     }
     &__actions{
       width: 100%;
       flex-direction: column;
       button{
         width: 100%;
       }
     }
   }
}