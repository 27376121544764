@import "styles/vars";

.table-cell-status {
  min-width: 190px;
  white-space: nowrap;
  &__flag {
    color: var(--new-grey-text, #939199);
    &--success {
      color: var(--new-green, #62CC3D);
    }
    &--processing {
      color: var(--new-yellow, #F2BA4D);
    }
    &--rejected {
      color: var(--red, #E54545);
    }
  }
}
