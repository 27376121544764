@import "src/styles/vars";

.new-trade-modal {
  @include tr-ease();
  &--small {
    max-width: 536px;
  }

  &--big {
    max-width: 1250px;
  }

  &__content {
    max-width: 100%;
    width: 100vw;
    padding: 32px 32px 28px;
  }

  &__title {
    margin-bottom: 32px;
    color: var(--new-white, #FFF);
    @include fs--title-3-pc;
  }

  &__autocomplete {
    &.ant-select.ant-select-single.ant-select-auto-complete {
      .ant-input-affix-wrapper {
        border-color: var(--new-bg-3, #1F1E21);
        background: var(--new-bg-3, #1F1E21);
      }
    }
  }

  &__chart {
    height: 267px;
    margin-top: 20px;
    &-filter{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 32px;
    background: var(--new-bg-3, #1F1E21);
  }

  &__summary-title {
    color: var(--new-white, #FFF);
    @include fs--title-4-pc;
  }

  &__summary-text {
    margin-top: 12px;
    color: var(--new-grey-text, #939199);
    @include fs--title-7-pc;
  }

}


@media screen and (max-width: 1024px) {
  .new-trade-modal {

    &__content {
      padding: 20px 16px 156px;
    }

    &__title {
      @include fs--title-4-pc;
      margin-bottom: 16px;
    }

    &__autocomplete {
      margin-bottom: 150px;
    }


    &__chart {
      position: relative;
      z-index: 0;
      margin-top: 24px;
      margin-bottom: 32px;
    }

    &__footer {
      position: fixed;
      z-index: 1;
      bottom: 0;
      left: 0;
      flex-direction: column;
      width: 100%;
      padding: 24px 16px;
      background: var(--new-bg-2, #19181C);
      box-shadow: 0px 10px 65px 0px rgba(0, 0, 0, 0.25);
    }

    &__summary {
      align-self: flex-start;

    }

    &__summary-title {
      @include fs--title-6-pc;
    }

    &__summary-text {
      margin-top: 12px;
      color: var(--new-grey-text, #939199);
      @include fs--headline-4-pc;
    }

    &__btn {
      width: 100%;
      max-width: 480px;
    }
  }
}
