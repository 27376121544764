.ant-switch {
  height: 20px;
  line-height: 20px;
  min-width: 35px;
  background-color: var(--new-border, #2B292E);
  .ant-switch-handle {
    top: 4px;
    inset-inline-start: 4px;
    width: 12px;
    height: 12px;
    &:before {
      background-color: var(--new-grey-text, #939199);
    }
  }
  &:hover:not(.ant-switch-disabled) {
    background-color: var(--new-border, #2B292E);
  }
  &.ant-switch-checked {
    background-color: var(--new-purple, #8D5DD9);
    .ant-switch-handle {
      inset-inline-start: calc(100% - 16px);
      &:before {
        background-color: $color-white;
      }
    }
  }

}
.ant-switch-handle, .ant-switch-inner{
  pointer-events: none;
}