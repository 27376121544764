@import "styles/vars";

.product-stats {
  padding: 24px 0 24px 36px;
  border-bottom: 1px solid var(--new-border, #2B292E);
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    color: var(--new-white, #FFF);
    @include fs--title-4-pc;
  }
  &__actions {

  }
  &__card-chart {
    margin-top: 16px;
    padding: 32px 16px 16px;
    border-radius: 12px;
    background: var(--new-bg-2, #19181C);
  }
  &__chart {
    height: 327px;
  }
}

@media screen and (max-width: 1280px) {
  .product-stats {
    padding: 24px 0 24px 16px;
  }
}

@media screen and (max-width: 1024px) {
  .product-stats {
    padding: 24px 0;
    &__head {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
    &__actions {
      width: 100%;
      gap: 12px 8px;
      padding-bottom: 8px;
      @include scrollbar('horizontal', 4px);
      overflow: auto;
      .ant-radio-button-wrapper {
        white-space: nowrap;
      }
    }
    &__card-chart {
      margin-top: 8px;
      padding: 16px 0 0;
      border-radius: 0;
      background: transparent;
    }
    &__chart {
      width: 100%;
    }
  }
}
