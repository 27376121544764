@import "src/styles/vars";

.app-btn-cart {
  &--remove {
    &.ant-btn {
      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          color: $color-purple-3;
          border-color: $color-purple-0;
          background: $color-purple-0;
          &:hover {
            color: $color-red;
            border-color: $color-red-0;
            background: $color-red-0;

            .app-btn-cart__icon-trash {
              opacity: 1;
              visibility: visible;
            }

            .app-btn-cart__icon-success {
              opacity: 0;
              visibility: hidden;
            }
          }

          &:active {
            color: $color-red;
            border-color: $color-red-01;
            background: $color-red-01;
          }
        }
      }
    }
  }
  &__icons {
    position: relative;
  }

  &__icon-trash {
    opacity: 0;
    visibility: hidden;
  }

  &__icon-success {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    visibility: visible;
  }
}
