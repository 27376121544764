@import "styles/vars";

.success-refill-modal {
  .withdraw-balance-confirmation__desc--title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-green;
  }
  &__wrapper {
    .ant-modal-close {
      color: $color-white;
    }
  }
  .withdraw-balance-confirmation__head--bg {
    background: linear-gradient(97.04deg, rgba(99, 179, 71, 0.85) 40.09%, rgba(51, 89, 38, 0.85) 100.67%);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1024px) {
  .block-warning-modal {
    .withdraw-balance-confirmation__actions {
      .ant-btn {
        max-width: 480px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .block-warning-modal {

    .withdraw-balance-confirmation__desc {
      padding: 24px 16px;
    }

    .withdraw-balance-confirmation__desc--title {
      margin-bottom: 8px;
      @include fs--headline-3-pc;
    }

    .withdraw-balance-confirmation__desc--content {
      @include fs--body-4-pc;
    }

    .withdraw-balance-confirmation__actions {
      padding: 24px 16px;
    }
  }
}
