@import "styles/vars";

.header-notice-drawer {
  top: 100%;
  height: calc(100vh - 100%);
  .ant-drawer-content {
    background: var(--new-bg-1, #131215);
  }
  .ant-drawer-body {
    height: 100%;
  }

}
