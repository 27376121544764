@import 'styles/vars';

.header-simple {
  &__alerts {
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 60px;
    gap: 16px;
    background: var(--new-bg-3, #1F1E21);
  }

  &__togglers {
    @include list-reset-style;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
  }
}

@media screen and (max-width: 1760px) {
  .header-simple {
    &__logo {
      svg {
        width: 80px;
        height: auto;
      }
    }
  }
}

@media screen and (max-width: 1280px){
  .header-simple {
    &__content {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .header-simple {
    display: flex;
    flex-direction: column;
    &__lang{
      min-width: 74px;
    }
    &__content {
      padding: 8px 16px;
    }
    &__togglers {
      gap: 8px;
    }

    &__alerts {
      order: 1;
    }
  }
}
