@import "../../../styles/vars";

.stickers-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  &__sticker {
    display: block;
    width: 34px;
    height: auto;
    object-fit: contain;
    aspect-ratio: 1;
    cursor: pointer;
  }
}


@media screen and (max-width: 1024px){
  .stickers-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    &__sticker {
      width: 28px;
    }
  }
}