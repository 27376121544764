@import "styles/vars";

.deals-trade {
  margin-top: 24px;
}

@media screen and (max-width: 1024px) {
  .deals-trade {
    margin-top: 12px;
  }
}
