@import "styles/vars";

.block-warning-modal {
  .withdraw-balance-confirmation__desc--title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__wrapper {
    .ant-modal-close {
      color: $color-white;
    }
  }
  .withdraw-balance-confirmation__head--bg {
    background: radial-gradient(73.20% 81.31% at 50.00% 91.14%, #FFCA62 0%, #D19B10 100%);
    img {
      object-position: top center;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1024px) {
  .block-warning-modal {
    .withdraw-balance-confirmation__actions {
      .ant-btn {
        max-width: 480px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .block-warning-modal {

    .withdraw-balance-confirmation__desc {
      padding: 24px 16px;
    }

    .withdraw-balance-confirmation__desc--title {
      margin-bottom: 8px;
      @include fs--headline-3-pc;
    }

    .withdraw-balance-confirmation__desc--content {
      @include fs--body-4-pc;
    }

    .withdraw-balance-confirmation__actions {
      padding: 24px 16px;
    }
  }
}
