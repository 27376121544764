@import "styles/vars";

.header-apps {
  @include list-reset-style;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  a {
    display: inline-block;
    padding: 8px;
    @include fs--headline-4-pc;
    color: $color-grey-text;
    >svg {
      width: 16px;
      height: auto;
      margin-right: 8px;
    }
  }
  &:hover,&:active {
    color: $color-gray-0;
  }
}

@media screen and (max-width: 1280px){
  .header-apps {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    gap: 12px;
    padding: 12px 0 12px 16px!important;
    margin-left: -16px;
    border-top: 1px solid var(--new-border, #2B292E);
    li {
      width: 100%;
    }
    a {
      display: flex;
      align-items: center;
      padding: 8px 0;
      color: $color-white;
      >svg {
        width: 20px;
      }
      span {
        flex-grow: 1;
      }
      &:hover,&:active {
        color: $color-grey-text;
      }
    }
    &__actions {
      color: $color-gray-2;
    }
  }
}
