@import "src/styles/vars";

.home-assortment-block {
  width: 100%;
  padding: 46px 110px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  &__wrap {
    width: calc(100% - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
  }
  &__bg{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: left center;
    }
  }
  &__head{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-title{
      @include fs--title-1-pc;
      color: $color-white;
    }
    &-link{
      text-decoration: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      span{
        @include fs--headline-3-pc;
        color: $color-white;
        margin-right: 12px;
        transition: all $trans-time--fast ease-in-out;
      }
      svg{
        color: $color-grey-text;
        transition: all $trans-time--fast ease-in-out;
      }
      &:hover{
        span, svg{
          color: $color-primary;
        }
      }
    }
  }
  &__body{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    min-height: 300px;
    &-item{
      flex: 0 0 16.666%;
      max-width: 16.666%;
      width: 100%;
      align-self: stretch;
      .product-card{
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 1680px){
 .home-assortment-block{
   padding: 46px 16px 46px 110px;
   &__body{
     overflow-x: scroll;
     @include scrollbar('horizontal', 4px);
     &-item{
       width: 190px;
       max-width: none;
       flex: none;
       display: inline-block;
     }
   }
 }
}

@media screen and (max-width: 1024px){
  .home-assortment-block{
    &__bg{
      img{
        object-position: right center;
      }
    }
    padding: 20px 0 20px 20px;
    border-radius: 0;
    &__wrap{
      width: 100%;
    }
    &__head{
      padding-right: 20px;
      &-title{
        @include fs--title-1-m;
      }
      &-link{
        @include fs--headline-2-m;
      }
    }
  }
}