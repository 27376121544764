@import "styles/vars";

.product-similar-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;

  &__select {

    &--scticker {
      width: 190px;
    }

    &--date {
      width: 180px;
    }

    &--price {
      width: 180px;
    }
  }

  &__switch {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    span {
      @include fs--headline-4-pc;
      color: $color-white;
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 1360px){
  .product-similar-actions {
    &__select {

      &--scticker {
        width: 160px;
      }

      &--date {
        width: 155px;
      }

      &--price {
        width: 155px;
      }
    }

    &__switch {
      gap: 8px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .product-similar-actions {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 24px 16px;

    &__select {
      &--scticker,
      &--date,
      &--price{
        width: 100%;
      }
    }

    &__switch {
      span {
        @include fs--headline-6-pc;
      }
    }
  }
}
