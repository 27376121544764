.ant-radio-group {
  color: $color-grey-text;

  // Button
  &.ant-radio-group-outline {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    .ant-radio-button-wrapper {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--new-bg-2, #19181C);
      border-color: var(--new-bg-2, #19181C);

      &:not(.ant-radio-button-wrapper-disabled) {
        &::before {
          display: none;
        }
      }

      &:hover {
        color: $color-white;
      }
    }

    .ant-radio-button-wrapper-checked {
      color: $color-white;
      background: var(--new-bg-3, #1F1E21);
      border-color: var(--new-bg-3, #1F1E21);
      pointer-events: none;
    }

    // SIZE
    &.ant-radio-group-large {
      .ant-radio-button-wrapper {
        width: 56px;
        height: 56px;
        padding: 4px;
      }
      .ant-radio-button-wrapper + .ant-radio-button-wrapper {
        margin-left: -1px;
      }
    }


    // END Button
    .ant-radio-wrapper {
      &:hover {
        .ant-radio-inner {
          border-color: var(--new-purple, #8D5DD9);
        }
      }

      span.ant-radio + * {
        color: var(--new-grey-text, #939199);
        @include fs--headline-4-pc
      }

      .ant-radio-inner {
        width: 20px;
        height: 20px;
        border-radius: 13px;
        background-color: transparent;
        border: 1px solid var(--new-border, #302E33);
      }

      .ant-radio {
        &.ant-radio-checked {
          .ant-radio-inner {
            background-color: var(--new-purple, #8D5DD9);
            &::after {
              transform: scale(0.625);
            }
          }
          & + * {
            color: $color-white;
          }
        }
      }
    }
  }

  // Tags
  &.ant-radio-group-solid {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    .ant-radio-button-wrapper {
      outline: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 12px 16px;
      border-radius: 24px;
      color: $color-white;
      background: var(--new-bg-2, #19181C);
      border-color: var(--new-bg-2, #19181C);
      @include fs--headline-2-pc;

      &:not(:first-child)::before {
        display: none;
      }
      &:hover {
        color: $color-white;
        background: $color-bg-05;
        border-color: $color-bg-05;
      }
    }

    .ant-radio-button-wrapper-checked {
      color: $color-purple-3;
      background: var(--new-purple-2, #1F1B26);
      border-color: var(--new-purple-2, #1F1B26);
      pointer-events: none;
    }
    .ant-radio-button-wrapper-disabled{
      color: $color-white;
      pointer-events: none;
      user-select: none;
      opacity: 0.4;
    }
  }
}

.ant-radio-button-wrapper:has(:focus-visible) {
  outline: 0;
}

@media screen and (max-width: 1024px) {
  .ant-radio-group {
    &.ant-radio-group-outline {
      &.ant-radio-group-large {
        .ant-radio-button-wrapper {
          width: 40px;
          height: 40px;
          padding: 4px;
        }
      }
    }
    // Tags
    &.ant-radio-group-solid {
      .ant-radio-button-wrapper {
        height: 30px;
        padding: 8px 12px;
        @include fs--headline-1-m;
      }
    }
  }
}
