//---core
@import "fonts";
@import "vars";
@import "media";

//---layouts
@import "layouts";

//---library custom
@import "antd";
@import "charts";
@import "infinite-scroll-component";

//---custom

//---NOTE: for THEME use css variables, just replace all scss color's on css color var's
//---EXAMPLE: $color-accent => var(--color-white);
:root {
  --new-white: #{$color-white};
  --new-purple: #{$color-purple};
  --new-purple-2: #{$color-purple-2};
  --new-purple-3: #{$color-purple-3};
  --new-purple-0: #{$color-purple-0};
  --new-purple-01: #{$color-purple-01};
  --new-purple-02: #{$color-purple-02};
  --new-grey-text: #{$color-grey-text};
  --new-green: #{$color-green};
  --new-green-0: #{$color-green-0};
  --new-green-01: #{$color-green-01};
  --new-green-02: #{$color-green-02};
  --red: #{$color-red};
  --red-0: #{$color-red-0};
  --red-01: #{$color-red-01};
  --new-yellow: #{$color-yellow};
  --new-yellow-0: #{$color-yellow-0};
  --new-bg-3: #{$color-bg-3};
  --new-bg-2: #{$color-bg-2};
  --new-bg-0: #{$color-bg-0};
  --new-border: #{$color-border};
  --new-gray-2: #{$color-gray-2};
  --new-bg-1: #{$color-bg-1};
  --gray-0: #{$color-gray-0};

  //--color-bg: #{$color-bg};
  //--color-bg-table2: #{$color-bg-table2};
  //--color-green-btn: #{$color-green-btn};
  //
  //--color-primary: #{$color-primary};
  //--color-secondary: #{$color-secondary};
  //--color-accent: #{$color-accent};

  --color-error: #{$color-error};
  --color-info: #{$color-info};
  --color-success: #{$color-success};
  --color-warning: #{$color-warning};
  &[data-theme='dark'] {
  };
}

:focus,
:focus-visible,
:focus:not(:focus-visible) {
  outline: 0!important;
  outline-offset: 0!important;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: $f-family;
  color: #000;
  background: var(--new-bg-1, #131215);
  scroll-behavior: smooth;
}

html {
  line-height: 1.4;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.app-fix {
    body {
      overflow-y: hidden;
      width: 100%;
      //height: 100vh;
      //width: calc(100% - 14px);
    }
  }
}


body {
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 0px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    width: 0px;
    background: $color-purple-2;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    width: 0px;
    background: $color-bg-03;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    width: 0px;
    background: $color-bg-03;
    cursor: pointer;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

textarea::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
textarea::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
textarea:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
textarea:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
textarea:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
textarea:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}



/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

strong {
  font-weight: 700;
}

.app {
  display: flex;
  justify-content: center;
}


.app-container {
  position: relative;
  width: 100%;
  height: 100%;
  //max-width: 1920px;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
  box-sizing: border-box;
  &--small {
    max-width: 1680px;
  }
  &--xxs {
    max-width: 1196px; //#todo refact
  }
}

@media screen and (max-width: 1760px){
  .app-container{
    &--small{
      padding-left: 52px;
      padding-right: 52px;
    }
    &--xxs{
      padding-left: 44px;
      padding-right: 44px;
    }
  }
}

@media screen and (max-width: 1280px){
  .app-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
