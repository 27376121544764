@import "src/styles/vars";

.home-promo {
  width: 100%;

  &__wrap {
    width: 100%;

    .ant-carousel .slick-dots {
      bottom: 40px;
      left: 180px;
      margin: 0;
      inset-inline-end: auto;

      li {
        &.slick-active, &:hover {
          button {
            background: $color-purple;
          }
        }

        height: 4px;
        width: 76px;

        button {
          height: 4px;
          mix-blend-mode: overlay;
          background: $color-white;
        }
      }
    }
  }

  &__banner {
    &-wrapper {
      width: 100%;
    }

    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &-content {
      max-width: 910px;
      width: 100%;
      overflow: hidden;
      position: relative;

      &:before {
        width: 588px;
        height: 588px;
        border-radius: 100%;
        opacity: 0.3;
        background: $color-purple;
        mix-blend-mode: screen;
        filter: blur(250px);
        position: absolute;
        left: -10%;
        bottom: -50%;
        content: '';
      }

      padding: 70px 180px 147px 180px;
      background: $color-bg-1;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      &-title {
        max-width: 550px;
        @include fs--title-h1-pc;
        margin-top: 0;
        color: $color-white;
        margin-bottom: 12px;
      }

      &-desc {
        max-width: 410px;
        @include fs--body-2-m;
        color: $color-grey-text;
        margin-bottom: 32px;
      }

      &-action {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;

        &-desc {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          padding: 20px;
          border-radius: 12px;
          border: 1px solid $color-border;

          span, svg {
            color: #219653;
          }

          @include fs--headline-2-pc;
          color: $color-white;
        }
      }
    }

    &-image {
      background: linear-gradient(154deg, #8F5EDC 0%, #3F226D 100%);
      display: flex;
      align-self: stretch;
      width: calc(100% - 910px);
      position: relative;

      &-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;

        img {
          object-position: top center;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

  }

  &__banner-block {
    position: absolute;

    &--dota {
      top: 0;
      //left: 185px;
      left: 26%;

      .home-promo__banner-icon {
        bottom: -66px;
        right: -66px;
      }

      .home-promo__banner-card-image {
        border-radius: 8px;
        overflow: hidden;

        img {
          object-fit: cover;
          max-height: 96px;
        }
      }
    }

    &--cs {
      top: 135px;
      left: -95px;

      .home-promo__banner-icon {
        bottom: -84px;
        right: -66px;
      }

      .home-promo__banner-card-image {
        max-height: 96px;

        img {
          object-fit: contain;
        }
      }
    }
  }

  &__banner-icon {
    position: absolute;
    z-index: 1;

    svg {
      width: 112px;
      height: 112px;
    }
  }

  &__banner-card {
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 194px;
    padding: 25px 13px 16px 13px;
    color: $color-white;
    border-radius: 16px;
    border: 1px solid $color-purple;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.13) 100%);
    box-shadow: 0 15px 75px 0 rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(50px);
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $color-white;

      .home-promo__banner-card-image {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  &__banner-card-image {
    position: relative;
    width: 100%;
    height: auto;

    margin-bottom: 18px;

    img {
      position: relative;
      z-index: 2;
      width: 100%;
      height: auto;
      object-fit: cover;
      transition: transform $trans-time ease-in-out;
    }
  }

  &__banner-card-image-logo {
    position: absolute;
    z-index: 0;
    left: 50%;
    bottom: 50%;
    width: 122px;
    height: 122px;
    color: $color-yellow;
    transform: translate(-50%, 50%);
  }

  &__banner-card-price {
    margin-bottom: 12px;
    @include fs--headline-3-pc;
  }

  &__banner-card-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 17px;
    @include fs--headline-3-pc;

    span {
      position: relative;
      @include text-dots(1);
    }

    span + span {
      &:before {
        height: 12px;
        width: 1px;
        background: $color-white;
        left: -9px;
        bottom: 2px;
        position: absolute;
        content: '';
      }
    }
  }
}

@media screen and (max-width: 1680px) {
  .home-promo {
    &__wrap {
      .ant-carousel .slick-dots {
        left: 120px;
      }
    }

    &__banner {
      &-content {
        width: 55%;
        padding-left: 120px;
        padding-right: 16px;
      }

      &-image {
        width: 45%;

        &-bg {
          img {
            object-position: top right;
          }
        }
      }
    }
  }
  .home-promo__banner-block--dota {
    left: auto;
    right: 244px;
  }
}


@media screen and (max-width: 1280px) {
  .home-promo {
    &__wrap {
      .ant-carousel .slick-dots {
        left: 16px;
      }
    }

    &__banner-content {
      padding-left: 16px;
      width: 45%;
    }

    &__banner-image {
      width: 55%;
    }

    &__banner-block--dota {
      top: 36px;
      right: 36px;

      .home-promo__banner-icon {
        right: auto;
        left: -64px;
      }
    }

    &__banner-block--cs {
      top: 196px;
      left: 10px;
    }
  }
}

@media screen and (max-width: 1140px) {
  .home-promo {
    &__wrap {
      .ant-carousel .slick-dots {
        left: 50%;
        transform: translateX(-50%);
        bottom: 35px;

        li {
          width: 50px;
          height: 3px;
        }
      }
    }

    &__banner {
      &-content {
        width: 55%;
      }

      &-image {
        width: 45%;
      }
    }

    &__banner-block--dota {
      top: 36px;
      right: 16px;

      .home-promo__banner-icon {
        bottom: -96px;
        right: auto;
        left: 64px;
      }
    }

    &__banner-block--cs {
      top: 164px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .home-promo {
    &__banner {
      flex-direction: column-reverse;

      &-content {
        background: transparent;
        position: relative;
        z-index: 3;
        margin-top: -100px;
        padding-bottom: 73px;
        width: 100%;
        padding-top: 0;

        &:before {
          display: none;
        }

        &-title {
          @include fs--title-h1-m;
          margin-top: 0;
          margin-bottom: 12px;
          max-width: 275px;
        }

        &-desc {
          max-width: 250px;
          color: $color-white;
          @include fs--body-3-m;
        }

        &-action {
          width: 100%;
          flex-direction: column;

          a {
            width: 100%;

            button {
              width: 100%;
            }
          }

          &-desc {
            width: 100%;
            @include fs--headline-4-pc;
            padding: 13px;
          }
        }
      }

      &-image {
        width: 100%;
        height: 500px;

        &-bg {
          &:before {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            content: '';
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 55%, rgba(19, 18, 21, 1) 100%);
          }

          img {
            object-position: right center;
          }
        }
      }
    }

    &__banner-block--dota {
      .home-promo__banner-icon {
        bottom: -64px;
        right: auto;
        left: -64px;
      }
    }

    &__banner-block--cs {
      top: 96px;
    }

  }
}

@media screen and (max-width: 640px) {
  .home-promo {
    &__banner {
      &-image {
        height: 400px;

        &-bg {
          img {
            object-position: right center;
          }
        }
      }

    }

    &__banner-block--dota {
      right: auto;
      top: auto;
      left: 70px;
      bottom: 150px;

      .home-promo__banner-icon {
        bottom: -45px;
        left: auto;
        right: -45px;
      }
    }

    &__banner-block--cs {
      right: 50px;
      top: 32px;
      left: auto;
      bottom: auto;

      .home-promo__banner-icon {
        bottom: -75px;
        right: 0;
      }

      .home-promo__banner-card-image {
        max-height: 56px;
      }
    }

    &__banner-icon {
      svg {
        width: 60px;
        height: 60px;
      }
    }

    &__banner-card {
      width: 104px;
      padding: 13px 7px 8px 7px;
    }

    &__banner-card-image {
      margin-bottom: 8px;
    }

    &__banner-card-image-logo {
      width: 65px;
      height: 65px;

      svg {
        width: 65px;
        height: 65px;
      }
    }

    &__banner-card-price {
      margin-bottom: 6px;
      font-size: 9px;
    }

    &__banner-card-title {
      gap: 8px;
      font-size: 9px;

      span + span {
        &:before {
          left: -5px;
          height: 8px;
          bottom: 1px;
        }
      }
    }

    &__banner-card-title {
    }
  }
}